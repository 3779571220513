import * as React from 'react';

import {
    FavoriteTypesEnum,
    QueryToolModeEnum,
    QueryTypes,
    TutorialTopics,
} from '../../utilities/Constants';
import { INoteUISearchModel, IQueryFilterCriteria } from '../../interfaces/IQuery';

import Authorization from '../../stores/Authorization';
import { ExportNotes } from '../../components/shared/ExportNotes';
import { Favorite } from '../../components/shared/Favorite';
import { INoteModel } from '../../interfaces/INote';
import { IUserModel } from '../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';

export interface IQueryHeaderProps {
    hideFavs?: boolean;
    applyFavoriteSelected: (settingJson?: string, isDefault?: boolean) => void;
    currentFavoriteDetailsString: string;
    currentFavoriteSettingJson: string;
    enableEasyUpdate: boolean;
    mode: number;
    notesResults: INoteModel[];
    noteSearchCriteria: INoteUISearchModel;
    onCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        item?: IQueryFilterCriteria
    ) => void;
    onClearClick: () => void;
    onDefaultFavoriteChange: (settingJson?: string) => void;
    onFavoriteSelected?: (settingJson?: string) => void;
    onRunClick: () => void;
    onShowExportModal: () => void;
    queryType: number;
    redirectComponentCurrentState: any;
    redirectUrl?: string;
    selectAllCasesForRedirect?: boolean;
    selectedGuidsForRedirect: string[];
    showExport: boolean;
    showExportNotes: boolean;
    title?: string;
    user: IUserModel;
}

export function QueryHeader(props: IQueryHeaderProps) {
    const showEasyUpdateToggle =
        Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, props.user) &&
        (props.queryType === QueryTypes.CaseDetails.Value ||
            props.queryType === QueryTypes.CaseNotes.Value ||
            props.queryType === QueryTypes.Deposition.Value ||
            props.queryType === QueryTypes.CaseExperts.Value);

    const showTutorialLink = getTutorialTopic(props.queryType).length > 0;

    const handleRunClick = () => {
        props.onRunClick();
    };
    const handleClearClick = () => {
        props.onClearClick();
    };

    return (
        <div className="row mb-3">
            <div className="col-sm-4">
                <h1 className="d-inline-block">Query Tool </h1>
                <span className="font-size-xs ps-3">{props.title}</span>
            </div>
            <div className="col-sm-8">
                {props.redirectUrl && (
                    <span className="btn btn-blue float-end">
                        <Link
                            to={{ pathname: props.redirectUrl }}
                            style={{ color: 'white' }}
                            state={{
                                selectedGuids: props.selectedGuidsForRedirect,
                                redirectComponentCurrentState: props.redirectComponentCurrentState,
                            }}
                        >
                            Back
                        </Link>
                    </span>
                )}
                <button className="btn btn-orange float-end text-gray" onClick={handleRunClick}>
                    Run Query
                </button>
                <button className="btn btn-default float-end" onClick={handleClearClick}>
                    Clear
                </button>
                <>
                    {props.mode === QueryToolModeEnum.Redirect &&
                    props.redirectUrl &&
                    (props.selectAllCasesForRedirect ||
                        props.selectedGuidsForRedirect.length > 0) ? (
                        <span className="btn btn-orange text-gray float-end">
                            {' '}
                            <Link
                                to={{ pathname: props.redirectUrl }}
                                state={{
                                    redirect: true,
                                    selectedGuids: props.selectedGuidsForRedirect,
                                    redirectComponentCurrentState:
                                        props.redirectComponentCurrentState,
                                }}
                                style={{ color: 'white' }}
                            >
                                Add Selected Cases
                            </Link>
                        </span>
                    ) : null}
                </>
                {props.showExport &&
                    getExportUIByQueryType(
                        props.queryType,
                        props.notesResults,
                        props.noteSearchCriteria,
                        props.onShowExportModal,
                        props.showExportNotes
                    )}
                {props.redirectUrl || props.hideFavs ? null : (
                    <Favorite
                        user={props.user}
                        type={{ id: FavoriteTypesEnum.Query }}
                        currentFavoriteSettingJson={props.currentFavoriteSettingJson}
                        currentFavoriteDetailsString={props.currentFavoriteDetailsString}
                        handleFavoriteSelected={props.onFavoriteSelected}
                        applyFavoriteSelected={props.applyFavoriteSelected}
                        handleDefaultFavoriteChange={props.onDefaultFavoriteChange}
                    />
                )}
                {showEasyUpdateToggle ? (
                    <span className="btn btn-no-bg float-end text-gray ps-3">
                        <label style={{ cursor: 'pointer' }}>
                            <input
                                name="chkEasyUpdate"
                                type="checkbox"
                                className="form-check-input"
                                onChange={props.onCheckedChange}
                                checked={props.enableEasyUpdate}
                            />
                            <i className="fal fa-edit ps-2 pe-1" />
                            Easy Update
                        </label>
                    </span>
                ) : null}
                {showTutorialLink && (
                    <Link
                        className="float-end"
                        target="_blank"
                        to={`${LocalRoutes.Tutorials}${LocalRoutes.ViewSpecificPDF.replace(
                                ':id',
                                getTutorialTopic(props.queryType)
                            )}`}
                    >
                        <span className="btn btn-no-bg text-gray">
                            <i className={'fal fa-graduation-cap'} />
                            &nbsp;Tutorials
                        </span>
                    </Link>
                )}
            </div>
        </div>
    );
}

const getTutorialTopic = (queryType: number) => {
    if (queryType === QueryTypes.CaseDetails.Value)
        return TutorialTopics.QueryToolCaseDetails.toString();
    if (queryType === QueryTypes.CaseNotes.Value)
        return TutorialTopics.QueryToolCaseNotes.toString();
    return '';
};

const getExportUIByQueryType = (
    queryType: number,
    notesResults: INoteModel[],
    noteSearchCriteria: INoteUISearchModel,
    onShowExportModal: () => void,
    showExportNotes: boolean
) => {
    switch (queryType) {
        case QueryTypes.CaseDetails.Value:
        case QueryTypes.CaseExperts.Value:
        case QueryTypes.CaseNotes.Value:
        case QueryTypes.Deposition.Value:
            return (
                <button className="btn btn-no-bg float-end text-gray" onClick={onShowExportModal}>
                    <i className="fal fa-file-export" />
                    &nbsp;Export
                </button>
            );
        case QueryTypes.ZoneNotes.Value:
        case QueryTypes.StrategyNote.Value:
            if (showExportNotes) {
                return (
                    <ExportNotes
                        notes={notesResults ?? []}
                        searchModel={noteSearchCriteria}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
                        handleError={() => {}}
                    />
                );
            } else return null;
        default:
    }
};
