export const ApiRoutes = {
    DBAppSettings: 'api/dbappsettings',
    Lookup: 'api/lookup/',
    LookupEntityType: 'api/lookup/entity/{entitytypeid}/lookuptype/{lookuptype}',
    organization: 'api/organization',
    OrganizationDetails: 'api/organization/{id}',
    AssociatedOrganizations: 'api/associatedorganization/{id}',
    OrganizationsInZone: 'api/organizationinzone',
    OrganizationSettings: 'api/organizationsettings',
    UserInvitation: 'api/user/invitation',
    UserInvitationDetails: 'api/user/invitation/{id}',
    User: 'api/user',
    UserInZone: 'api/zoneuser',
    UserByRoleRight: 'api/userbyroleright',
    Login: 'api/account/login',
    Logout: 'api/account/logout',
    SetDefaultUserOrganization: 'api/user/organization/default',
    UserDetails: 'api/user/{id}',
    LoggedInUserDetails: 'api/login/user',
    AccountRecover: 'api/account/recover',
    AcceptTermsOfService: 'api/account/terms/accept/{id}',
    ResetPassword: 'api/account/reset',
    assertiondescriptor: 'api/assertiondescriptor',
    assertiondescriptors: 'api/assertiondescriptors',
    AssertionDescriptorDetails: 'api/assertiondescriptor/{id}',
    Cases: 'api/cases',
    Case: 'api/case',
    CaseFileModel: 'api/casefilemodel',
    CaseDetails: 'api/case/{id}',
    CasePriority: 'api/case/{id}/priority/{priority}',
    CaseTitle: 'api/casetitle/{id}',
    MasterTasks: 'api/mastertask',
    CaseTaskNew: 'api/case/{id}/caseplan/{caseplanid}/task?mastertaskid={mastertaskid}&loe={loeid}',
    CasePlan: 'api/caseplan/{id}?templateid={templateid}&loe={loeid}',
    CaseDeadlines: 'api/case/{id}/deadlines',
    CaseTaskDetails: 'api/case/{id}/caseplan/{caseplanid}/task/{taskid}',
    CasePlanTask: 'api/case/{id}/caseplan/{caseplanid}/task',
    CasePlanFirmAssignments: 'api/case/{id}/caseplan/{caseplanid}/firmassignment',
    CasePlanTaskAssignments: 'api/case/{id}/caseplan/{caseplanid}/taskassignment',
    CasePlanExecute: 'api/case/{id}/caseplan/{caseplanid}/execute',
    UserZoneProfile: 'api/userzoneprofile',
    UserZoneProfiles: 'api/userzoneprofiles',
    UserTasks: 'api/user/tasks',
    TaskBar: 'api/user/taskbar',
    UserSetting: 'api/user/setting',

    EntityDocumentSave: 'api/document/{id}/{entitytypeid}',
    EntityDocumentUpload: 'api/documentupload/{id}/{entitytypeid}',
    EntityDocument: 'api/document/{id}/{entitytypeid}/{documentid}',
    DocumentTemplate: 'api/documenttemplate/{id}',
    EntityDocumentDownload: 'api/documentdownload/{id}/{entitytypeid}/{documentid}',
    Documents: 'api/mydocuments/{pageNumber}/{pageSize}',
    MyDocumentsCount: 'api/mydocuments-count',
    MyDocumentsDownload: 'api/mydocuments/download/{id}',
    MyDocumentsDelete: 'api/mydocuments/{id}',
    ChildDocuments: 'api/collabreportchildren/{id}/{entitytypeid}',

    UserNotes: 'api/usernotes',
    CaseNote: 'api/case/{id}/note',
    Query: 'api/query',
    QueryLegacy: 'api/querylegacy',
    TriggerCreate: 'api/triggers',
    TriggerSearch: 'api/triggers/search',
    TriggerGet: 'api/triggers/{guid}',
    TriggerUpdate: 'api/triggers/{guid}',
    TriggerDelete: 'api/triggers/{guid}',

    Notes: 'api/notes',
    Note: 'api/note',
    NoteDetail: 'api/note/{id}',
    ExportNotes: 'api/exportnotes',
    NoteTargets: 'api/notetargets/{notetypeId}',
    MarkAlertAsReadForUser: 'api/alert/read/{id}',
    UserAlertSummary: 'api/user/alertsummary',
    Deposition: 'api/deposition',
    Depositions: 'api/depositions',
    PackageSettlements: 'api/packagesettlements',
    PackageSettlement: 'api/packagesettlement',
    PackageSettlementDetail: 'api/packagesettlement/{id}',

    // BOARD
    BoardCaseDetails: 'api/boardcasedetails?type={type}',
    BoardComments: 'api/boardcomments',
    ExportBoard: 'api/exportboard',

    ThreatReport: 'api/threatreport',
    UserReports: 'api/userreport',
    ReportDetail: 'api/report/{id}',
    ReportTypeDetail: 'api/reporttype/{id}',
    ExportReport: 'api/exportreport',
    DownloadReport: 'api/downloadreport/{id}',
    UnreadQueryExports: 'api/unreadqueryexports',
    ReportStatus: 'api/reportstatus/{id}',

    // ZONE SPECIFIC REPORTS

    TimelineRecap: 'api/timelinerecap',
    TimelineRecapExport: 'api/timelinerecapexport',

    EntityAssertions: 'api/entityassertions',
    ExecutionPlan: 'api/executionplan',
    StrategicPlan: 'api/strategicplan',

    Strategy: 'api/strategy',
    StrategyDetail: 'api/strategy/{id}',

    Projects: 'api/projects',
    ProjectDetail: 'api/project/{id}',

    MeetingAgendaReport: 'api/meetingagenda',
    ReportDataByReportId: 'api/reportdata/{id}/{getLiveData}/{endDate?}',
    ReportDataByReportParameters: 'api/reportdata/parameters',

    Alerts: 'api/alerts',
    Favorites: 'api/favorites',
    FavoriteItem: 'api/favorite/{id}',

    CurrentWeather: 'api/weather/current',

    // EXPERTS

    GetExperts: 'api/experts',
    CreateExpert: 'api/expert',
    GetExpert: 'api/expert/{id}',
    UpdateExpert: 'api/expert',
    DeleteExpert: 'api/expert/{id}',
    ExpertNameExists: 'api/expert/lastName/{lastName}',

    // CASE EXPERTS

    CreateCaseExpert: 'api/caseexpert',
    GetCaseExpert: 'api/caseexpert/{id}',
    UpdateCaseExpert: 'api/caseexpert',
    DeleteCaseExpert: 'api/caseexpert/{id}',

    CreateReport: 'api/createreport',
    ReadReportList: 'api/readreportlist',
    UpdateReport: 'api/updatereport',
    DeleteReport: 'api/deletereport',

    // REPORTS
    CollaborationReportSave: 'api/collaboration-report',
    CollaborationReportComprehensiveSave: '/api/collaboration-report-comprehensive',
    CollaborationReportGet: 'api/collaboration-report/{collaborationReportId}',
    CollaborationReportGetComprehensive:
        'api/collaboration-report-comprehensive/{collaborationReportId}',
    CollaborationReportDelete: 'api/collaboration-report/{collaborationReportId}',
    CollaborationReportGetAll: 'api/collaboration-report/',
    CollaborationReportDataGet: 'api/collaboration-report-data/{collaborationReportId}',
    CollaborationReportUserCreate: 'api/collaboration-report-users/',
    CollaborationReportUsersGetAll: 'api/collaboration-report-users/',
    CollaborationReportHistory: 'api/collaboration-report-history/{collaborationReportId}',
    CollaborationReportGetStatus: 'api/collaboration-report-review-status/{collaborationReportId}',
    StaticReportDataDate: 'api/staticdatadate/{documentTypeId}',

    CloseAct: 'api/alert/closealertuser',

    EntityConversationGetAll: 'api/entity-conversation/{entityGuid}/{entityTypeId}',
    EntityConversationCreate: 'api/entity-conversation/',

    EntityLease: "api/entity-lease/{entityLeaseId}",
    EntityLeaseIsAvailable: "api/entity-lease/{entityId}/{entityTypeId}",
    EntityLeaseObtain: "api/entity-lease-obtain/{entityId}/{entityTypeId}",
    EntityLeaseRenew: "api/entity-lease-renew/{entityId}/{entityTypeId}",
    EntityLeaseRelease: "api/entity-lease-release/{entityLeaseGuid}",
    EntityLeasePing: "api/entity-lease-ping/{entityLeaseGuid}",
};
