import * as React from 'react';

import {
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    AssertionDescriptors,
    CaseFileStaticItems,
    CaseStatusTypesEnum,
    ConditionSelectorEnums,
    DataScopesEnum,
    EmptyGuid,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    QueryToolDefaultColumnList,
    QueryToolModeEnum,
    QueryToolRedirectTypeEnum,
    QueryTypes,
    StrategyTypes,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    ICaseExpertModel,
    ICaseExpertViewDetailModel,
    IViewCaseExpertModelWithDefaultEdit,
} from '../../interfaces/ICaseExpert';
import {
    ICaseExpertsUISearchModel,
    IDepositionUISearchModel,
    INoteUISearchModel,
    IQueryColumnDefinitionModel,
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
    IQueryResultModel,
    IQuerySettingsModel,
} from '../../interfaces/IQuery';
import {
    ICaseModel,
    ICaseStatusLookupModel,
    IDepositionModel,
    IPackageSettlementBaseModel,
} from '../../interfaces/ICase';
import {
    ILookupModel,
    IMultiSelectOptions,
    IStaticItemLookupModel,
} from '../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CaseDepositionListWrapper } from '../../components/case/static-sections/CaseDepositionListWrapper';
import { CaseDetailsResults } from './CaseDetailsResults';
import { CaseEasyUpdate } from '../case/CaseEasyUpdate';
import { CaseExpertEditor } from '../../components/case/static-sections/case-experts/CaseExpertEditor';
import { CaseExpertsList } from '../../components/case/static-sections/case-experts/CaseExpertsList';
import CaseHelper from '../../utilities/CaseHelper';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { DateRangePicker } from './DateRangePicker';
import { DepositionSectionCriteriaValues } from './DepositionSectionCriteriaValues';
import { DisplayColumnSelector } from './DisplayColumnSelector';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ExpertsCriteriaSelector } from '../query/ExpertsCriteriaSelector';
import { FilterCriteria } from './FilterCriteria';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IAssociatedOrganizationModel } from '../../interfaces/IOrganization';
import { IQueryRedirectModel } from './Query2Wrapper';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { NoteCategoryAdditionalInfoDetailDialog } from '../../components/notes/NoteCategoryAdditionalInfoDetailDialog';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteListTable } from '../../components/notes/NoteListTable';
import { NotesSectionCriteriaValues } from './NotesSectionCriteriaValues';
import { PackageDealsCriteriaValues } from './PackageDealsCriteriaValues';
import { QueryExportInBackgroundModal } from './QueryExportInBackgroundModal';
import { QueryExportModal } from './QueryExportModal';
import { QueryHeader } from './QueryHeader';
import { QueryTooManyRecordsModal } from './QueryTooManyRecordsModal';
import { QueryTypeSelector } from './QueryTypeSelector';
import { SelectedFilterCriteria } from './SelectedFilterCriteria';
import Sort from '../../stores/Sort';
import UIHelper from '../../utilities/UIHelper';
import ValidateUtils from '../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InternalDatabaseFields_CaseName = -1;
const InternalDatabaseFields_CaseStatus_Status = -2;
const InternalDatabaseFields_CaseStatus_SubStatus = -3;
const InternalDatabaseFields_PackageSettlementGuid = -4;

const maxExportRecordCount = 1000;
const maxDisplayRecordCount = 1000;
const maxRecordCountForWebProcessing = 10000;

const QueryStaticItemFields = Object.assign({}, CaseFileStaticItems);

export function getAuthorizedQueryTypes(user: IUserModel) {
    const queryTypes: ILookupModel[] = [];

    if (Authorization.userHasRight(UserRightsEnum.ViewCaseFile, user)) {
        queryTypes.push({
            id: QueryTypes.CaseDetails.Value,
            name: QueryTypes.CaseDetails.Name,
            displayName: QueryTypes.CaseDetails.DisplayName,
        });
    }

    if (Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, user)) {
        queryTypes.push({
            id: QueryTypes.CaseNotes.Value,
            name: QueryTypes.CaseNotes.Name,
            displayName: QueryTypes.CaseNotes.DisplayName,
        });
    }

    if (Authorization.userHasRight(UserRightsEnum.ViewCaseExpert, user)) {
        queryTypes.push({
            id: QueryTypes.CaseExperts.Value,
            name: QueryTypes.CaseExperts.Name,
            displayName: QueryTypes.CaseExperts.DisplayName,
        });
    }

    if (Authorization.userHasRight(UserRightsEnum.ViewDeposition, user)) {
        queryTypes.push({
            id: QueryTypes.Deposition.Value,
            name: QueryTypes.Deposition.Name,
            displayName: QueryTypes.Deposition.DisplayName,
        });
    }

    if (Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, user)) {
        queryTypes.push({
            id: QueryTypes.ZoneNotes.Value,
            name: QueryTypes.ZoneNotes.Name,
            displayName: QueryTypes.ZoneNotes.DisplayName,
        });
    }

    if (Authorization.userHasViewStrategyNotesRight(user)) {
        queryTypes.push({
            id: QueryTypes.StrategyNote.Value,
            name: QueryTypes.StrategyNote.Name,
            displayName: QueryTypes.StrategyNote.DisplayName,
        });
    }

    return queryTypes;
}

interface IQueryProps {
    acknowledgmentStatusTypes: ILookupModel[];
    approvalStatusTypes: ILookupModel[];
    assertionDescriptors: IAssertionDescriptorModel[];
    caseManagers: IUserModel[];
    caseStatuses: ICaseStatusLookupModel[];
    defenseCounsels: IAssociatedOrganizationModel[];
    depositionCategories: ILookupModel[];
    depositionStatusTypes: ILookupModel[];
    displayColumnsSelected?: IQueryFilterCriteria[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    notePurposeTypes: ILookupModel[];
    packageDeals: IPackageSettlementBaseModel[];
    redirectModel: IQueryRedirectModel;
    refreshIdentifier: string;
    staticItems: IStaticItemLookupModel[];
    user: IUserModel;
    usStates: ILookupModel[];
    validation: IValidation;
    zoneUsers: IUserModel[];
}

interface IQueryState {
    allUsers: IUserModel[];
    caseExpertsResults: ICaseExpertModel[];
    caseExpertsSearchCriteria?: ICaseExpertsUISearchModel;
    caseManagers: IQueryFilterCriteriaSelectionValue[];
    caseResults: IQueryResultModel;
    caseResultsCount: number;
    caseStatusesForQueryCriteria: IQueryFilterCriteriaSelectionValue[];
    currentCaseExpert?: IViewCaseExpertModelWithDefaultEdit;
    currentFavoriteDetailsString: string;
    currentNote: INoteModel;
    depositionCategoryOptions: IMultiSelectOptions[];
    depositionResults?: IDepositionModel[];
    depositionSearchCriteria?: IDepositionUISearchModel;
    depoStatusTypeOptions: IMultiSelectOptions[];
    displayColumnsAll: IQueryFilterCriteria[];
    displayColumnsSelected: IQueryFilterCriteria[];
    displayColumnsSelectedForExport: IQueryFilterCriteria[];
    displayNoteCategoryAdditionalInfo: boolean;
    easyUpdateAttemptedCaseList: string[];
    easyUpdateAttemptedNoteGuidList: string[];
    easyUpdateCaseGuid?: string;
    easyUpdateMode: boolean;
    enableEasyUpdate: boolean;
    enableNoteUpdate?: boolean;
    loggedInUserDataScopeId: number;
    mode: QueryToolModeEnum;
    noteResults?: INoteModel[];
    noteSearchCriteria: INoteUISearchModel;
    openNoteEditor?: boolean;
    otherDefenseCounselsForZone: IQueryFilterCriteriaSelectionValue[];
    pendingResponse: boolean;
    queryCriteriaMasterList: IQueryFilterCriteria[];
    queryFilterCriteriaAll: IQueryFilterCriteria[];
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    queryType: number;
    redirectComponentCurrentState?: any;
    redirectType?: QueryToolRedirectTypeEnum;
    redirectUrl?: string;
    selectAllCasesForRedirect?: boolean;
    selectedGuidsForRedirect: string[];
    selectedNoteCategoriesOnEdit: IMultiSelectOptions[]; // AR - TO DO - 01/18/2023 - Not sure of its purpose. It doesnt seem to be used anywhere
    selectedQueryFavoriteId?: number;
    showConfirmRemoveCaseNoteDialog: boolean;
    showExportInBackgroundDialog?: boolean;
    showExportModal: boolean;
    showTooManyRecordsToDisplayDialog?: boolean;
    showTooManyRecordsToProcessDialog?: boolean;
    showSelectAllCheckbox?: boolean;
    strategyTypeId?: number;
    title?: string;
    userDefaultSettingsJson?: string;
    usStatesOptions: IQueryFilterCriteriaSelectionValue[];
    validation: IValidation;
    zoneDefenseCounselOptions: IQueryFilterCriteriaSelectionValue[];
    displayRecordCount: boolean;
}

export class QueryLegacy extends React.Component<IQueryProps, IQueryState> {
    constructor(props: IQueryProps) {
        super(props);

        this.state = {
            allUsers: [],
            caseExpertsResults: [],
            caseResults: {},
            caseResultsCount: 0,
            caseStatusesForQueryCriteria: [],
            currentFavoriteDetailsString: '',
            currentNote: {
                purpose: {
                    id: NotePurposeTypesEnum.Info,
                },
                status: {
                    id: NoteStatusTypes.Open,
                },
            },
            depositionCategoryOptions: [],
            depoStatusTypeOptions: [],
            displayColumnsAll: [],
            displayColumnsSelected: [],
            displayColumnsSelectedForExport: [],
            displayNoteCategoryAdditionalInfo: false,
            easyUpdateAttemptedCaseList: [],
            easyUpdateAttemptedNoteGuidList: [],
            easyUpdateMode: false,
            enableEasyUpdate: false,
            loggedInUserDataScopeId: 0,
            mode: QueryToolModeEnum.Standalone,
            noteSearchCriteria: {},
            otherDefenseCounselsForZone: [],
            pendingResponse: true,
            queryCriteriaMasterList: [],
            queryFilterCriteriaAll: [],
            queryFilterCriteriaSelected: [],
            queryType: QueryTypes.CaseDetails.Value,
            selectedGuidsForRedirect: [],
            selectedNoteCategoriesOnEdit: [],
            showConfirmRemoveCaseNoteDialog: false,
            showExportModal: false,
            caseManagers: [],
            usStatesOptions: [],
            validation: props.validation,
            zoneDefenseCounselOptions: [],
            displayRecordCount: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Query, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (
            this.props.redirectModel &&
            this.props.redirectModel?.redirectType === QueryToolRedirectTypeEnum.PackageDeal &&
            (!this.props.redirectModel.queryFilterCriteriaSelected ||
                this.props.redirectModel.queryFilterCriteriaSelected.length === 0)
        ) {
            const validation = this.state.validation;
            validation.model = [DisplayMessages.UnexpectedErrorPackageDealQueryNav];
            this.setState({ validation: validation });
            return;
        }

        if (
            this.props.redirectModel?.queryFilterCriteriaSelected ||
            this.props.displayColumnsSelected
        ) {
            let queryFilterCriteriaSelected: IQueryFilterCriteria[] = [];
            let displayColumnsSelected: IQueryFilterCriteria[] = [];
            let displayColumnsSelectedForExport: IQueryFilterCriteria[] = [];
            let showExportModal = false;

            if (this.props.redirectModel?.queryFilterCriteriaSelected) {
                queryFilterCriteriaSelected = cloneDeep(
                    this.props.redirectModel?.queryFilterCriteriaSelected
                );
            }

            if (this.props.displayColumnsSelected) {
                displayColumnsSelected = cloneDeep(this.props.displayColumnsSelected);
                displayColumnsSelectedForExport = cloneDeep(displayColumnsSelected);
                showExportModal = true;
            }

            this.setState(
                {
                    ...this.props.redirectModel,
                    queryFilterCriteriaSelected,
                    displayColumnsSelected,
                    displayColumnsSelectedForExport,
                    showExportModal,
                    mode: QueryToolModeEnum.Redirect,
                },
                this.loadInitialData
            );
        } else {
            this.loadInitialData();
        }
    }

    componentDidUpdate(prevProps: IQueryProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier !== this.props.refreshIdentifier
        ) {
            window.location.assign(LocalRoutes.Query2);
        }
    }

    getStaticItemsQueryCriteriasMasterList = (
        staticItems: IStaticItemLookupModel[]
    ): IQueryFilterCriteria[] => {
        const packageDealAuthFilter = (staticItem: IStaticItemLookupModel) => {
            const isPackageDeal = staticItem.guid === QueryStaticItemFields.PackageSettlement.Guid;
            return (
                !isPackageDeal ||
                (isPackageDeal &&
                    Authorization.userHasRight(
                        UserRightsEnum.ViewPackageSettlement,
                        this.props.user
                    ))
            );
        };

        const staticSectionFilter = (staticItem: IStaticItemLookupModel) =>
            staticItem.id !== CaseFileStaticItems.DefenseCounselSection.Id;

        const staticItemToQueryFilterCriteriaMapper = (staticItem: IStaticItemLookupModel) => ({
            label: CaseHelper.getCaseFileStaticItemDisplayName(staticItems, staticItem.id),
            value: UIHelper.getName(staticItems, staticItem.id),
            isAssertionDescriptor: false,
            staticItemId: staticItem.id,
        });

        return staticItems
            .filter(packageDealAuthFilter)
            .filter(staticSectionFilter)
            .map(staticItemToQueryFilterCriteriaMapper);
    };

    loadInitialData = async () => {
        const caseStatusDropdownValuesAll = this.props.caseStatuses.map((caseStatus) => ({
            label: caseStatus.name,
            value: caseStatus.name,
            id: caseStatus.id!,
        }));

        const caseManagerDropdownValuesAll = this.props.caseManagers.map((caseManager) => ({
            label: `${caseManager.profile?.firstName} ${caseManager.profile?.lastName}` ?? '',
            value: caseManager.guid ?? '',
            guid: caseManager.guid ?? '',
        }));

        let zoneDefenseCounselOptions = this.props.defenseCounsels.map((org) => ({
            label: org.name ?? '',
            value: org.guid ?? '',
            guid: org.guid ?? '',
        }));

        const defenseCounselDropdownValuesOther = this.props.defenseCounsels
            .filter((x) => x.priority !== 1)
            .map((org) => ({
                label: org.name ?? '',
                value: org.guid ?? '',
                guid: org.guid ?? '',
            }));

        const usStatesOptions = this.props.usStates.map((state) => ({
            label: `${state.displayName} - ${state.name}`,
            value: state.guid ?? '',
            guid: state.guid ?? '',
        }));

        const staticItemQueryCriterias = this.getStaticItemsQueryCriteriasMasterList(
            this.props.staticItems
        );

        const queryCriteriaMasterList: IQueryFilterCriteria[] = [...staticItemQueryCriterias];

        this.props.assertionDescriptors
            .filter((x) => x.usageType && x.usageType.id === AssertionDescriptorUsageTypesEnum.Case)
            .forEach((item: IAssertionDescriptorModel) => {
                queryCriteriaMasterList.push({
                    label: item.alternateDisplayName || item.displayName!,
                    value: item.name!,
                    isAssertionDescriptor: true,
                    assertionDescriptorGuid: item.guid,
                });
            });

        queryCriteriaMasterList.sort(Sort.compareValues('label'));

        const displayColumnFilter = (item: IQueryFilterCriteria) =>
            item.staticItemId !== QueryStaticItemFields.CaseStatusAsOfDate.Id &&
            item.staticItemId !== QueryStaticItemFields.DefenseCounselSection.Id;

        const queryCriteriaAssertionDescriptorNationalPFFilter = (item: IQueryFilterCriteria) =>
            item.assertionDescriptorGuid?.toUpperCase() !==
            AssertionDescriptors.PlainfirmFirmSecondary.Guid;

        const queryCriteriaAssertionDescriptorNationalOtherOccupationFilter = (
            item: IQueryFilterCriteria
        ) =>
            item.assertionDescriptorGuid?.toUpperCase() !==
            AssertionDescriptors.OtherOccupation.Guid;

        const caseAlternateManagerFilter = (item: IQueryFilterCriteria) =>
            item.staticItemId !== CaseFileStaticItems.AlternateCaseManager.Id;

        const displayColumnsAll = queryCriteriaMasterList.filter(displayColumnFilter);

        const caseManagerMapper = (filter: IQueryFilterCriteria) => {
            if (filter.staticItemId === CaseFileStaticItems.CaseManager.Id) {
                return { ...filter, label: 'A1/A2' };
            }
            return filter;
        };

        const queryFilterCriteriaAll = queryCriteriaMasterList
            .filter(queryCriteriaAssertionDescriptorNationalPFFilter)
            .filter(queryCriteriaAssertionDescriptorNationalOtherOccupationFilter)
            .filter(caseAlternateManagerFilter)
            .map(caseManagerMapper);

        const depositionCategoryOptions = this.props.depositionCategories.map((category) => ({
            label: category.displayName ?? category.name ?? '',
            value: category.name ?? '',
            id: category.id,
        }));

        const depoStatusTypeOptions = this.props.depositionStatusTypes.map((statusType) => ({
            label: statusType.displayName ?? statusType.name ?? '',
            value: statusType.name!,
            id: statusType.id!,
        }));

        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );
        if (
            loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
            this.props.user.homeOrganization
        ) {
            zoneDefenseCounselOptions = zoneDefenseCounselOptions.filter(
                (x) => x.guid === this.props.user.homeOrganization!.guid
            );
            queryFilterCriteriaSelected.push({
                staticItemId: QueryStaticItemFields.LocalCounsel.Id,
                readOnly: true,
                required: true,
                isFixed: true,
                label: CaseHelper.getCaseFileStaticItemDisplayName(
                    this.props.staticItems,
                    QueryStaticItemFields.LocalCounsel.Id
                ),
                value: UIHelper.getName(
                    this.props.staticItems,
                    QueryStaticItemFields.LocalCounsel.Id
                ),
                selectedValues: [
                    {
                        guid: this.props.user.homeOrganization.guid!,
                        label: this.props.user.homeOrganization.name!,
                        value: this.props.user.homeOrganization.name!,
                        isFixed: true,
                    },
                ],
            });

            const lcFilter = queryFilterCriteriaAll.find(
                (filter) => filter.staticItemId === QueryStaticItemFields.LocalCounsel.Id
            );

            if (lcFilter) {
                lcFilter.isFixed = true;
            }
        }

        const defaultColumns = QueryToolDefaultColumnList;
        let displayColumnsSelectedInit: IQueryFilterCriteria[];
        let displayColumnsSelectedForExportInit: IQueryFilterCriteria[];

        const displayColumnsSelectedDefault: IQueryFilterCriteria[] = defaultColumns.map((c) => {
            const value: IQueryFilterCriteria | undefined = displayColumnsAll.find((o) => {
                return (
                    (c.assertionDescriptorGuid &&
                        o.assertionDescriptorGuid?.toUpperCase() ===
                            c.assertionDescriptorGuid?.toUpperCase()) ||
                    (c.staticItemId && o.staticItemId === c.staticItemId)
                );
            });
            if (value) return value;
            else {
                const name: string = c.name ? c.name : '';
                const value: IQueryFilterCriteria = {
                    label: name,
                    value: name,
                };
                return value;
            }
        });

        if (this.state.displayColumnsSelected.length == 0) {
            displayColumnsSelectedInit = [...displayColumnsSelectedDefault];
        } else {
            displayColumnsSelectedInit = [...this.state.displayColumnsSelected];
        }

        if (this.state.displayColumnsSelectedForExport.length == 0) {
            displayColumnsSelectedForExportInit = [...displayColumnsSelectedDefault];
        } else {
            displayColumnsSelectedForExportInit = [...this.state.displayColumnsSelectedForExport];
        }

        this.setState({
            caseStatusesForQueryCriteria: caseStatusDropdownValuesAll,
            depositionCategoryOptions,
            depoStatusTypeOptions,
            displayColumnsAll,
            displayColumnsSelected: displayColumnsSelectedInit,
            displayColumnsSelectedForExport: displayColumnsSelectedForExportInit,
            loggedInUserDataScopeId,
            otherDefenseCounselsForZone: defenseCounselDropdownValuesOther,
            pendingResponse: false,
            queryCriteriaMasterList,
            queryFilterCriteriaAll,
            queryFilterCriteriaSelected,
            caseManagers: caseManagerDropdownValuesAll,
            usStatesOptions,
            zoneDefenseCounselOptions,
        });
    };

    getNoteTypeByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.Deposition.Value:
            case QueryTypes.CaseNotes.Value:
                return NoteTypes.CaseNote;
            case QueryTypes.ZoneNotes.Value:
                return NoteTypes.ZoneNote;
            case QueryTypes.StrategyNote.Value:
                return NoteTypes.StrategyNote;
            default:
                return 0;
        }
    };

    getAsOfDateStatusLookupValues = () => {
        const statusLookupValues: ILookupModel[] = [];

        statusLookupValues.push(
            this.props.caseStatuses.filter(
                (x) => x.status && x.status.id == CaseStatusTypesEnum.Open.Value
            )[0].status
        );

        return statusLookupValues;
    };

    getSubStatusLookupValues = (statusId: number) => {
        const subStatusLookupValues: IQueryFilterCriteriaSelectionValue[] = this.props.caseStatuses
            .filter((x) => x.status.id === statusId && x.subStatus)
            .map((y) => ({
                label: y.subStatus.displayName!,
                value: y.subStatus.name!,
                id: y.subStatus.id,
            }));

        return subStatusLookupValues;
    };

    queryCriteriaSelected = (optionsList: any) => {
        const validation = this.state.validation;

        const queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[] = JSON.parse(
            JSON.stringify(this.state.queryFilterCriteriaSelected)
        );

        const isDepositionSearchCriteriaRemoved = this.isDepositionSearchCriteriaRemoved(
            optionsList,
            queryFilterCriteriaSelectedBefore
        );

        const isNotesSearchCriteriaRemoved = this.isNotesSearchCriteriaRemoved(
            optionsList,
            queryFilterCriteriaSelectedBefore
        );
        const noteSearchCriteria: INoteUISearchModel = this.state.noteSearchCriteria;
        if (isNotesSearchCriteriaRemoved) {
            this.removeNoteSearchFromSearchCriteria(noteSearchCriteria);
            //noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
        }

        const queryFilterCriteriaSelected: IQueryFilterCriteria[] = optionsList;

        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            if (
                queryFilterCriteriaSelectedBefore.findIndex(
                    (x) => x.value == queryFilterCriteriaSelected[i].value
                ) == -1
            ) {
                // This means it was added for the first time or added earlier, cleared/deleted and re-added again. Hence clear all input values for search
                queryFilterCriteriaSelected[i].selectedValues = undefined;
                queryFilterCriteriaSelected[i].text = '';
                queryFilterCriteriaSelected[i].booleanValue = undefined;
                queryFilterCriteriaSelected[i].startDate = undefined;
                queryFilterCriteriaSelected[i].endDate = undefined;
                queryFilterCriteriaSelected[i].numericValueFromText = '';
                queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                queryFilterCriteriaSelected[i].numericValueToText = '';
                queryFilterCriteriaSelected[i].numericValueTo = undefined;
                queryFilterCriteriaSelected[i].condition = undefined;
                queryFilterCriteriaSelected[i].validationError = undefined;
            }
        }

        if (optionsList.length > 10) {
            validation.criteria = [DisplayMessages.QueryToolMaximumCriteria];
            //queryFilterCriteriaSelected = optionsList.splice(0, 10);
        } else {
            validation.criteria = [''];
        }

        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            queryFilterCriteriaSelected[i].required = true;
        }

        // Custom Logic for Redirect from Package Deal
        if (this.props.redirectModel?.redirectType === QueryToolRedirectTypeEnum.PackageDeal) {
            const plaintiffsFirmCurrent = queryFilterCriteriaSelected.find(
                (x) =>
                    x.assertionDescriptorGuid != undefined &&
                    x.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.PlainfirmFirmLocal.Guid
            );

            const plaintiffsFirmBefore = queryFilterCriteriaSelectedBefore.find(
                (x) =>
                    x.assertionDescriptorGuid != undefined &&
                    x.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.PlainfirmFirmLocal.Guid
            );

            const packageDealFromBefore = queryFilterCriteriaSelectedBefore.find(
                (x) => x.staticItemId === QueryStaticItemFields.PackageSettlement.Id
            );
            const packageDealCurrent = queryFilterCriteriaSelected.find(
                (x) => x.staticItemId === QueryStaticItemFields.PackageSettlement.Id
            );

            if (
                (!plaintiffsFirmCurrent && plaintiffsFirmBefore) ||
                (!packageDealCurrent && packageDealFromBefore)
            ) {
                // User trying to remove the Pre-selcted Plaintiffs Firm/Package Deal Criteria from the Select.
                return;
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            validation: validation,
            depositionSearchCriteria: isDepositionSearchCriteriaRemoved
                ? undefined
                : this.state.depositionSearchCriteria,
            noteSearchCriteria,
        });
    };

    isDepositionSearchCriteriaRemoved = (
        currentList: IQueryFilterCriteria[],
        queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[]
    ): boolean => {
        const previousDepositionCriteriaExists = queryFilterCriteriaSelectedBefore.some(
            (a) => a.staticItemId === 13
        );
        const curCriteriaExists = currentList.some((a) => a.staticItemId === 13);

        return previousDepositionCriteriaExists && !curCriteriaExists;
    };

    isNotesSearchCriteriaRemoved = (
        currentList: IQueryFilterCriteria[],
        queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[]
    ): boolean => {
        const previousNotesCriteriaExists = queryFilterCriteriaSelectedBefore.some(
            (a) => a.staticItemId === 12
        );
        const curCriteriaExists = currentList.some((a) => a.staticItemId === 12);

        return previousNotesCriteriaExists && !curCriteriaExists;
    };

    queryColumnsSelected = (optionsList: any) => {
        const validation = this.state.validation;
        const queryColumnsSelected = optionsList;

        this.setState({
            displayColumnsSelected: queryColumnsSelected,
            displayColumnsSelectedForExport: JSON.parse(JSON.stringify(queryColumnsSelected)),
            validation,
        });
    };

    queryColumnsSelectedForExport = (optionsList: any) => {
        this.setState({ displayColumnsSelectedForExport: optionsList });
    };

    getSelectedAsOfSubStatusValues = (item: IQueryFilterCriteria) => {
        const returnData: IQueryFilterCriteriaSelectionValue[] = [];
        if (item.selectedValues && item.selectedValues.length > 1) {
            for (let i = 1; i < item.selectedValues.length; i++) {
                returnData.push(item.selectedValues[i]);
            }
        }
        return returnData;
    };

    filterValuesSelected = (optionsList: any, item: IQueryFilterCriteria) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const noteSearchCriteria = this.state.noteSearchCriteria;
        const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
        if (match) {
            // Custom Logic for As Of Date logic.
            if (match.staticItemId == QueryStaticItemFields.CaseStatusAsOfDate.Id) {
                let asOfStatusSelectedValue: IQueryFilterCriteriaSelectionValue = {
                    label: '',
                    value: '',
                    id: 0,
                };
                if (match.selectedValues && match.selectedValues.length > 0)
                    asOfStatusSelectedValue = match.selectedValues[0];
                match.selectedValues = optionsList.splice(0, 3);
                if (match.selectedValues) match.selectedValues!.unshift(asOfStatusSelectedValue);
                else match.selectedValues = [asOfStatusSelectedValue];
                match.validationError = '';
            }
            // No 3 values Max limit for the following fields
            else if (
                match.staticItemId === QueryStaticItemFields.CaseStatus.Id ||
                match.staticItemId === QueryStaticItemFields.State.Id ||
                (match.isAssertionDescriptor &&
                    match.assertionDescriptorGuid &&
                    (match.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.DefenseBRead.Guid ||
                        match.assertionDescriptorGuid.toUpperCase() ===
                            AssertionDescriptors.PlaintiffBRead.Guid))
            ) {
                match.selectedValues = optionsList;
                match.validationError = '';
            } else {
                match.selectedValues = optionsList.splice(0, 3);
                match.validationError = '';
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    filterValuesSelectedAlt = (optionsList: any, name: string) => {
        const noteSearchDetails = this.state.noteSearchCriteria;
        let depositionSearchCriteria = this.state.depositionSearchCriteria;
        let caseExpertsSearchCriteria = this.state.caseExpertsSearchCriteria;

        switch (name) {
            case 'noteCategories': {
                noteSearchDetails.noteCategories = optionsList;
                let noteSubCategories = noteSearchDetails.noteSubCategories;
                if (
                    !noteSearchDetails.noteCategories ||
                    noteSearchDetails.noteCategories.length == 0
                )
                    noteSubCategories = [];
                else if (noteSubCategories) {
                    for (let i = 0; i < noteSubCategories.length; i++) {
                        const catMatch = noteSearchDetails.noteCategories.find(
                            (x) => x.id === noteSubCategories![i].parentId!
                        );
                        if (!catMatch) noteSubCategories.splice(i, 1);
                    }
                }
                noteSearchDetails.noteSubCategories = noteSubCategories;
                break;
            }
            case 'noteSubCategories':
                noteSearchDetails.noteSubCategories = optionsList;
                break;
            case 'noteSubTypes':
                noteSearchDetails.noteSubTypes = optionsList;
                break;
            case 'defenseCounselUsers':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.defenseCounselUsers = optionsList;
                break;
            case 'depositionCategories':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.categories = optionsList;
                break;
            case 'depositionStatuses':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.statuses = optionsList;
                break;
            case 'caseExpertsExpert':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.expertGuids = optionsList.map(
                    (opt: { value: string }) => opt.value
                );
                break;
            case 'caseExpertsRetainedBy':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.retainedByTypeIds = optionsList.map(
                    (opt: { id: number }) => opt.id
                );
                break;
            case 'caseExpertsReportStatus':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.reportStatusTypeIds = optionsList.map(
                    (opt: { id: number }) => opt.id
                );
                break;
            default:
        }
        this.setState({
            noteSearchCriteria: noteSearchDetails,
            depositionSearchCriteria,
            caseExpertsSearchCriteria,
        });
    };

    onSearchableSingleSelectChange = (selectedItem?: any) => {
        const depositionSearchCriteria: IDepositionUISearchModel =
            this.state.depositionSearchCriteria || {};
        depositionSearchCriteria.defenseCounsel = selectedItem;
        depositionSearchCriteria.defenseCounselUsers = [];
        this.setState({ depositionSearchCriteria: depositionSearchCriteria });
    };

    showExportModal = () => {
        const valid = this.validateCaseQueryInput(true);
        if (!valid) return;

        this.setState({ showExportModal: true });
    };

    closeExportModal = () => {
        this.setState({ showExportModal: false });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        let saveReport = false;
        let processExternally = false;
        let url = '';
        let count = 0;

        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value: {
                url = ApiRoutes.Query;
                const chkSave: any = document.getElementById('chkSave');
                if (chkSave && chkSave.checked) saveReport = true;

                count = await this.getQueryCount();
                break;
            }
            default:
        }

        if (count > maxRecordCountForWebProcessing) {
            this.setState({ showTooManyRecordsToProcessDialog: true, pendingResponse: false });
            return;
        }

        if (count > maxExportRecordCount) {
            saveReport = true;
            processExternally = true;
            const validation: IValidation = {};
            validation.export = [];
            this.setState({
                pendingResponse: false,
                showExportInBackgroundDialog: true,
                showExportModal: false,
                validation: validation,
            });
        }

        if (saveReport) url = url + '?save=true';

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (count! <= maxExportRecordCount) {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    this.setState({ pendingResponse: false, showExportModal: false });

                    let fileName = 'QueryResults.xlsx';
                    const blob = xhr.response;

                    const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                    if (contentDisposition) {
                        const contentDispositionItems = contentDisposition.split(';');
                        if (contentDispositionItems) {
                            for (let i = 0; i < contentDispositionItems.length; i++) {
                                const currentItem = contentDispositionItems[i];
                                if (currentItem.includes('filename=')) {
                                    const n = currentItem.indexOf('filename=') + 9;
                                    fileName = contentDispositionItems[i].substring(
                                        n + 1,
                                        contentDispositionItems[i].length - 1
                                    );
                                    break;
                                }
                            }
                        }
                    }
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = fileName;
                    a.dispatchEvent(new MouseEvent('click'));
                }

                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                    const validation = this.state.validation;
                    validation.export = [DisplayMessages.ReportGenerateError];
                    this.setState({
                        pendingResponse: false,
                        validation: validation,
                    });
                }
            }
        };

        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value: {
                const columns: IQueryColumnDefinitionModel[] =
                    this.getDisplayColumnsSelectedForExport();
                const querySettingsDisplay: IQuerySettingsModel = this.getQuerySettings();

                const querySettings: IQuerySettingsModel = {
                    ...querySettingsDisplay,
                    columns: columns,
                    saveFile: saveReport,
                    export: true,
                    processExternally: processExternally,
                };
                xhr.send(JSON.stringify(querySettings));
                break;
            }
            default:
        }
    };

    getDisplayColumnsSelectedForExport = (): IQueryColumnDefinitionModel[] => {
        const columns: IQueryColumnDefinitionModel[] = [];

        if (this.state.displayColumnsSelectedForExport) {
            for (let i = 0; i < this.state.displayColumnsSelectedForExport.length; i++) {
                const item: IQueryColumnDefinitionModel = {
                    name: this.state.displayColumnsSelectedForExport[i].value,
                    assertionDescriptorGuid:
                        this.state.displayColumnsSelectedForExport[i].assertionDescriptorGuid,
                    staticItemId: this.state.displayColumnsSelectedForExport[i].staticItemId,
                };
                columns.push(item);
            }
        }

        return columns;
    };

    includeQueryTypeStaticItems = (staticItem: IStaticItemLookupModel) =>
        staticItem.id === QueryStaticItemFields.DepositionSection.Id ||
        staticItem.id === QueryStaticItemFields.NotesSection.Id ||
        staticItem.id === QueryStaticItemFields.ExpertsSection.Id;

    addQueryTypeStaticItems = () => {
        let queryFilterCriteriaAll = [...this.state.queryFilterCriteriaAll];
        const requiredStaticItems = this.props.staticItems.filter(this.includeQueryTypeStaticItems);

        requiredStaticItems.forEach((staticItem) => {
            if (!queryFilterCriteriaAll.some((a) => a.staticItemId === staticItem.id)) {
                queryFilterCriteriaAll.push(this.staticItemToQueryFilterCriteriaMapper(staticItem));
            }
        });

        queryFilterCriteriaAll = queryFilterCriteriaAll.sort(Sort.compareValues('label'));
        return queryFilterCriteriaAll;
    };

    staticItemToQueryFilterCriteriaMapper = (
        staticItem: IStaticItemLookupModel
    ): IQueryFilterCriteria => ({
        label: CaseHelper.getCaseFileStaticItemDisplayName(this.props.staticItems, staticItem.id),
        value: UIHelper.getName(this.props.staticItems, staticItem.id),
        isAssertionDescriptor: false,
        staticItemId: staticItem.id,
    });

    handleQueryTypeChange = (selectedItem: any) => {
        const previousQueryType = this.state.queryType;
        const queryType = selectedItem ? selectedItem.id : 0;
        if (!queryType) return;
        let queryFilterCriteriaAll = [...this.state.queryFilterCriteriaAll];
        const queryFilterCriteriaSelected = [...this.state.queryFilterCriteriaSelected];
        let noteSearchCriteria = this.state.noteSearchCriteria;

        let initDisplayValues = false;
        let isZoneORStrategyQueryType = false;

        const removeStaticItemFromAvailableCriteria = (staticItemId: number) => {
            for (let i = 0; i < queryFilterCriteriaAll.length; i++) {
                if (queryFilterCriteriaAll[i].staticItemId === staticItemId) {
                    queryFilterCriteriaAll.splice(i, 1);

                    // Remove it from the selected list if it was previously selected
                    for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                        if (queryFilterCriteriaSelected[i].staticItemId === staticItemId) {
                            queryFilterCriteriaSelected.splice(i, 1);
                            break;
                        }
                    }
                    break;
                }
            }
        };

        const clearNotesCriteriaSearchFields = (currentQueryType: number) => {
            //clear the Notes search criteria field values if the query type is switched from zone or strategy notes to any other type.
            // for the remaining all types we need to keep the fiedls search values available.
            if (
                previousQueryType == QueryTypes.ZoneNotes.Value ||
                previousQueryType == QueryTypes.StrategyNote.Value
            ) {
                if (previousQueryType != currentQueryType) {
                    //clear the note search criteria.
                    noteSearchCriteria = {};
                }
            }
        };

        switch (queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
            case QueryTypes.CaseExperts.Value: {
                clearNotesCriteriaSearchFields(queryType);

                queryFilterCriteriaAll = this.addQueryTypeStaticItems();

                if (queryType === QueryTypes.CaseNotes.Value) {
                    noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
                } else if (queryType === QueryTypes.CaseDetails.Value) {
                    initDisplayValues = true;
                }
                break;
            }
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value: {
                noteSearchCriteria = {};
                isZoneORStrategyQueryType = true;
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.NotesSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.DepositionSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.ExpertsSection.Id);

                if (queryType === QueryTypes.ZoneNotes.Value) {
                    noteSearchCriteria.noteType = { id: NoteTypes.ZoneNote };
                }
                if (queryType === QueryTypes.StrategyNote.Value) {
                    noteSearchCriteria.noteType = { id: NoteTypes.StrategyNote };
                }
                break;
            }
            default:
        }

        if (initDisplayValues) {
            const defaultColumns = QueryToolDefaultColumnList;
            let displayColumnsSelectedInit: IQueryFilterCriteria[];
            let displayColumnsSelectedForExportInit: IQueryFilterCriteria[];
            const displayColumnsAll = this.state.displayColumnsAll;

            const displayColumnsSelectedDefault: IQueryFilterCriteria[] = defaultColumns.map(
                (c) => {
                    const value: IQueryFilterCriteria | undefined = displayColumnsAll.find((o) => {
                        return (
                            (c.assertionDescriptorGuid &&
                                o.assertionDescriptorGuid?.toUpperCase() ===
                                    c.assertionDescriptorGuid?.toUpperCase()) ||
                            (c.staticItemId && o.staticItemId === c.staticItemId)
                        );
                    });
                    if (value) return value;
                    else {
                        const name: string = c.name ? c.name : '';
                        const value: IQueryFilterCriteria = {
                            label: name,
                            value: name,
                        };
                        return value;
                    }
                }
            );

            if (this.state.displayColumnsSelected.length == 0) {
                displayColumnsSelectedInit = [...displayColumnsSelectedDefault];
            } else {
                displayColumnsSelectedInit = [...this.state.displayColumnsSelected];
            }

            if (this.state.displayColumnsSelectedForExport.length == 0) {
                displayColumnsSelectedForExportInit = [...displayColumnsSelectedDefault];
            } else {
                displayColumnsSelectedForExportInit = [
                    ...this.state.displayColumnsSelectedForExport,
                ];
            }

            //set state for noteSearchCriteria only for zone notes or stratagey notes.

            this.setState({
                queryType: queryType,
                queryFilterCriteriaAll,
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
                caseResults: {},
                noteResults: undefined,
                noteSearchCriteria: noteSearchCriteria,
                displayColumnsSelected: displayColumnsSelectedInit,
                displayColumnsSelectedForExport: displayColumnsSelectedForExportInit,
            });
        } else {
            this.setState({
                queryType,
                queryFilterCriteriaAll,
                queryFilterCriteriaSelected,
                caseResults: {},
                noteResults: undefined,
                noteSearchCriteria: noteSearchCriteria,
                depositionSearchCriteria: isZoneORStrategyQueryType
                    ? {}
                    : this.state.depositionSearchCriteria,
                caseExpertsSearchCriteria: isZoneORStrategyQueryType
                    ? {}
                    : this.state.caseExpertsSearchCriteria,
                displayColumnsSelected: [],
                displayColumnsSelectedForExport: [],
            });
        }
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        item: IQueryFilterCriteria
    ) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
        if (!match) return;

        if (item.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.props.assertionDescriptors.find(
                (x) => x.guid == item.assertionDescriptorGuid
            );
            if (assertionDescriptorMatch) {
                const valueSourceTypeId = assertionDescriptorMatch.valueSourceType!.id;
                const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                    ? assertionDescriptorMatch.valueDisplayType.id
                    : 0;

                const { name, value } = event.target;

                switch (valueSourceTypeId) {
                    case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                        match.booleanValue = value == 'true' ? true : false;
                        break;
                    case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                        switch (valueDisplayTypeId) {
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                            case AssertionDescriptorValueDisplayTypes.Number.Value:
                            case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                if (name === 'numericValueFrom') {
                                    match.numericValueFromText = value;
                                    if (isNaN(Number(value))) {
                                        match.validationError = 'Enter a valid number';
                                    } else {
                                        match.validationError = '';
                                        match.numericValueFrom = Number(value);
                                    }
                                } else if (name === 'numericValueTo') {
                                    match.numericValueToText = value;
                                    if (isNaN(Number(value))) {
                                        match.validationError = 'Enter a valid number';
                                    } else {
                                        match.validationError = '';
                                        match.numericValueTo = Number(value);
                                    }
                                }
                                break;
                            case AssertionDescriptorValueDisplayTypes.DateTime.Value: {
                                match.validationError = '';

                                if (name === 'startDate') {
                                    match.startDate = value;
                                    match.validationError =
                                        !match.startDate || Common.isValidDate(match.startDate)
                                            ? ''
                                            : 'Invalid Date';
                                } else if (name === 'endDate') {
                                    match.endDate = value;
                                    match.validationError =
                                        !match.endDate || Common.isValidDate(match.endDate)
                                            ? ''
                                            : 'Invalid Date';
                                }
                                break;
                            }
                            default:
                                match.validationError = '';
                                match.text = value;
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (item.staticItemId) {
                case QueryStaticItemFields.CaseNumber.Id:
                case QueryStaticItemFields.UniqueCaseID.Id:
                    if (event.target.value.length > 0) item.validationError = '';
                    match.text = event.target.value;
                    break;
                case QueryStaticItemFields.CasePriority.Id:
                    match.booleanValue = event.target.value == 'true' ? true : false;
                    item.validationError = '';
                    break;

                case QueryStaticItemFields.ResolutionDate.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'resolutionDateStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'resolutionDateEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
                case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'asOfStatus':
                            match.selectedValues = [
                                { label: '', value: '', id: parseInt(event.target.value) },
                            ];
                            break;
                        case 'asOfStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'asOfEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
                case QueryStaticItemFields.NotesSection.Id:
                    item.validationError = '';
                    if (event.target.name === 'noteStartDate') {
                        match.startDate = event.target.value;
                    } else if (event.target.name === 'noteEndDate') {
                        match.endDate = event.target.value;
                    }
                    break;
                case QueryStaticItemFields.PackageSettlement.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'packageSettlementName':
                            match.text = event.target.value;
                            break;
                        case 'packageSettlementYesNo':
                            match.booleanValue = event.target.value == 'true' ? true : false;
                            break;
                        case 'packageSettlementStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'packageSettlementEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
            }
        }

        this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
    };

    // For Assertion Descriptors, identifier needs to be AssertionDescriptor.Guid
    handleCheckmateSingleSelectChange = (
        selectedItem: any,
        name: string,
        item?: IQueryFilterCriteria
    ) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const noteSearchCriteria = this.state.noteSearchCriteria;

        if (item) {
            const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
            if (!match) return;

            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.props.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (assertionDescriptorMatch) {
                    const valueSourceTypeId = assertionDescriptorMatch.valueSourceType!.id;
                    if (valueSourceTypeId === AssertionDescriptorValueSourceTypeEnum.Boolean.Value)
                        match.booleanValue =
                            selectedItem && selectedItem.value
                                ? selectedItem.id === 0
                                    ? false
                                    : true
                                : undefined;
                }
            } else {
                switch (item.staticItemId) {
                    case QueryStaticItemFields.CasePriority.Id:
                    case QueryStaticItemFields.PackageSettlement.Id:
                        match.booleanValue =
                            selectedItem && selectedItem.value
                                ? selectedItem.id === 0
                                    ? false
                                    : true
                                : undefined;
                        break;
                    case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    case QueryStaticItemFields.ResolutionDate.Id: {
                        const value = selectedItem ? selectedItem.id : 0;
                        match.selectedValues = value ? [{ label: '', value: '', id: value }] : [];
                        break;
                    }
                    default:
                        break;
                }
            }
        } else {
            switch (name) {
                case 'private':
                    noteSearchCriteria.private =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'allowLocalEdit':
                    noteSearchCriteria.allowLocalEdit =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'modifiedByLocal':
                    noteSearchCriteria.modifiedByLocal =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'hasAttachment':
                    noteSearchCriteria.hasAttachment =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'purpose': {
                    const purposeId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.purpose = purposeId ? [{ id: purposeId }] : undefined;
                    if (purposeId != NotePurposeTypesEnum.Approval)
                        noteSearchCriteria.approvalStatus = undefined;
                    else if (purposeId != NotePurposeTypesEnum.Acknowledgment)
                        noteSearchCriteria.acknowledgementStatus = undefined;
                    break;
                }
                case 'approvalStatus': {
                    const approvalStatusId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.approvalStatus = approvalStatusId
                        ? [{ id: approvalStatusId }]
                        : undefined;
                    break;
                }
                case 'acknowledgementStatus': {
                    const acknowledgementStatusId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.acknowledgementStatus = acknowledgementStatusId
                        ? [{ id: acknowledgementStatusId }]
                        : undefined;
                    break;
                }
                case 'relatedTo': {
                    const isAssociatedCaseExpertCaseNote = !selectedItem
                        ? undefined
                        : selectedItem.value === 'CASE_EXPERTS';

                    noteSearchCriteria.isAssociatedCaseExpertCaseNote =
                        isAssociatedCaseExpertCaseNote;
                    break;
                }
                default:
                    break;
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    handleChangeAlt = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const noteSearchCriteria = this.state.noteSearchCriteria;
        let depositionSearchCriteria = this.state.depositionSearchCriteria;
        switch (event.target.name) {
            case 'noteStartDate':
                noteSearchCriteria.startDate = event.target.value;
                noteSearchCriteria.validationError =
                    !noteSearchCriteria.startDate ||
                    Common.isValidDate(noteSearchCriteria.startDate)
                        ? ''
                        : 'Invalid Date';
                break;
            case 'noteEndDate':
                noteSearchCriteria.endDate = event.target.value;
                noteSearchCriteria.validationError =
                    !noteSearchCriteria.endDate || Common.isValidDate(noteSearchCriteria.endDate)
                        ? ''
                        : 'Invalid Date';
                break;
            case 'depositionStartDate':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.startDate = event.target.value;
                depositionSearchCriteria.validationError =
                    !depositionSearchCriteria.startDate ||
                    Common.isValidDate(depositionSearchCriteria.startDate)
                        ? ''
                        : 'Invalid Date';
                break;
            case 'depositionEndDate':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.endDate = event.target.value;
                depositionSearchCriteria.validationError =
                    !depositionSearchCriteria.endDate ||
                    Common.isValidDate(depositionSearchCriteria.endDate)
                        ? ''
                        : 'Invalid Date';
                break;
            default:
        }

        this.setState({
            depositionSearchCriteria: depositionSearchCriteria,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item?: IQueryFilterCriteria
    ) => {
        const { name, checked } = event.target;

        if (item) {
            const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
            const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
            if (!match) return;
            if (match.staticItemId == QueryStaticItemFields.CasePriority.Id)
                match.booleanValue = checked;
            else if (name === 'chkCriteriaRequired') match.required = checked;
            else if (name === 'chkExactTextMatch') match.exactTextMatch = checked;
            else if (name === 'chkBlank') {
                match.condition = checked;
                if (checked) {
                    match.selectedValues = undefined;
                    match.text = '';
                    match.exactTextMatch = undefined;
                    match.booleanValue = undefined;
                    match.startDate = '';
                    match.endDate = '';
                    match.numericValueFromText = '';
                    match.numericValueFrom = undefined;
                    match.numericValueToText = '';
                    match.numericValueTo = undefined;
                }
            }

            this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
        } else if (name === 'chkEasyUpdate') {
            this.setState({ enableEasyUpdate: checked });
        } else if (this.state.mode === QueryToolModeEnum.Redirect) {
            let selectedGuidsForRedirect = this.state.selectedGuidsForRedirect;
            let selectAllCasesForRedirect = false;

            if (name === 'chkSelectAllCases') {
                selectedGuidsForRedirect = [];
                if (checked) {
                    selectAllCasesForRedirect = true;
                    let packageDealIndex = -1;
                    if (this.state.caseResults.columns) {
                        for (let i = 0; i < this.state.caseResults.columns.length; i++) {
                            if (
                                this.state.caseResults.columns[i].staticItemId ===
                                QueryStaticItemFields.PackageSettlement.Id
                            )
                                packageDealIndex = i;
                        }
                    }
                    if (this.state.caseResults.values) {
                        this.state.caseResults.values.map((row: string[]) => {
                            if (!row[packageDealIndex]) selectedGuidsForRedirect.push(row[0]);
                        }); // Index 0 is ALWAYS Case Guid;
                    }
                }
            } else {
                if (checked) {
                    if (this.state.selectedGuidsForRedirect.findIndex((x) => x === name) === -1)
                        selectedGuidsForRedirect.push(name);
                } else {
                    if (this.state.selectedGuidsForRedirect.findIndex((x) => x === name) >= 0)
                        selectedGuidsForRedirect.splice(
                            this.state.selectedGuidsForRedirect.findIndex((x) => x === name),
                            1
                        );
                }
            }

            this.setState({
                selectedGuidsForRedirect,
                selectAllCasesForRedirect,
            });
        }
    };

    buildQueryFilterCriteriaValuesColumn = (item: IQueryFilterCriteria): any => {
        const content = [];

        const isInputDisabled = (item: any) => {
            //item.condition == true means (user selected "Blank" ) false means => user selected "Not Blank"
            const disableFilter =
                item.readOnly || item.condition === true || item.condition === false;
            if (disableFilter) {
                item.validationError = undefined;
            }

            return disableFilter;
        };

        if (item.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.props.assertionDescriptors.find((x) => {
                return (
                    item.assertionDescriptorGuid !== undefined &&
                    x.guid !== undefined &&
                    x.guid.toUpperCase() === item.assertionDescriptorGuid.toUpperCase()
                );
            });
            if (assertionDescriptorMatch) {
                switch (assertionDescriptorMatch.valueSourceType!.id) {
                    case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                        if (
                            assertionDescriptorMatch.assertionDescriptorValues &&
                            assertionDescriptorMatch.assertionDescriptorValues.length > 0
                        ) {
                            const assertionDescriptorValuesAll: IQueryFilterCriteriaSelectionValue[] =
                                [];
                            for (
                                let i = 0;
                                i < assertionDescriptorMatch.assertionDescriptorValues.length;
                                i++
                            ) {
                                assertionDescriptorValuesAll.push({
                                    label: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .text!,
                                    value: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .guid!,
                                    guid: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .guid!,
                                });
                            }
                            content.push(
                                <>
                                    <CheckmateSelect
                                        isMulti={true}
                                        options={assertionDescriptorValuesAll}
                                        value={item.selectedValues}
                                        onChange={(e: any) => {
                                            this.filterValuesSelected(e, item);
                                        }}
                                        isDisabled={isInputDisabled(item)}
                                    />
                                    <span className="text-danger">{item.validationError}</span>
                                </>
                            );
                        }
                        break;
                    case AssertionDescriptorValueSourceTypeEnum.Boolean.Value: {
                        const disableSelect = isInputDisabled(item);
                        //TODO: Set the default value to No boolean criteria.
                        // const booleanValue = item && item.booleanValue !== undefined ? item.booleanValue : false;
                        // item.booleanValue = booleanValue;

                        content.push(
                            <div style={{ width: '135px' }}>
                                <CheckmateSelect
                                    options={CheckmateSelectHelper.getBooleanOptions()}
                                    value={CheckmateSelectHelper.getBooleanValue(item.booleanValue)}
                                    isDisabled={disableSelect}
                                    name={item.assertionDescriptorGuid}
                                    onChange={(selectedItem: any) => {
                                        this.handleCheckmateSingleSelectChange(
                                            selectedItem,
                                            item.value,
                                            item
                                        );
                                    }}
                                />
                                <span className="text-danger">{item.validationError}</span>
                            </div>
                        );
                        break;
                    }
                    case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                        const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                            ? assertionDescriptorMatch.valueDisplayType.id
                            : 0;
                        switch (valueDisplayTypeId) {
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                            case AssertionDescriptorValueDisplayTypes.Number.Value:
                            case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                content.push(
                                    <>
                                        {valueDisplayTypeId ==
                                        AssertionDescriptorValueDisplayTypes.Currency.Value ? (
                                            <span>$&nbsp;</span>
                                        ) : null}
                                        <input
                                            className="form-control d-inline-block"
                                            type="number"
                                            name="numericValueFrom"
                                            disabled={isInputDisabled(item)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                            value={item.numericValueFromText}
                                        ></input>
                                        <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                        <input
                                            className="form-control d-inline-block"
                                            type="number"
                                            name="numericValueTo"
                                            disabled={isInputDisabled(item)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                            value={item.numericValueToText}
                                        ></input>
                                        <span className="text-danger ms-2">
                                            {item.validationError}
                                        </span>
                                    </>
                                );
                                break;
                            case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                content.push(
                                    <DateRangePicker
                                        disableEndDate={isInputDisabled(item)}
                                        disableStartDate={isInputDisabled(item)}
                                        endDate={item.endDate}
                                        startDate={item.startDate}
                                        onEndDateChange={(e) => this.handleChange(e, item)}
                                        onStartDateChange={(e) => this.handleChange(e, item)}
                                        validationError={item.validationError}
                                        endInputFieldName="endDate"
                                        startInputFieldName="startDate"
                                    />
                                );
                                break;
                            default:
                                content.push(
                                    <span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter at least 3 characters"
                                            disabled={isInputDisabled(item)}
                                            value={item.text}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                        ></input>
                                        <span className="text-danger">{item.validationError}</span>
                                    </span>
                                );
                                break;
                        }
                        break;
                    }
                    default:
                        content.push(<span className="text-danger">Unexpected Error!</span>);
                        break;
                }
            } else {
                content.push(<span className="text-danger">Unexpected Error!</span>);
            }
        } else {
            switch (item.staticItemId) {
                case QueryStaticItemFields.CaseNumber.Id:
                case QueryStaticItemFields.UniqueCaseID.Id:
                    content.push(
                        <span>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Enter at least 3 characters"
                                name={item.value}
                                disabled={isInputDisabled(item)}
                                value={item.text}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleChange(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
                    break;
                case QueryStaticItemFields.CaseStatus.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.caseStatusesForQueryCriteria}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.CaseManager.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.caseManagers}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.LocalCounsel.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.zoneDefenseCounselOptions}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.OtherDefenseCounsels.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.otherDefenseCounselsForZone}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.CasePriority.Id:
                    content.push(
                        <div className="col-sm-2">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                isDisabled={isInputDisabled(item)}
                                value={CheckmateSelectHelper.getBooleanValue(item.booleanValue)}
                                name={UIHelper.getName(
                                    this.props.staticItems,
                                    QueryStaticItemFields.CasePriority.Id
                                )}
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(
                                        e,
                                        UIHelper.getName(
                                            this.props.staticItems,
                                            QueryStaticItemFields.CasePriority.Id
                                        ),
                                        item
                                    );
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.ResolutionDate.Id:
                    content.push(
                        <DateRangePicker
                            disableEndDate={item.readOnly}
                            disableStartDate={item.readOnly}
                            endDate={item.endDate}
                            onEndDateChange={(e) => this.handleChange(e, item)}
                            onStartDateChange={(e) => this.handleChange(e, item)}
                            startDate={item.startDate}
                            validationError={item.validationError}
                            endInputFieldName="resolutionDateEndDate"
                            startInputFieldName="resolutionDateStartDate"
                        />
                    );
                    break;
                case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    content.push(
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.getAsOfDateStatusLookupValues()
                                        )}
                                        isDisabled={item.readOnly}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.getAsOfDateStatusLookupValues(),
                                            item.selectedValues && item.selectedValues.length > 0
                                                ? item.selectedValues[0].id!
                                                : 0
                                        )}
                                        name="asOfStatus"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSingleSelectChange(
                                                e,
                                                'asOfStatus',
                                                item
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        isMulti={true}
                                        options={this.getSubStatusLookupValues(
                                            item.selectedValues && item.selectedValues.length > 0
                                                ? item.selectedValues[0].id!
                                                : 0
                                        )}
                                        isDisabled={item.readOnly}
                                        value={this.getSelectedAsOfSubStatusValues(item)}
                                        onChange={(e: any) => {
                                            this.filterValuesSelected(e, item);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <DateRangePicker
                                    disableEndDate={item.readOnly}
                                    disableStartDate={item.readOnly}
                                    endDate={item.endDate}
                                    onEndDateChange={(e) => this.handleChange(e, item)}
                                    onStartDateChange={(e) => this.handleChange(e, item)}
                                    startDate={item.startDate}
                                    validationError={item.validationError}
                                    endInputFieldName="asOfEndDate"
                                    startInputFieldName="asOfStartDate"
                                />
                            </div>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.NotesSection.Id:
                    content.push(
                        <NotesSectionCriteriaValues
                            acknowledgmentStatusTypes={this.props.acknowledgmentStatusTypes}
                            approvalStatusTypes={this.props.approvalStatusTypes}
                            hideRowDividers
                            item={item}
                            noteCategoryTypes={this.props.noteCategoryTypes}
                            notePurposeTypes={this.props.notePurposeTypes}
                            noteSearchCriteria={this.state.noteSearchCriteria}
                            queryType={this.state.queryType}
                            user={this.props.user}
                            onChangeAlt={this.handleChangeAlt}
                            onCheckmateSingleSelectChange={this.handleCheckmateSingleSelectChange}
                            onFilterValuesSelectedAlt={this.filterValuesSelectedAlt}
                        />
                    );
                    break;
                case QueryStaticItemFields.DepositionSection.Id:
                    content.push(
                        <DepositionSectionCriteriaValues
                            defenseCounselsOptions={this.getAllDefenseCounselMultiSelectValuesForZone()}
                            depositionCategoryOptions={this.state.depositionCategoryOptions}
                            depositionSearchCriteria={this.state.depositionSearchCriteria}
                            depoStatusTypeOptions={this.state.depoStatusTypeOptions}
                            disableInputs={false}
                            item={item}
                            onChangeAlt={this.handleChangeAlt}
                            onCheckedChange={this.handleCheckedChange}
                            onFilterValuesSelectedAlt={this.filterValuesSelectedAlt}
                            onSearchableSingleSelectChange={this.onSearchableSingleSelectChange}
                            zoneUsers={this.state.allUsers}
                        />
                    );
                    break;
                case QueryStaticItemFields.State.Id:
                    content.push(
                        <div>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.usStatesOptions}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.PackageSettlement.Id:
                    content.push(
                        <PackageDealsCriteriaValues
                            assertionDescriptors={this.props.assertionDescriptors}
                            disableInputs={isInputDisabled(item)}
                            item={item}
                            onChange={this.handleChange}
                            onFilterValuesSelected={this.filterValuesSelected}
                            packageDeals={this.props.packageDeals}
                        />
                    );
                    break;
                case QueryStaticItemFields.ExpertsSection.Id:
                    content.push(
                        <ExpertsCriteriaSelector
                            error={item.validationError}
                            selectedExpertGuids={
                                this.state.caseExpertsSearchCriteria?.expertGuids ?? []
                            }
                            selectedRetainedByTypeIds={
                                this.state.caseExpertsSearchCriteria?.retainedByTypeIds ?? []
                            }
                            selectedReportStatusTypeIds={
                                this.state.caseExpertsSearchCriteria?.reportStatusTypeIds ?? []
                            }
                            onExpertChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsExpert');
                            }}
                            onRetainedByChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsRetainedBy');
                            }}
                            onReportStatusChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsReportStatus');
                            }}
                            readonly={item.condition === true || item.condition === false}
                        />
                    );
                    break;
            }
        }

        return content;
    };

    getQuerySettings = (): IQuerySettingsModel => {
        const columns: IQueryColumnDefinitionModel[] = [];
        if (this.state.displayColumnsSelected) {
            for (let i = 0; i < this.state.displayColumnsSelected.length; i++) {
                const item: IQueryColumnDefinitionModel = {
                    name: this.state.displayColumnsSelected[i].value,
                    assertionDescriptorGuid:
                        this.state.displayColumnsSelected[i].assertionDescriptorGuid,
                    staticItemId: this.state.displayColumnsSelected[i].staticItemId,
                };
                columns.push(item);
            }
        }

        const filters = this.processInputQueryFilters();
        const queryType = { id: this.state.queryType };

        const querySettings: IQuerySettingsModel = {
            filters,
            columns,
            queryType,
        };

        if (this.state.depositionSearchCriteria!) {
            querySettings.depositionSearchCriteria = this.state.depositionSearchCriteria;
        }

        if (this.state.caseExpertsSearchCriteria!) {
            querySettings.caseExpertSearchCriteria = this.state.caseExpertsSearchCriteria;
        }

        if (this.state.noteSearchCriteria!) {
            querySettings.noteSearchCriteria = this.state.noteSearchCriteria;
            querySettings.noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
        }

        if (this.state.queryType === QueryTypes.CaseNotes.Value && this.state.noteSearchCriteria) {
            const noteSearchCriteria = JSON.parse(JSON.stringify(this.state.noteSearchCriteria));
            if (noteSearchCriteria.startDate)
                noteSearchCriteria.startDate = Common.localStartOfDayInUTC(
                    noteSearchCriteria.startDate
                );
            if (noteSearchCriteria.endDate)
                noteSearchCriteria.endDate = Common.localEndOfDayInUTC(noteSearchCriteria.endDate);
            querySettings.noteSearchCriteria = noteSearchCriteria;
        } else if (
            this.state.queryType === QueryTypes.Deposition.Value &&
            this.state.depositionSearchCriteria
        ) {
            querySettings.depositionSearchCriteria = this.state.depositionSearchCriteria;
        }

        if (this.props.redirectModel?.redirectType) {
            querySettings.querySubType = { id: this.props.redirectModel.redirectType };
        }

        return querySettings;
    };

    runQuery = async () => {
        this.setState({
            caseResultsCount: 0,
            caseResults: {},
            caseExpertsResults: [],
            noteResults: [],
            depositionResults: [],
        });

        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
                {
                    const valid = this.validateCaseQueryInput();
                    if (!valid) return;

                    this.setState({
                        pendingResponse: true,
                        validation: {},
                        showTooManyRecordsToProcessDialog: false,
                        caseResultsCount: 0,
                    });

                    const querySettings = this.getQuerySettings();
                    const countResponse = await _apiClient.getCaseQueryCountLegacy(querySettings);

                    if (countResponse.httpResponse.status == 401) {
                        window.location.reload();
                        return;
                    }

                    if (countResponse.errorMessage) {
                        this.setState({
                            validation: ValidateUtils.parseErrors(
                                countResponse.errors,
                                countResponse.errorMessage
                            ),
                            pendingResponse: false,
                        });
                        return;
                    }

                    if (countResponse.httpResponse.status > 399) {
                        this.setState({
                            validation: { model: [DisplayMessages.UnexpectedError] },
                            pendingResponse: false,
                        });
                        return;
                    }

                    const count = countResponse.payload!.data;

                    this.setState({ displayRecordCount: true });

                    if (count === 0) {
                        this.setState({
                            caseResultsCount: count,
                            pendingResponse: false,
                        });
                        return;
                    }

                    if (count > maxRecordCountForWebProcessing) {
                        this.setState({
                            caseResultsCount: count,
                            showTooManyRecordsToProcessDialog: true,
                            pendingResponse: false,
                        });
                        return;
                    }

                    if (count > maxDisplayRecordCount) {
                        this.setState({
                            showTooManyRecordsToDisplayDialog: true,
                            pendingResponse: false,
                        });
                        return;
                    }

                    switch (this.state.queryType) {
                        case QueryTypes.CaseExperts.Value: {
                            const caseExpertsResponse = await _apiClient.getCaseQueryResultsLegacy<
                                ICaseExpertModel[]
                            >(querySettings);
                            if (caseExpertsResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseExpertsResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseExpertsResponse.errors,
                                        caseExpertsResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                caseExpertsResults: caseExpertsResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        case QueryTypes.CaseDetails.Value: {
                            const caseResponse =
                                await _apiClient.getCaseQueryResultsLegacy<IQueryResultModel>(
                                    querySettings
                                );
                            if (caseResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseResponse.errors,
                                        caseResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                caseResults: caseResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        case QueryTypes.CaseNotes.Value: {
                            const caseNotesResponse =
                                await _apiClient.getCaseNotesQueryResultsLegacy(querySettings);
                            if (caseNotesResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseNotesResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseNotesResponse.errors,
                                        caseNotesResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                noteResults: caseNotesResponse.payload!,
                                pendingResponse: false,
                            });
                            break;
                        }
                        case QueryTypes.Deposition.Value: {
                            const depoResponse = await _apiClient.getDepositionQueryResultsLegacy(
                                querySettings
                            );
                            if (depoResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (depoResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        depoResponse.errors,
                                        depoResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                    caseResultsCount: count,
                                });
                                return;
                            }

                            this.setState({
                                depositionResults: depoResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        default:
                    }
                }
                break;
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value: {
                const valid = this.validateStrategyZoneNotesQueryInput();
                if (!valid) return;

                this.setState({ pendingResponse: true, validation: {} });

                const noteSearchCriteria = JSON.parse(
                    JSON.stringify(this.state.noteSearchCriteria)
                );
                if (noteSearchCriteria.startDate)
                    noteSearchCriteria.startDate = Common.localStartOfDayInUTC(
                        noteSearchCriteria.startDate
                    );
                if (noteSearchCriteria.endDate)
                    noteSearchCriteria.endDate = Common.localEndOfDayInUTC(
                        noteSearchCriteria.endDate
                    );

                const notesResponse = await _apiClient.searchNotes(noteSearchCriteria);
                if (notesResponse.httpResponse.status == 401) {
                    window.location.reload();
                    return;
                }
                if (notesResponse.errorMessage) {
                    this.setState({
                        validation: ValidateUtils.parseErrors(
                            notesResponse.errors,
                            notesResponse.errorMessage
                        ),
                        pendingResponse: false,
                    });
                    return;
                }

                this.setState({ noteResults: notesResponse.payload!, pendingResponse: false });
                return;
            }
            default:
                return;
        }
    };

    getQueryCount = async () => {
        const querySettings: IQuerySettingsModel = this.getQuerySettings();
        const caseResponse = await _apiClient.getCaseQueryCountLegacy(querySettings);

        if (caseResponse.httpResponse.status == 401) {
            window.location.reload();
            return 0;
        }
        if (caseResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    caseResponse.errors,
                    caseResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return 0;
        }

        return caseResponse.payload!.data;
    };

    validateStrategyZoneNotesQueryInput = () => {
        let validationErrors = false;
        const notesCriteriaSelected = this.state.noteSearchCriteria;
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const strategyNotesFieldValidation = () => {
            let validationMessage = '';
            let validationRequired = false;

            const isMissingDates =
                !notesCriteriaSelected.startDate && !notesCriteriaSelected.endDate;

            const isMissingNoteCategories =
                (notesCriteriaSelected.noteCategories?.length ?? 0) === 0;

            const isMissingPurpose = !notesCriteriaSelected.purpose?.some((x) => x.id && x.id > 0);

            const isMissingApprovalStatus = !notesCriteriaSelected.approvalStatus?.some(
                (x) => x.id && x.id > 0
            );

            const hasNoValuesSet =
                isMissingDates &&
                isMissingNoteCategories &&
                isMissingPurpose &&
                isMissingApprovalStatus &&
                notesCriteriaSelected.private === undefined &&
                notesCriteriaSelected.allowLocalEdit === undefined &&
                notesCriteriaSelected.modifiedByLocal === undefined &&
                notesCriteriaSelected.hasAttachment === undefined &&
                notesCriteriaSelected.isAssociatedCaseExpertCaseNote === undefined;

            validationRequired = !notesCriteriaSelected || hasNoValuesSet;

            if (this.state.queryType === QueryTypes.StrategyNote.Value) {
                //this has additional field in the StrategyNote
                const isMissingNoteSubType =
                    (notesCriteriaSelected.noteSubTypes?.length ?? 0) === 0;
                validationRequired = isMissingNoteSubType && validationRequired;
            }

            if (validationRequired) {
                validationMessage = 'Enter at least one Case Note Criteria';
                return validationMessage;
            }

            if (!validationRequired) {
                if (
                    this.state.noteSearchCriteria!.startDate! &&
                    this.state.noteSearchCriteria!.endDate!
                ) {
                    if (
                        this.state.noteSearchCriteria!.startDate >
                        this.state.noteSearchCriteria!.endDate
                    ) {
                        validationRequired = true;
                    }
                }
                if (validationRequired) {
                    validationMessage = 'From Date must be before To Date';
                }
            }
            return validationMessage;
        };

        const item = notesCriteriaSelected;
        switch (this.state.queryType) {
            case QueryTypes.StrategyNote.Value: {
                const noteValidationMessage: string = strategyNotesFieldValidation();
                if (noteValidationMessage.length > 0) {
                    item.validationError = noteValidationMessage;
                    validationErrors = true;
                } else {
                    item.validationError = undefined;
                    validationErrors = false;
                }
                break;
            }
            case QueryTypes.ZoneNotes.Value: {
                const validationMessage: string = strategyNotesFieldValidation();
                if (validationMessage.length > 0) {
                    item.validationError = validationMessage;
                    validationErrors = true;
                } else {
                    item.validationError = undefined;
                    validationErrors = false;
                }
                break;
            }
            default:
                break;
        }

        if (validationErrors) {
            this.setState({
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
                noteSearchCriteria: item,
            });
            return false;
        }

        return true;
    };

    // todo: very high cyclomatic complexitity in this function - it could really use a refactoring
    validateCaseQueryInput = (isExport?: boolean) => {
        let validationErrors = false;
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const itemRequiresValue = (item: any) => {
            return (
                item.condition === undefined ||
                item.condition === ConditionSelectorEnums.EqualTo ||
                item.condition === ConditionSelectorEnums.NotEqualTo
            );
        };

        const verifyBooleanValidation = (item: any) => {
            if (itemRequiresValue(item) && item.booleanValue === undefined) {
                item.validationError = 'Select Yes / No';
                validationErrors = true;
            } else {
                item.validationError = undefined;
                validationErrors = false;
            }
        };

        const depositionSearchFieldValidation = (item: any) => {
            let validationMessage = '';
            let validationRequired = false;
            if (itemRequiresValue(item)) {
                validationRequired =
                    !this.state.depositionSearchCriteria ||
                    (!this.state.depositionSearchCriteria.startDate &&
                        !this.state.depositionSearchCriteria.endDate &&
                        (!this.state.depositionSearchCriteria.categories ||
                            this.state.depositionSearchCriteria.categories.length == 0 ||
                            this.state.depositionSearchCriteria.categories.filter(
                                (x) => x.id && x.id > 0
                            ).length === 0) &&
                        (!this.state.depositionSearchCriteria.statuses ||
                            this.state.depositionSearchCriteria.statuses.filter(
                                (x) => x.id && x.id > 0
                            ).length === 0) &&
                        !this.state.depositionSearchCriteria.defenseCounsel);

                if (validationRequired) {
                    validationMessage = 'Enter at least one Case deposition Criteria';
                    return validationMessage;
                }

                if (!validationRequired) {
                    if (
                        this.state.depositionSearchCriteria!.startDate! &&
                        this.state.depositionSearchCriteria!.endDate!
                    ) {
                        if (
                            this.state.depositionSearchCriteria!.startDate >
                            this.state.depositionSearchCriteria!.endDate
                        ) {
                            validationRequired = true;
                        }
                    }
                    if (validationRequired) {
                        validationMessage = 'From Date must be before To Date';
                    }
                }
            }
            return validationMessage;
        };

        const notesSearchFieldValidation = (item: any) => {
            let validationMessage = '';
            let validationRequired = false;

            if (itemRequiresValue(item)) {
                const isMissingDates =
                    !this.state.noteSearchCriteria.startDate &&
                    !this.state.noteSearchCriteria.endDate;

                const isMissingNoteCategories =
                    (this.state.noteSearchCriteria.noteCategories?.length ?? 0) === 0;

                const isMissingPurpose = !this.state.noteSearchCriteria.purpose?.some(
                    (x) => x.id && x.id > 0
                );

                const isMissingApprovalStatus = !this.state.noteSearchCriteria.approvalStatus?.some(
                    (x) => x.id && x.id > 0
                );

                const hasNoValuesSet =
                    isMissingDates &&
                    isMissingNoteCategories &&
                    isMissingPurpose &&
                    isMissingApprovalStatus &&
                    this.state.noteSearchCriteria.private === undefined &&
                    this.state.noteSearchCriteria.allowLocalEdit === undefined &&
                    this.state.noteSearchCriteria.modifiedByLocal === undefined &&
                    this.state.noteSearchCriteria.hasAttachment === undefined &&
                    this.state.noteSearchCriteria.isAssociatedCaseExpertCaseNote === undefined;

                validationRequired = !this.state.noteSearchCriteria || hasNoValuesSet;

                if (validationRequired) {
                    validationMessage = 'Enter at least one Case Note Criteria';
                    return validationMessage;
                }

                if (!validationRequired) {
                    if (
                        this.state.noteSearchCriteria!.startDate! &&
                        this.state.noteSearchCriteria!.endDate!
                    ) {
                        if (
                            this.state.noteSearchCriteria!.startDate >
                            this.state.noteSearchCriteria!.endDate
                        ) {
                            validationRequired = true;
                        }
                    }
                    if (validationRequired) {
                        validationMessage = 'From Date must be before To Date';
                    }
                }
            }
            return validationMessage;
        };

        const validateDateRange = (
            item: any,
            startDateKey: string,
            endDateKey: string,
            errorMessage: string
        ) => {
            if (itemRequiresValue(item)) {
                if (!item[startDateKey] && !item[endDateKey]) {
                    item.validationError = errorMessage || 'Enter at least one date';
                } else if (item[startDateKey] && !Common.isValidDate(item[startDateKey])) {
                    item.validationError = 'Invalid Date';
                } else if (item[endDateKey] && !Common.isValidDate(item[endDateKey])) {
                    item.validationError = 'Invalid Date';
                } else if (
                    item[startDateKey] &&
                    item[endDateKey] &&
                    item.startDate > item.endDate
                ) {
                    item.validationError = 'From Date must be before To Date';
                } else {
                    item.validationError = undefined;
                }
                validationErrors = !!item.validationError; // Set validationErrors flag
            }
        };

        const validateAssertionDescriptorDates = (item: any) => {
            validateDateRange(item, 'startDate', 'endDate', 'Enter at least one date');
        };

        const validateStaticItemDate = (item: any) => {
            const startDateRequiredMsg = 'Start date is required';
            validateDateRange(item, 'startDate', 'endDate', startDateRequiredMsg);
            // If Start Date is not entered, irrespective of endDate is entered or not (as per the existing logic) reset the validationError for start date.
            if (!item.startDate) {
                item.validationError = startDateRequiredMsg;
                validationErrors = true;
            }
        };

        // Loop through all selected options and check for any validation errors
        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            const item = queryFilterCriteriaSelected[i];
            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.props.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (assertionDescriptorMatch) {
                    switch (assertionDescriptorMatch.valueSourceType!.id) {
                        case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                            verifyBooleanValidation(item);
                            break;
                        case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                            if (
                                itemRequiresValue(item) &&
                                (!item.selectedValues || item.selectedValues.length == 0)
                            ) {
                                item.validationError = 'Select at least one value';
                                validationErrors = true;
                            } else {
                                item.validationError = undefined;
                                validationErrors = false;
                            }
                            break;
                        case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                            const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                                ? assertionDescriptorMatch.valueDisplayType.id
                                : 0;
                            switch (valueDisplayTypeId) {
                                case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                    validateAssertionDescriptorDates(item);
                                    break;
                                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                                case AssertionDescriptorValueDisplayTypes.Number.Value:
                                case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                    if (
                                        itemRequiresValue(item) &&
                                        !item.numericValueFrom &&
                                        !item.numericValueTo
                                    ) {
                                        item.validationError = 'Enter at least one value';
                                        validationErrors = true;
                                    } else {
                                        item.validationError = undefined;
                                        validationErrors = false;
                                    }
                                    break;
                                default:
                                    if (
                                        itemRequiresValue(item) &&
                                        (item.text == null || item.text.trim().length == 0)
                                    ) {
                                        item.validationError = 'Enter at least 3 characters';
                                        validationErrors = true;
                                    } else {
                                        item.validationError = undefined;
                                        validationErrors = false;
                                    }
                                    break;
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }
            } else {
                switch (item.staticItemId) {
                    case QueryStaticItemFields.CaseNumber.Id:
                    case QueryStaticItemFields.UniqueCaseID.Id:
                        if (
                            itemRequiresValue(item) &&
                            (item.text == null || item.text.trim().length == 0)
                        ) {
                            item.validationError = 'Enter at least 3 characters';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.CaseStatus.Id:
                    case QueryStaticItemFields.CaseManager.Id: //CHECK739
                    case QueryStaticItemFields.LocalCounsel.Id:
                    case QueryStaticItemFields.OtherDefenseCounsels.Id:
                    case QueryStaticItemFields.State.Id:
                        if (
                            itemRequiresValue(item) &&
                            (!item.selectedValues || item.selectedValues.length == 0)
                        ) {
                            item.validationError = 'Select at least one value';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.CasePriority.Id:
                        verifyBooleanValidation(item);
                        break;
                    case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    case QueryStaticItemFields.ResolutionDate.Id:
                        validateStaticItemDate(item);
                        break;
                    case QueryStaticItemFields.NotesSection.Id: {
                        const noteValidationMessage: string = notesSearchFieldValidation(item);
                        if (noteValidationMessage.length > 0) {
                            item.validationError = noteValidationMessage;
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    }
                    case QueryStaticItemFields.DepositionSection.Id: {
                        const validationMessage: string = depositionSearchFieldValidation(item);
                        if (validationMessage.length > 0) {
                            item.validationError = validationMessage;
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    }
                    case QueryStaticItemFields.ExpertsSection.Id:
                        if (
                            itemRequiresValue(item) &&
                            (!this.state.caseExpertsSearchCriteria ||
                                ((!this.state.caseExpertsSearchCriteria.expertGuids ||
                                    this.state.caseExpertsSearchCriteria.expertGuids.length == 0) &&
                                    (!this.state.caseExpertsSearchCriteria.reportStatusTypeIds ||
                                        this.state.caseExpertsSearchCriteria.reportStatusTypeIds
                                            .length == 0) &&
                                    (!this.state.caseExpertsSearchCriteria.retainedByTypeIds ||
                                        this.state.caseExpertsSearchCriteria.retainedByTypeIds
                                            .length == 0)))
                        ) {
                            item.validationError = 'Select at least one Case Experts Criteria';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.PackageSettlement.Id:
                        //TODO: validation has to be done for all the optionCSS.
                        // if ((item) &&(item.text == null || item.text.trim().length == 0)) {
                        //     item.validationError = 'Enter at least 3 characters';
                        //     validationErrors = true;
                        // }
                        // else {
                        //     item.validationError = undefined;
                        //     validationErrors = false;
                        // }
                        break;
                }
            }
        }

        const validation: IValidation = {};

        //CHECK1317

        // At least one Criteria is required
        if (
            itemRequiresValue(queryFilterCriteriaSelected) &&
            queryFilterCriteriaSelected.length === 0
        ) {
            validation.criteria = ['Enter at least one criteria'];
            validationErrors = true;
        }

        // Max Criteria limit
        if (queryFilterCriteriaSelected.length > 10) {
            validation.criteria = ['You can only include 10 Criteria at a time'];
            validationErrors = true;
        }

        // Max columns selected
        if (
            !isExport &&
            this.state.queryType === QueryTypes.CaseDetails.Value &&
            this.state.displayColumnsSelected.length > 10
        ) {
            validation.columns = [
                'You can only select a maximum of 10 Columns for display on the screen. You can export to include any number of columns.',
            ];
            validationErrors = true;
        }

        // At least one criteria should be marked as required.
        if (
            queryFilterCriteriaSelected.length > 0 &&
            !queryFilterCriteriaSelected.find((x) => x.required === true)
        ) {
            validation.criteria = ["At least one Criteria should be marked as 'Required'"];
            validationErrors = true;
        }

        if (
            this.state.queryFilterCriteriaSelected.find(
                (x) => x.validationError != null && x.validationError.length > 0
            )
        ) {
            this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
            return false;
        }

        if (validationErrors) {
            this.setState({
                validation: validation,
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            });
            return false;
        }

        return true;
    };

    processInputQueryFilters(): IQueryFilterCriteria[] {
        // Special Logic to separate Status & Substatus
        const queryFilterCriteriaSelected = cloneDeep(this.state.queryFilterCriteriaSelected);

        const filters: IQueryFilterCriteria[] = cloneDeep(queryFilterCriteriaSelected);

        const caseStatusFilter = filters.find(
            (x) => x.staticItemId === QueryStaticItemFields.CaseStatus.Id
        );

        if (
            caseStatusFilter &&
            caseStatusFilter.selectedValues &&
            caseStatusFilter.selectedValues.length
        ) {
            const statusIds: number[] = [];
            const subStatusIds: number[] = [];

            for (let i = 0; i < caseStatusFilter.selectedValues!.length; i++) {
                const item = caseStatusFilter.selectedValues[i];
                if (item.id) {
                    const match = this.props.caseStatuses.find((x) => x.id == item.id);
                    if (match) {
                        if (match.status) statusIds.push(match.status!.id);
                        if (match.subStatus) subStatusIds.push(match.subStatus.id);
                    }
                }
            }

            if (statusIds.length > 0) {
                const selectedValues = statusIds.map((item) => {
                    const container: IQueryFilterCriteriaSelectionValue = {
                        label: '',
                        value: '',
                        id: item,
                    };
                    return container;
                });

                filters.push({
                    label: '',
                    value: '',
                    required: caseStatusFilter.required,
                    selectedValues: selectedValues,
                    staticItemId: InternalDatabaseFields_CaseStatus_Status,
                    condition: caseStatusFilter.condition,
                });
            }

            if (subStatusIds.length > 0) {
                const selectedValues = subStatusIds.map((item) => {
                    const container: IQueryFilterCriteriaSelectionValue = {
                        label: '',
                        value: '',
                        id: item,
                    };
                    return container;
                });

                filters.push({
                    label: '',
                    value: '',
                    required: caseStatusFilter.required,
                    selectedValues: selectedValues,
                    staticItemId: InternalDatabaseFields_CaseStatus_SubStatus,
                    condition: caseStatusFilter.condition,
                });
            }

            // Remove CaseStatus filter as its only a UI thing
            for (let i = 0; i < filters.length; i++) {
                if (filters[i].staticItemId === QueryStaticItemFields.CaseStatus.Id) {
                    filters.splice(i, 1);
                    break;
                }
            }
        }

        // Additional default logic for Boolean Types. The dropdown will only have Yes & No (No empty item) & defaulting to No. Set the boolean value to false if no value is explicitly selected since the server requires a valid value for boolean filter of true or false
        for (let i = 0; i < filters.length; i++) {
            const item = filters[i];
            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.props.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (
                    assertionDescriptorMatch &&
                    assertionDescriptorMatch.valueSourceType &&
                    assertionDescriptorMatch.valueSourceType.id ==
                        AssertionDescriptorValueSourceTypeEnum.Boolean.Value
                ) {
                    if (!item.booleanValue) item.booleanValue = false;
                }
            } else if (
                item.staticItemId === QueryStaticItemFields.CasePriority.Id &&
                !item.booleanValue
            )
                item.booleanValue = false;
        }

        // Special Time Zone related logic for Case Notes Date Range. All dates in Checkmate so far EXCEPT Case Notes Date are input fields, hence we do an actual match w/o timezone. However, Case Notes Date is stored as UTC in DB. Hence we need to convert to UTC before passing to server.
        const caseNotesFilterMatch = filters.find(
            (x) => x.staticItemId == QueryStaticItemFields.NotesSection.Id
        );
        if (caseNotesFilterMatch) {
            if (caseNotesFilterMatch.startDate)
                caseNotesFilterMatch.startDate = Common.localStartOfDayInUTC(
                    caseNotesFilterMatch.startDate
                );
            if (caseNotesFilterMatch.endDate)
                caseNotesFilterMatch.endDate = Common.localEndOfDayInUTC(
                    caseNotesFilterMatch.endDate
                );
        }

        return filters;
    }

    clearQuery = () => {
        window.location.reload();
    };

    sortData = (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataItem?: number
    ) => {
        switch (dataItem) {
            case QueryTypes.CaseDetails.Value:
                this.sortCaseData(parseInt(key), order, subKey);
                break;
            case QueryTypes.Deposition.Value:
                this.sortDepositionData(key, order, subKey, subGrandKey);
                break;
        }
    };

    sortCaseData = (index: number, order: string, datatype?: string) => {
        const caseResults = cloneDeep(this.state.caseResults);
        caseResults.values?.sort(Sort.compareValuesByIndex(index, datatype, order));
        this.setState({ caseResults });
    };

    sortDepositionData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        const depositions = cloneDeep(this.state.depositionResults);
        if (key === 'date') {
            depositions?.sort(Sort.compareDate(key, subKey, order));
        } else {
            depositions?.sort(Sort.compareValues(key, subKey, order, subGrandKey));
        }
        this.setState({ depositionResults: depositions });
    };

    handleEasyUpdate = (caseGuid: string, noteGuid?: string) => {
        const easyUpdateAttemptedCaseList = [...this.state.easyUpdateAttemptedCaseList];
        const easyUpdateAttemptedNoteGuidList = [...this.state.easyUpdateAttemptedNoteGuidList];

        easyUpdateAttemptedCaseList.push(caseGuid);

        if (noteGuid) {
            easyUpdateAttemptedNoteGuidList.push(noteGuid);
        }

        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList,
            easyUpdateAttemptedNoteGuidList,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.some((x) => x === caseGuid);
    };

    easyUpdateAttemptedOnNote = (noteGuid: string) => {
        return this.state.easyUpdateAttemptedNoteGuidList.some((x) => x === noteGuid);
    };

    getEasyUpdateIconColorStyle = (caseGuid: string, noteGuid?: string) => {
        let cssClass = '';

        if (this.state.queryType === QueryTypes.CaseNotes.Value) {
            if (noteGuid) {
                cssClass = this.easyUpdateAttemptedOnNote(noteGuid) ? 'color-gray' : 'color-black';
            } else {
                cssClass = this.easyUpdateAttempted(caseGuid) ? 'color-gray' : 'color-black';
            }
        } else {
            cssClass = this.easyUpdateAttempted(caseGuid) ? 'color-gray' : 'color-black';
        }

        return cssClass;
    };

    easyUpdateDone = async (updatedCase: ICaseModel) => {
        const noteResults = cloneDeep(this.state.noteResults);
        if (noteResults) {
            const matches = noteResults.filter((x) => x.caseGuid === updatedCase.guid);
            matches.map((item: INoteModel) => {
                item.caseName = updatedCase.caseName;
            });
        }

        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
            noteResults,
        });

        if (this.state.queryType === QueryTypes.CaseDetails.Value) await this.runQuery();
    };

    easyUpdateCancel = () => {
        this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined });
    };

    showConfirmRemoveDailog = (note: INoteModel) => {
        this.setState({
            showConfirmRemoveCaseNoteDialog: true,
            currentNote: JSON.parse(JSON.stringify(note)),
        });
    };

    viewEditNote = async (currentNote: INoteModel) => {
        const selectedNoteCategories: IMultiSelectOptions[] = [];
        if (currentNote.categories != undefined) {
            for (let i = 0; i < currentNote.categories.length; i++) {
                selectedNoteCategories.push({
                    label: currentNote.categories[i].name!,
                    value: currentNote.categories[i].name!,
                    id: currentNote.categories[i].id,
                });
            }
        }

        let strategyTypeId = 0;
        if (
            this.state.queryType === QueryTypes.StrategyNote.Value &&
            currentNote.associations &&
            currentNote.associations.length > 0
        ) {
            switch (currentNote.associations[0].entityType.name) {
                case 'Local Counsel':
                    strategyTypeId = StrategyTypes.LocalCounsel.Value;
                    break;
                case 'Jurisdiction':
                    strategyTypeId = StrategyTypes.Jurisdiction.Value;
                    break;
                case 'Plaintiffs Firm':
                    strategyTypeId = StrategyTypes.PlaintiffsFirm.Value;
                    break;
                case 'Projects':
                    strategyTypeId = StrategyTypes.Projects.Value;
                    break;
                default:
            }
        }

        this.setState({
            openNoteEditor: true,
            strategyTypeId: strategyTypeId || undefined,
            currentNote: cloneDeep(currentNote),
            selectedNoteCategoriesOnEdit: selectedNoteCategories,
        });
    };

    handleCancelDeleteNote = () => {
        this.setState({
            showConfirmRemoveCaseNoteDialog: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        const caseNotesResults = cloneDeep(this.state.noteResults);

        if (caseNotesResults) {
            let existingNote = false;
            for (let i = 0; i < caseNotesResults.length; i++) {
                if (caseNotesResults[i].guid === noteItem.guid) {
                    caseNotesResults[i] = noteItem;
                    existingNote = true;
                    break;
                }
            }

            if (!existingNote) {
                if (
                    caseNotesResults.filter(
                        (x) => x.caseGuid == noteItem.caseGuid && x.guid && x.guid != EmptyGuid
                    ).length > 0
                ) {
                    caseNotesResults.push(noteItem);
                    caseNotesResults.sort(Sort.compareValues('caseName', undefined, 'asc'));
                } else {
                    for (let i = 0; i < caseNotesResults.length; i++) {
                        if (caseNotesResults[i].caseGuid === this.state.currentNote.caseGuid!) {
                            caseNotesResults[i] = noteItem;
                            break;
                        }
                    }
                }
            }
        }

        if (caseNotesResults) {
            caseNotesResults.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        }

        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            noteResults: caseNotesResults,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    deleteNote = async () => {
        if (
            !this.state.currentNote ||
            !this.state.currentNote.guid ||
            !this.state.currentNote.caseGuid
        ) {
            const validation: IValidation = {};
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        const noteGuidToDelete = this.state.currentNote.guid!;

        const response = await _apiClient.deleteNote(
            noteGuidToDelete,
            this.state.currentNote.caseGuid
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                showConfirmRemoveCaseNoteDialog: false,
            });
            return;
        }

        const caseNotesResults = this.state.noteResults!;

        // Determine if we should delete the note row or keep it.
        // 1) Delete row for Zone and Strategy Note Search
        // 2) For Case Notes, if there is a Case Note Criteria, delete the row. If not, check if there is another row with the same Case Guid. If another row exists for the same Case, delete this row.

        const caseNotesResultMatch = caseNotesResults.find((x) => x.guid === noteGuidToDelete);

        if (caseNotesResultMatch) {
            let deleteNoteRow = true;
            if (
                this.state.queryType === QueryTypes.CaseNotes.Value &&
                this.state.queryFilterCriteriaSelected.filter(
                    (x) => x.staticItemId === QueryStaticItemFields.NotesSection.Id
                ).length === 0 &&
                caseNotesResults.filter((x) => x.caseGuid === caseNotesResultMatch!.caseGuid)
                    .length === 1
            )
                deleteNoteRow = false;

            for (let i = 0; i < caseNotesResults.length; i++) {
                if (caseNotesResults[i].guid == noteGuidToDelete) {
                    if (deleteNoteRow) {
                        caseNotesResults.splice(i, 1);
                    } else {
                        caseNotesResults[i] = {
                            caseGuid: caseNotesResults[i].caseGuid,
                            caseName: caseNotesResults[i].caseName,
                            status: { id: NoteStatusTypes.Open },
                            purpose: { id: NotePurposeTypesEnum.Info },
                        };
                    }
                    break;
                }
            }
        }

        this.setState({
            noteResults: caseNotesResults,
            showConfirmRemoveCaseNoteDialog: false,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    onCancelAddEditNote = () => {
        const validation = { ...this.state.validation, categories: [], content: [] };

        this.setState({
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            validation: validation,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    deleteField(item: IQueryFilterCriteria) {
        const queryFilterCriteriaSelected = cloneDeep(this.state.queryFilterCriteriaSelected);
        let depositionSearchCriteria = cloneDeep(this.state.depositionSearchCriteria);
        const noteSearchCriteria = cloneDeep(this.state.noteSearchCriteria);

        if (queryFilterCriteriaSelected) {
            if (item.isAssertionDescriptor) {
                for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                    if (
                        queryFilterCriteriaSelected[i].isAssertionDescriptor &&
                        queryFilterCriteriaSelected[i].assertionDescriptorGuid ==
                            item.assertionDescriptorGuid
                    ) {
                        queryFilterCriteriaSelected[i].selectedValues = undefined;
                        queryFilterCriteriaSelected[i].text = '';
                        queryFilterCriteriaSelected[i].booleanValue = false;
                        queryFilterCriteriaSelected[i].startDate = undefined;
                        queryFilterCriteriaSelected[i].endDate = undefined;
                        queryFilterCriteriaSelected[i].numericValueFromText = '';
                        queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                        queryFilterCriteriaSelected[i].numericValueToText = '';
                        queryFilterCriteriaSelected[i].numericValueTo = undefined;
                        queryFilterCriteriaSelected[i].condition = undefined;
                        queryFilterCriteriaSelected[i].validationError = undefined;
                        queryFilterCriteriaSelected.splice(i, 1);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                    if (queryFilterCriteriaSelected[i].label === item.label) {
                        queryFilterCriteriaSelected[i].selectedValues = undefined;
                        queryFilterCriteriaSelected[i].text = '';
                        queryFilterCriteriaSelected[i].booleanValue = false;
                        queryFilterCriteriaSelected[i].startDate = undefined;
                        queryFilterCriteriaSelected[i].endDate = undefined;
                        queryFilterCriteriaSelected[i].numericValueFromText = '';
                        queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                        queryFilterCriteriaSelected[i].numericValueToText = '';
                        queryFilterCriteriaSelected[i].numericValueTo = undefined;
                        queryFilterCriteriaSelected[i].condition = undefined;
                        queryFilterCriteriaSelected[i].validationError = undefined;
                        queryFilterCriteriaSelected.splice(i, 1);
                        break;
                    }
                }

                if (item.staticItemId === QueryStaticItemFields.DepositionSection.Id)
                    depositionSearchCriteria = undefined;
            }

            if (item.value === 'CaseNotes' || item.value === 'NotesSection') {
                this.removeNoteSearchFromSearchCriteria(noteSearchCriteria);
            }
            if (item.value == 'DepositionSection' && depositionSearchCriteria !== undefined) {
                depositionSearchCriteria.categories = undefined;
                depositionSearchCriteria.defenseCounsel = undefined;
                depositionSearchCriteria.startDate = undefined;
                depositionSearchCriteria.endDate = undefined;
                depositionSearchCriteria.statuses = undefined;
                depositionSearchCriteria.defenseCounselUsers = undefined;
            }
            this.setState({
                queryFilterCriteriaSelected,
                depositionSearchCriteria,
                noteSearchCriteria,
            });
        }
    }

    removeNoteSearchFromSearchCriteria = (noteSearchCriteria: INoteUISearchModel) => {
        noteSearchCriteria.acknowledgementStatus = undefined;
        noteSearchCriteria.alertListSearchStatusOptions = undefined;
        noteSearchCriteria.alertListType = undefined;
        noteSearchCriteria.alertTypeIds = undefined;
        noteSearchCriteria.alertUserGuids = undefined;
        noteSearchCriteria.allowLocalEdit = undefined;
        noteSearchCriteria.approvalStatus = undefined;
        noteSearchCriteria.blankApproval = undefined;
        noteSearchCriteria.endDate = undefined;
        noteSearchCriteria.hasAttachment = undefined;
        noteSearchCriteria.modifiedByLocal = undefined;
        noteSearchCriteria.noteCategories = undefined;
        noteSearchCriteria.noteStatusIds = undefined;
        noteSearchCriteria.noteSubCategories = undefined;
        noteSearchCriteria.noteSubTypes = undefined;
        noteSearchCriteria.private = undefined;
        noteSearchCriteria.purpose = undefined;
        noteSearchCriteria.readOnly = undefined;
        noteSearchCriteria.startDate = undefined;
    };

    getTotalRecordsByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value: {
                return this.state.caseResultsCount;
            }
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                return this.state.noteResults ? this.state.noteResults.length : 0;
            case QueryTypes.Deposition.Value:
                return this.state.depositionResults ? this.state.depositionResults.length : 0;
            default:
                return 0;
        }
    };

    handleCaseExpertsListRefresh = (caseExperts: ICaseExpertModel[]) => {
        this.setState({ caseExpertsResults: caseExperts });
    };

    getQueryResultsUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
                return (
                    <CaseDetailsResults
                        caseResults={this.state.caseResults}
                        enableEasyUpdate={this.state.enableEasyUpdate}
                        getEasyUpdateIconColorStyle={this.getEasyUpdateIconColorStyle}
                        mode={this.state.mode}
                        onCheckedChange={this.handleCheckedChange}
                        onEasyUpdate={this.handleEasyUpdate}
                        onSortData={this.sortData}
                        selectAllCasesForRedirect={this.state.selectAllCasesForRedirect}
                        selectedGuidsForRedirect={this.state.selectedGuidsForRedirect}
                        showSelectAllCheckbox={this.state.showSelectAllCheckbox ?? false}
                    />
                );
            case QueryTypes.CaseExperts.Value: {
                return (
                    <CaseExpertsList
                        user={this.props.user}
                        displayTableWhenEmpty
                        caseExperts={this.state.caseExpertsResults ?? []}
                        enableEasyUpdateColumn={this.state.enableEasyUpdate}
                        getEasyUpdateIconColorStyle={this.getEasyUpdateIconColorStyle}
                        hideDelete
                        onEasyUpdateClick={this.handleEasyUpdate}
                        onSetCurrentCaseExpert={(caseExpert) =>
                            this.setState({ currentCaseExpert: caseExpert ?? undefined })
                        }
                        refreshParent={this.handleCaseExpertsListRefresh}
                        showCaseName
                    />
                );
            }
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                return (
                    <NoteListTable
                        getEasyUpdateIconColorStyle={this.getEasyUpdateIconColorStyle}
                        notes={this.state.noteResults ?? []}
                        onRowAddClick={this.handleRowAddClick}
                        onEasyUpdateClick={this.handleEasyUpdate}
                        onShowConfirmRemoveDialog={this.showConfirmRemoveDailog}
                        onViewEditNote={this.viewEditNote}
                        onViewNotesCategoryAdditionalInfo={() => {
                            this.setState({
                                displayNoteCategoryAdditionalInfo: true,
                            });
                        }}
                        showEasyUpdateColumn={
                            this.state.queryType === QueryTypes.CaseNotes.Value &&
                            this.state.enableEasyUpdate
                        }
                        showDeleteButton={false}
                        showEditButton={
                            this.state.loggedInUserDataScopeId !== DataScopesEnum.LocalBasic.Value
                        }
                        showCaseColumn={
                            this.state.queryType !== QueryTypes.ZoneNotes.Value &&
                            this.state.queryType !== QueryTypes.StrategyNote.Value
                        }
                        showExpertsColumn={this.state.queryType === QueryTypes.CaseNotes.Value}
                        showTypeColumn={this.state.queryType === QueryTypes.StrategyNote.Value}
                    />
                );
            case QueryTypes.Deposition.Value: {
                return (
                    <CaseDepositionListWrapper
                        user={this.props.user}
                        usersInZone={this.props.zoneUsers ?? []}
                        allDefenseCounselsForZone={this.props.defenseCounsels ?? []}
                        depositions={this.state.depositionResults ?? []}
                        showCaseColumn
                        showEasyUpdateColumn={
                            this.state.queryType === QueryTypes.Deposition.Value &&
                            this.state.enableEasyUpdate
                        }
                        forceHideDelete
                    />
                );
            }
            default:
        }
    };

    getAllDefenseCounselMultiSelectValuesForZone = () => {
        return cloneDeep(this.state.zoneDefenseCounselOptions);
    };

    getNoteCategoryTypesByQueryType = () => {
        let noteTypeId = 0;
        switch (this.state.queryType) {
            case QueryTypes.CaseNotes.Value:
                noteTypeId = NoteTypes.CaseNote;
                break;
            case QueryTypes.ZoneNotes.Value:
                noteTypeId = NoteTypes.ZoneNote;
                break;
            case QueryTypes.StrategyNote.Value:
                noteTypeId = NoteTypes.StrategyNote;
                break;
            default:
        }

        return this.props.noteCategoryTypes.filter((x) => x.noteType.id === noteTypeId);
    };

    isNoteReadOnly = () => {
        const noteType = this.state.currentNote.type ? this.state.currentNote.type.id : 0;

        if (noteType === NoteTypes.CaseNote) {
            return NoteHelper.isUserAuthorizedToEditNote(
                this.state.currentNote,
                this.props.user,
                Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user),
                Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user),
                Authorization.userHasRight(UserRightsEnum.AddCaseNote, this.props.user)
            );
        } else if (noteType === NoteTypes.StrategyNote) {
            switch (this.state.currentNote.originStrategyType) {
                case StrategyTypes.Jurisdiction.Value:
                    return NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewJurisdictionStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdateJurisdictionStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddJurisdictionStrategyNote,
                            this.props.user
                        )
                    );
                case StrategyTypes.PlaintiffsFirm.Value:
                    return NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewPlaintiffsFirmStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdatePlaintiffsFirmStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddPlaintiffsFirmStrategyNote,
                            this.props.user
                        )
                    );
                case StrategyTypes.LocalCounsel.Value:
                    return NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewLocalCounselStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdateLocalCounselStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddLocalCounselStrategyNote,
                            this.props.user
                        )
                    );
                case StrategyTypes.Projects.Value:
                    return NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewProjectsStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdateProjectsStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddProjectsStrategyNote,
                            this.props.user
                        )
                    );
                case StrategyTypes.Experts.Value:
                    return NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewExpertStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdateExpertStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddExpertStrategyNote,
                            this.props.user
                        )
                    );
            }
        } else if (noteType === NoteTypes.ZoneNote) {
            return NoteHelper.isUserAuthorizedToEditNote(
                this.state.currentNote,
                this.props.user,
                Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, this.props.user),
                Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user),
                Authorization.userHasRight(UserRightsEnum.AddZoneNote, this.props.user)
            );
        }

        return true;
    };

    //Old favorites will not have condition property -> may or may not have blank property --> to handle these scenarios.

    setFilterItemConditionForOldFavorites = (item: any) => {
        let isConditionPropertyExist = true;
        //verify if condition property is exist in the saved setting - if condition is there that is new setting and do not need to verify blank
        if (!this.hasProp(item, 'condition') || item.condition === undefined) {
            isConditionPropertyExist = false;
        }
        if (!isConditionPropertyExist) {
            //verify if existing saved item has blank property -> initially it was not there.. later blank was implemented and the condition replaced the blank property.
            if (this.hasProp(item, 'blank') && item.blank !== undefined) {
                item.condition = item.blank; //set true or false.
            } else {
                item.condition = ConditionSelectorEnums.EqualTo;
                // no condition property & blank property exists and is undefined - it means equal to.
            }
        }
    };

    applyFavoriteSelected = (settingJson?: string) => {
        if (settingJson) {
            const settings: IQuerySettingsModel = JSON.parse(settingJson);
            const columns: IQueryFilterCriteria[] = [];
            let isDepoSecInCriteria = false;
            let isNoteSecInCriteria = false;
            let isExpertSecInCriteria = false;

            if (settings.filters) {
                const adsToRemove: string[] = [];
                for (let i = 0; i < settings.filters.length; i++) {
                    const item = settings.filters[i];

                    this.setFilterItemConditionForOldFavorites(item);

                    // Get the Latest Display Name since the Favorite might have been added with an old value
                    if (item.assertionDescriptorGuid) {
                        const match = this.props.assertionDescriptors.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                item.assertionDescriptorGuid!.toUpperCase()
                        );
                        if (match)
                            item.label =
                                match.alternateDisplayName || match.displayName || match.name!;
                        else adsToRemove.push(item.assertionDescriptorGuid);
                    } else {
                        const match = this.state.queryFilterCriteriaAll.find(
                            (x) => x.value === item.value
                        );
                        if (match) item.label = match.label;
                    }
                }
                for (let index = 0; index < adsToRemove.length; index++) {
                    const element = adsToRemove[index];
                    settings.filters = settings.filters.filter(
                        (x) => x.assertionDescriptorGuid != element
                    );
                }
            }

            //find depo section / notes section / Experts is included in the criteria.
            if (settings.filters) {
                isDepoSecInCriteria = settings.filters.some(
                    (filterCriteria) =>
                        filterCriteria.staticItemId === QueryStaticItemFields.DepositionSection.Id
                );
                isNoteSecInCriteria = settings.filters.some(
                    (filterCriteria) =>
                        filterCriteria.staticItemId === QueryStaticItemFields.NotesSection.Id
                );
                isExpertSecInCriteria = settings.filters.some(
                    (filterCriteria) =>
                        filterCriteria.staticItemId === QueryStaticItemFields.ExpertsSection.Id
                );
            }
            //verify if Query type is Strategy or Zone note. then -- It should have the notes search Criteria.
            if (!isNoteSecInCriteria) {
                isNoteSecInCriteria =
                    settings.queryType.id === QueryTypes.StrategyNote.Value ||
                    settings.queryType.id === QueryTypes.ZoneNotes.Value;
            }
            if (settings.columns) {
                for (let i = 0; i < settings.columns.length; i++) {
                    const item = settings.columns[i];
                    // Get the Latest Display Name since the Favorite might have been added with an old value
                    if (item.assertionDescriptorGuid) {
                        const match = this.props.assertionDescriptors.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                item.assertionDescriptorGuid!.toUpperCase()
                        );
                        if (match) item.displayName = match.displayName;
                    } else {
                        const match = this.state.displayColumnsAll.find(
                            (x) => x.value === item.name!
                        );
                        if (match) item.displayName = match.label;
                    }

                    columns.push({
                        staticItemId: item.staticItemId!,
                        label: item.displayName!,
                        value: item.name!,
                        assertionDescriptorGuid: item.assertionDescriptorGuid,
                        isAssertionDescriptor: item.assertionDescriptorGuid ? true : false,
                    });
                }
            }
            //handle this based on query type - if it is a stragety query type.. need to verify.
            const defaultnoteSearchCriteria: INoteUISearchModel = {};

            this.setState({
                queryType: settings.queryType.id,
                queryFilterCriteriaSelected: settings.filters,
                displayColumnsSelected: columns,
                displayColumnsSelectedForExport: columns,
                depositionSearchCriteria: isDepoSecInCriteria
                    ? settings.depositionSearchCriteria
                    : {},
                noteSearchCriteria: isNoteSecInCriteria
                    ? settings.noteSearchCriteria!
                    : defaultnoteSearchCriteria,
                caseExpertsSearchCriteria: isExpertSecInCriteria
                    ? settings.caseExpertSearchCriteria
                    : {},
                validation: {},
                caseResults: {},
            });
        }
    };

    getCurrentFavoriteSettingJson = () => {
        let settingsJson = '';
        if (this.state.queryFilterCriteriaSelected.length > 0) {
            const displayColumnsForFavorite = this.state.displayColumnsSelected.map(
                (item: IQueryFilterCriteria, index: number) => ({
                    index: index,
                    name: item.value,
                    displayName: item.label,
                    assertionDescriptorGuid: item.assertionDescriptorGuid,
                    staticItemId: item.staticItemId,
                })
            );

            const settings: IQuerySettingsModel = {
                queryType: { id: this.state.queryType },
                filters: this.state.queryFilterCriteriaSelected,
                columns: displayColumnsForFavorite,
                depositionSearchCriteria: this.state.depositionSearchCriteria,
                noteSearchCriteria: this.state.noteSearchCriteria,
                caseExpertSearchCriteria: this.state.caseExpertsSearchCriteria,
            };

            settingsJson = JSON.stringify(settings);
        }

        return settingsJson;
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    hasProp = (obj: any, prop: string): obj is { [key: string]: any } => {
        return obj[prop] !== undefined;
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';
        if (settingJson) {
            const settings: IQuerySettingsModel = JSON.parse(settingJson);

            selectedFilters +=
                'QUERY TYPE: ' +
                getAuthorizedQueryTypes(this.props.user).find(
                    (x) => x.id === settings.queryType.id
                )!.displayName +
                '\n\n';

            if (settings.filters && settings.filters.length > 0) {
                selectedFilters += 'CRITERIA:' + '\n';

                settings.filters.forEach((filter) => {
                    const match = this.state.queryCriteriaMasterList.find(
                        (x) => x.value === filter.value
                    );
                    const displayName = match ? match.label : filter.label || filter.value;

                    selectedFilters += displayName + '\n';
                });

                selectedFilters += '\n';
            }

            if (settings.columns && settings.columns.length > 0) {
                selectedFilters += 'DISPLAY:' + '\n';

                settings.columns.forEach((column) => {
                    const name = column.name || '';
                    const match = this.state.displayColumnsAll.find((x) => x.value === name);
                    const displayName = match ? match.label : column.displayName || name;

                    selectedFilters += displayName + '\n';
                });
            }
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    exportFilterForDisplayColumns = (): IQueryFilterCriteria[] =>
        this.state.displayColumnsAll.filter(
            (item) =>
                item.staticItemId !== QueryStaticItemFields.DepositionSection.Id &&
                item.staticItemId !== QueryStaticItemFields.NotesSection.Id &&
                item.staticItemId !== QueryStaticItemFields.ExpertsSection.Id
        );

    handleCaseExpertEditorSave = (updatedCaseExpert: ICaseExpertViewDetailModel) => {
        const caseExpertsResultsCopy = cloneDeep(this.state.caseExpertsResults);
        const found = caseExpertsResultsCopy.find((ce) => ce.guid === updatedCaseExpert.guid);

        if (found) {
            found.disciplines = updatedCaseExpert.expert.disciplines ?? [];
            found.leadDefendant = updatedCaseExpert.leadDefendant ?? '';
            found.coDefendants = updatedCaseExpert.coDefendants ?? '';
            found.expertReportStatusTypeId = updatedCaseExpert.expertReportStatusTypeId ?? 0;
            found.expertRetainedByTypeId = updatedCaseExpert.expertRetainedByTypeId ?? 0;
            found.purposeOfRetention = updatedCaseExpert.purposeOfRetention;
        }

        this.setState({ caseExpertsResults: caseExpertsResultsCopy });
    };

    handleRowAddClick = (caseGuid: string) => {
        const newNote: INoteModel = {
            type: { id: NoteTypes.CaseNote },
            status: { id: NoteStatusTypes.Open },
            caseGuid,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            strategyTypeId: undefined,
            currentNote: newNote,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    handleFilterConditionOptionChange(filtersCopy: IQueryFilterCriteria[], filterIdx: number) {
        const filterItemToModify = filtersCopy[filterIdx];

        const filterConditionBlankOrNotBlank =
            filterItemToModify.condition === true || filterItemToModify.condition === false;

        if (filterItemToModify.staticItemId === QueryStaticItemFields.DepositionSection.Id) {
            this.setState({
                queryFilterCriteriaSelected: filtersCopy,
                depositionSearchCriteria: filterConditionBlankOrNotBlank
                    ? { readOnly: true }
                    : { ...this.state.depositionSearchCriteria, readOnly: false },
            });
        } else if (filterItemToModify.staticItemId === QueryStaticItemFields.NotesSection.Id) {
            this.setState({
                queryFilterCriteriaSelected: filtersCopy,
                noteSearchCriteria: filterConditionBlankOrNotBlank
                    ? { readOnly: true }
                    : { ...this.state.noteSearchCriteria, readOnly: false },
            });
        } else if (filterItemToModify.staticItemId === QueryStaticItemFields.ExpertsSection.Id) {
            this.setState({
                queryFilterCriteriaSelected: filtersCopy,
                caseExpertsSearchCriteria: filterConditionBlankOrNotBlank
                    ? {}
                    : this.state.caseExpertsSearchCriteria,
            });
        } else {
            this.setState({
                queryFilterCriteriaSelected: filtersCopy,
            });
        }
    }

    render() {
        const showFilterCriteria = [
            QueryTypes.CaseDetails.Value,
            QueryTypes.CaseExperts.Value,
            QueryTypes.CaseNotes.Value,
            QueryTypes.Deposition.Value,
        ].includes(this.state.queryType);

        const recordCount = this.getTotalRecordsByQueryType();

        if (this.state.openNoteEditor) {
            return (
                <NoteEditor
                    authorizedToEdit={this.isNoteReadOnly()}
                    user={this.props.user}
                    caseTitle={NoteHelper.getCaseTitle(this.state.currentNote)}
                    noteCategoryTypes={this.getNoteCategoryTypesByQueryType()}
                    currentNote={this.state.currentNote}
                    caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.onCancelAddEditNote}
                    helpText={[]}
                    strategyTypeId={this.state.strategyTypeId}
                />
            );
        }

        if (this.state.currentCaseExpert) {
            return (
                <CaseExpertEditor
                    caseExpertGuid={this.state.currentCaseExpert.guid}
                    caseGuid={this.state.currentCaseExpert.caseGuid}
                    defaultIsEditMode={this.state.currentCaseExpert.defaultIsEditMode ?? false}
                    onBackClick={() => this.setState({ currentCaseExpert: undefined })}
                    onSave={this.handleCaseExpertEditorSave}
                    user={this.props.user}
                />
            );
        }

        return (
            <>
                <div>
                    {Authorization.userHasRight(UserRightsEnum.EasyUpdate, this.props.user) && (
                        <CaseEasyUpdate
                            user={this.props.user}
                            caseGuid={this.state.easyUpdateCaseGuid}
                            onComplete={this.easyUpdateDone}
                            onCancel={this.easyUpdateCancel}
                            hidden={!this.state.easyUpdateMode}
                            excludeNotes={this.state.queryType === QueryTypes.CaseNotes.Value}
                            additionalHeaderText={
                                this.state.queryType === QueryTypes.CaseNotes.Value
                                    ? 'Update Case Data | Case Notes Query'
                                    : ''
                            }
                        />
                    )}

                    {this.state.pendingResponse ? (
                        <Loader />
                    ) : (
                        <div>
                            {!this.state.easyUpdateMode && (
                                <>
                                    <QueryHeader
                                        hideFavs={
                                            this.state.queryType == QueryTypes.ZoneNotes.Value ||
                                            this.state.queryType == QueryTypes.StrategyNote.Value
                                        }
                                        applyFavoriteSelected={this.applyFavoriteSelected}
                                        currentFavoriteSettingJson={this.getCurrentFavoriteSettingJson()}
                                        currentFavoriteDetailsString={
                                            this.state.currentFavoriteDetailsString
                                        }
                                        enableEasyUpdate={this.state.enableEasyUpdate}
                                        mode={this.state.mode}
                                        notesResults={this.state.noteResults ?? []}
                                        noteSearchCriteria={this.state.noteSearchCriteria}
                                        onCheckedChange={this.handleCheckedChange}
                                        onClearClick={this.clearQuery}
                                        onDefaultFavoriteChange={this.handleDefaultFavoriteChange}
                                        onFavoriteSelected={this.handleFavoriteSelected}
                                        onRunClick={this.runQuery}
                                        onShowExportModal={this.showExportModal}
                                        queryType={this.state.queryType}
                                        redirectComponentCurrentState={
                                            this.state.redirectComponentCurrentState
                                        }
                                        redirectUrl={this.state.redirectUrl}
                                        selectAllCasesForRedirect={
                                            this.state.selectAllCasesForRedirect
                                        }
                                        selectedGuidsForRedirect={
                                            this.state.selectedGuidsForRedirect
                                        }
                                        showExport={
                                            this.state.caseResultsCount <=
                                            maxRecordCountForWebProcessing
                                        }
                                        showExportNotes={(this.state.noteResults?.length ?? 0) > 0}
                                        title={
                                            (this.state.title ? this.state.title + ' ' : '') +
                                            'Legacy'
                                        }
                                        user={this.props.user}
                                    />

                                    {this.state.validation.model && (
                                        <div className="text-danger mb-3">
                                            {this.state.validation.model}
                                        </div>
                                    )}
                                    {(this.state.validation.lookups?.length ?? 0) > 0 && (
                                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                                            {this.state.validation.lookups.map((error) => (
                                                <div className="text-danger">{error}</div>
                                            ))}
                                        </div>
                                    )}
                                    <QueryTypeSelector
                                        mode={this.state.mode}
                                        onChange={this.handleQueryTypeChange}
                                        selectedQueryType={this.state.queryType}
                                        user={this.props.user}
                                        validation={this.state.validation}
                                    />
                                    {showFilterCriteria && (
                                        <FilterCriteria
                                            onQueryCriteriaSelected={this.queryCriteriaSelected}
                                            queryFilterCriteriaAll={
                                                this.state.queryFilterCriteriaAll
                                            }
                                            queryFilterCriteriaSelected={
                                                this.state.queryFilterCriteriaSelected
                                            }
                                            validation={this.state.validation}
                                            useQueryToolText={true}
                                        />
                                    )}
                                    <SelectedFilterCriteria
                                        acknowledgmentStatusTypes={
                                            this.props.acknowledgmentStatusTypes
                                        }
                                        approvalStatusTypes={this.props.approvalStatusTypes}
                                        noteCategoryTypes={this.props.noteCategoryTypes}
                                        notePurposeTypes={this.props.notePurposeTypes}
                                        noteSearchCriteria={this.state.noteSearchCriteria}
                                        onChangeAlt={this.handleChangeAlt}
                                        onCheckmateSingleSelectChange={
                                            this.handleCheckmateSingleSelectChange
                                        }
                                        onConditionOptionChange={(filters, filterIdx) => {
                                            this.handleFilterConditionOptionChange(
                                                filters,
                                                filterIdx
                                            );
                                        }}
                                        onDeleteField={(itemToDelete) =>
                                            this.deleteField(itemToDelete)
                                        }
                                        onFilterValuesSelectedAlt={this.filterValuesSelectedAlt}
                                        queryFilterCriteriaSelected={
                                            this.state.queryFilterCriteriaSelected
                                        }
                                        queryType={this.state.queryType}
                                        user={this.props.user}
                                        buildValuesColumn={
                                            this.buildQueryFilterCriteriaValuesColumn
                                        }
                                    />
                                    {this.state.queryType === QueryTypes.CaseDetails.Value && (
                                        <DisplayColumnSelector
                                            availableColumns={this.exportFilterForDisplayColumns()}
                                            onQueryColumnsSelected={this.queryColumnsSelected}
                                            selectedColumns={this.state.displayColumnsSelected}
                                            validation={this.state.validation}
                                        />
                                    )}
                                    {this.state.displayRecordCount && recordCount >= 0 && (
                                        <div className="row mb-2">
                                            <div
                                                className="float-end text-gray"
                                                style={{ textAlign: 'right' }}
                                            >
                                                Returned {recordCount} Record(s)
                                            </div>
                                        </div>
                                    )}
                                    {this.getQueryResultsUIByQueryType()}
                                    {this.state.showExportModal && (
                                        <QueryExportModal
                                            open={this.state.showExportModal}
                                            onHide={this.closeExportModal}
                                            validation={this.state.validation}
                                            availableColumnsForExport={this.exportFilterForDisplayColumns()}
                                            selectedColumns={
                                                this.state.displayColumnsSelectedForExport
                                            }
                                            onExportColumnsChange={
                                                this.queryColumnsSelectedForExport
                                            }
                                            onSubmit={this.generateAndDownloadReport}
                                        />
                                    )}
                                    <CheckmateDialog
                                        isShowingModal={this.state.showConfirmRemoveCaseNoteDialog}
                                        body="Are you sure you want to delete the selected note? Deleting this note will delete the note permanently from the Case File."
                                        handleClose={this.handleCancelDeleteNote}
                                        handleConfirm={this.deleteNote}
                                        confirmText="Yes"
                                        cancelText="No"
                                        confirmButtonClassName="btn btn-black float-end"
                                        dialogClassName="confirm-document-delete-dialog"
                                        closeButtonClassName="btn btn-default float-end"
                                    />
                                    <NoteCategoryAdditionalInfoDetailDialog
                                        noteCategoryTypes={this.props.noteCategoryTypes.filter(
                                            (x) => x.noteType.id === this.getNoteTypeByQueryType()
                                        )}
                                        isShowingModal={
                                            this.state.displayNoteCategoryAdditionalInfo
                                        }
                                        handleClose={() =>
                                            this.setState({
                                                displayNoteCategoryAdditionalInfo: false,
                                            })
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>

                <CheckmateDialog
                    isShowingModal={this.state.showTooManyRecordsToDisplayDialog || false}
                    title="Query Results"
                    body={
                        'Your query returned more than ' +
                        maxDisplayRecordCount +
                        ' records. Please refine your search or export to view results.'
                    }
                    handleClose={() => this.setState({ showTooManyRecordsToDisplayDialog: false })}
                    cancelText="Ok"
                    dialogClassName="user-profile-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />

                <QueryTooManyRecordsModal
                    maxRecordCount={maxRecordCountForWebProcessing}
                    onHide={() => this.setState({ showTooManyRecordsToProcessDialog: false })}
                    open={!!this.state.showTooManyRecordsToProcessDialog}
                />

                <QueryExportInBackgroundModal
                    maxExportRecordCount={maxExportRecordCount}
                    onHide={() => this.setState({ showExportInBackgroundDialog: false })}
                    open={!!this.state.showExportInBackgroundDialog}
                    queryType={this.state.queryType}
                />
            </>
        );
    }
}
