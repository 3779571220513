import {
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    CaseFileStaticItems,
    ConditionSelectorEnums,
} from './Constants';

import Common from '../stores/Common';

// AR - @Josh - You are probably not going to use this file; So just got it to build!
export default class TriggerRuleHelper {
    static getTriggerAssertionDescriptorSummary = (item: any, assertionDescriptorMatch: any) => {
        let fieldSummary = item.label + ': ';

        const getSelectedValuesSummary = () => {
            if (!item?.triggerAllChanges) {
                if (item?.selectedValues?.length) {
                    let i = 0;
                    for (const v of item.selectedValues) {
                        fieldSummary = fieldSummary + (i > 0 ? ' | ' : '') + v.label;
                        i++;
                    }
                }
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getDateRangeSummary = () => {
            if (!item?.triggerAllChanges) {
                if (item?.startDate && !item?.endDate)
                    fieldSummary += 'After ' + Common.dateFormat(item.startDate);
                else if (!item?.startDate && item?.endDate)
                    fieldSummary += 'Before ' + Common.dateFormat(item.endDate);
                else if (item?.startDate && item?.endDate)
                    fieldSummary +=
                        'Between ' +
                        Common.dateFormat(item.startDate) +
                        ' and ' +
                        Common.dateFormat(item.endDate);
                else if (item?.condition && item?.dateRangeInterval){
                    fieldSummary += 'In ' + (item?.dateRangeInterval > 0 ? 'next '+ item?.dateRangeInterval : 'past ' + (-1)*item?.dateRangeInterval) + ' days';
                }
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getTextSummary = () => {
            if (!item?.triggerAllChanges) {
                fieldSummary += item.text;
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getBooleanSummary = () => {
            if (!item?.triggerAllChanges) {
                fieldSummary += item.booleanValue ? 'Yes' : 'No';
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getNumericSummary = () => {
            if (!item?.triggerAllChanges) {
                if (item.numericValueFrom && item.numericValueTo)
                    fieldSummary +=
                        'Between ' + item.numericValueFrom + ' and ' + item.numericValueTo;
                else if (item.numericValueFrom)
                    fieldSummary += 'Greater than ' + item.numericValueFrom;
                else fieldSummary += 'Less than ' + item.numericValueTo;
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getCurrencySummary = () => {
            if (!item?.triggerAllChanges) {
                if (item.numericValueFrom && item.numericValueTo)
                    fieldSummary +=
                        'Between ' +
                        Common.formatCurrency(item.numericValueFrom) +
                        ' and ' +
                        Common.formatCurrency(item.numericValueTo);
                else if (item.numericValueFrom)
                    fieldSummary += 'Greater than ' + Common.formatCurrency(item.numericValueFrom);
                else fieldSummary += 'Less than ' + Common.formatCurrency(item.numericValueTo);
            } else {
                fieldSummary += 'All Changes';
            }
        };

        switch (assertionDescriptorMatch?.valueSourceType?.id) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                if (
                    assertionDescriptorMatch.assertionDescriptorValues &&
                    assertionDescriptorMatch.assertionDescriptorValues.length > 0
                ) {
                    getSelectedValuesSummary();
                }
                break;
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                getBooleanSummary();
                break;
            case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                    ? assertionDescriptorMatch.valueDisplayType.id
                    : 0;

                switch (valueDisplayTypeId) {
                    case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        getCurrencySummary();
                        break;
                    case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                    case AssertionDescriptorValueDisplayTypes.Number.Value:
                    case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                        getNumericSummary();
                        break;
                    case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                        getDateRangeSummary();
                        break;
                    default:
                        getTextSummary();
                        break;
                }
                break;
            }
            default:
                break;
        }

        return fieldSummary;
    };

    static getNonAssertionDescriptorSummary = (item: any) => {
        let fieldSummary = '';

        const getSelectedValuesSummary = () => {
            if (item?.blank === null || item?.blank === undefined) {
                if (item?.selectedValues?.length) {
                    if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                        fieldSummary += 'Not Equal To '
                    }
                    let i = 0;
                    for (const v of item.selectedValues) {
                        const label = v.label;

                        fieldSummary = fieldSummary + (i > 0 ? ' | ' : '') + label;
                        i++;
                    }
                }
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getDateRangeSummary = () => {
            if (item?.blank === null || item?.blank === undefined) {
                if (item?.startDate && !item?.endDate)
                    fieldSummary += 'After ' + Common.dateFormat(item.startDate);
                else if (!item?.startDate && item?.endDate)
                    fieldSummary += 'Before ' + Common.dateFormat(item.endDate);
                else if (item?.startDate && item?.endDate)
                    fieldSummary +=
                        'Between ' +
                        Common.dateFormat(item.startDate) +
                        ' and ' +
                        Common.dateFormat(item.endDate);
                 else if (item?.condition && item?.dateRangeInterval){
                    fieldSummary += 'In ' + (item?.dateRangeInterval > 0 ? 'next '+ item?.dateRangeInterval : 'past ' + (-1)*item?.dateRangeInterval) + ' days';
                 }
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getTextSummary = () => {
            if (item?.blank === null || item?.blank === undefined) {
                if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                    fieldSummary += 'Not Equal To '
                }
                fieldSummary += item.text;
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getBooleanSummary = () => {
            if (item?.blank === null || item?.blank === undefined) {
                if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                    fieldSummary += 'Not Equal To '
                }
                fieldSummary += item.booleanValue ? 'Yes' : 'No';
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        switch (item.staticItemId) {
            case CaseFileStaticItems.CaseNumber.Id:
            case CaseFileStaticItems.UniqueCaseID.Id:
                getTextSummary();
                break;
            case CaseFileStaticItems.CaseStatus.Id:
                getSelectedValuesSummary();
                break;
            //CHECK739
            case CaseFileStaticItems.CaseManager.Id:
                //case CaseFileStaticItems.CaseManager.Id:
                //case CaseFileStaticItems.AlternateCaseManager.Id:
                getSelectedValuesSummary();
                break;

            case CaseFileStaticItems.LocalCounsel.Id:
                getSelectedValuesSummary();
                break;
            case CaseFileStaticItems.OtherDefenseCounsels.Id:
                getSelectedValuesSummary();
                break;
            case CaseFileStaticItems.CasePriority.Id:
                getBooleanSummary();
                break;
            case CaseFileStaticItems.CaseStatusAsOfDate.Id:
                getDateRangeSummary();
                break;
            case CaseFileStaticItems.State.Id:
                getSelectedValuesSummary();
                break;
            default:
                getSelectedValuesSummary();
                break;
        }

        return fieldSummary;
    };

    static getTriggerNonAssertionDescriptorSummary = (item: any) => {
        let fieldSummary = item.label + ': ';

        const getSelectedValuesSummary = () => {
            if (!item?.triggerAllChanges) {
                if (item?.selectedValues?.length) {
                    let i = 0;
                    for (const v of item.selectedValues) {
                        const label = v.label;

                        fieldSummary = fieldSummary + (i > 0 ? ' | ' : '') + label;
                        i++;
                    }
                }
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getTextSummary = () => {
            if (!item?.triggerAllChanges) {
                fieldSummary += item.text;
            } else {
                fieldSummary += 'All Changes';
            }
        };

        const getBooleanSummary = () => {
            if (!item?.triggerAllChanges) {
                fieldSummary += item.booleanValue ? 'Yes' : 'No';
            } else {
                fieldSummary += 'All Changes';
            }
        };

        switch (item.staticItemId) {
            case CaseFileStaticItems.CaseNumber.Id:
            case CaseFileStaticItems.UniqueCaseID.Id:
                getTextSummary();
                break;
            case CaseFileStaticItems.CaseStatus.Id:
                getSelectedValuesSummary();
                break;
            //CHECK739
            case CaseFileStaticItems.CaseManager.Id:
                //case CaseFileStaticItems.CaseManager.Id:
                //case CaseFileStaticItems.AlternateCaseManager.Id:
                getSelectedValuesSummary();
                break;

            case CaseFileStaticItems.LocalCounsel.Id:
                getSelectedValuesSummary();
                break;
            case CaseFileStaticItems.OtherDefenseCounsels.Id:
                getSelectedValuesSummary();
                break;
            case CaseFileStaticItems.CasePriority.Id:
                getBooleanSummary();
                break;
            case CaseFileStaticItems.State.Id:
                getSelectedValuesSummary();
                break;
        }

        return fieldSummary;
    };

    static getAssertionDescriptorSummary = (item: any, assertionDescriptorMatch: any) => {
        let fieldSummary = '';

        const getSelectedValuesSummary = () => {
            if (item?.blank === null) {
                if (item?.selectedValues?.length) {
                    if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                        fieldSummary += 'Not Equal To '
                    }

                    let i = 0;
                    for (const v of item.selectedValues) {
                        fieldSummary = fieldSummary + (i > 0 ? ' | ' : '') + v.label;
                        i++;
                    }
                }
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getDateRangeSummary = () => {
            if (item?.blank === null) {
                if (item?.startDate && !item?.endDate)
                    fieldSummary += 'After ' + Common.dateFormat(item.startDate);
                else if (!item?.startDate && item?.endDate)
                    fieldSummary += 'Before ' + Common.dateFormat(item.endDate);
                else if (item?.startDate && item?.endDate)
                    fieldSummary +=
                        'Between ' +
                        Common.dateFormat(item.startDate) +
                        ' and ' +
                        Common.dateFormat(item.endDate);
                else if (item?.condition && item?.dateRangeInterval){
                    fieldSummary += 'In ' + (item?.dateRangeInterval > 0 ? 'next '+ item?.dateRangeInterval : 'past ' + (-1)*item?.dateRangeInterval) + ' days';
                }
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getTextSummary = () => {
            if (item?.blank === null) {
                if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                    fieldSummary += 'Not Equal To '
                }
                fieldSummary += item.text;
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getBooleanSummary = () => {
            if (item?.blank === null) {
                if (item.condition === ConditionSelectorEnums.NotEqualTo) {
                    fieldSummary += 'Not Equal To '
                }
                fieldSummary += item.booleanValue ? 'Yes' : 'No';
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getNumericSummary = () => {
            if (item?.blank === null) {
                if (item.numericValueFrom && item.numericValueTo)
                    fieldSummary +=
                        'Between ' + item.numericValueFrom + ' and ' + item.numericValueTo;
                else if (item.numericValueFrom)
                    fieldSummary += 'Greater than ' + item.numericValueFrom;
                else fieldSummary += 'Less than ' + item.numericValueTo;
            } else if (item?.blank != undefined) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            }
        };

        const getCurrencySummary = () => {
            if (item.blank !== null) {
                fieldSummary += item.blank ? 'Blank' : 'Not Blank';
            } else if (!item?.triggerAllChanges) {
                if (item.numericValueFrom && item.numericValueTo)
                    fieldSummary +=
                        'Between ' +
                        Common.formatCurrency(item.numericValueFrom) +
                        ' and ' +
                        Common.formatCurrency(item.numericValueTo);
                else if (item.numericValueFrom)
                    fieldSummary += 'Greater than ' + Common.formatCurrency(item.numericValueFrom);
                else fieldSummary += 'Less than ' + Common.formatCurrency(item.numericValueTo);
            } else {
                fieldSummary += 'All Changes';
            }
        };

        switch (assertionDescriptorMatch?.valueSourceType?.id) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                if (
                    assertionDescriptorMatch.assertionDescriptorValues &&
                    assertionDescriptorMatch.assertionDescriptorValues.length > 0
                ) {
                    getSelectedValuesSummary();
                }
                break;
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                getBooleanSummary();
                break;
            case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                    ? assertionDescriptorMatch.valueDisplayType.id
                    : 0;
                switch (valueDisplayTypeId) {
                    case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        getCurrencySummary();
                        break;
                    case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                    case AssertionDescriptorValueDisplayTypes.Number.Value:
                    case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                        getNumericSummary();
                        break;
                    case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                        getDateRangeSummary();
                        break;
                    default:
                        getTextSummary();
                        break;
                }
                break;
            }
            default:
                break;
        }

        return fieldSummary;
    };
}
