import {
    AlertSubTypesEnum,
    AlertTypesEnum,
    DataScopesEnum,
    EntityTypes,
    IQueryExportCountModel,
    LookupDataEnums,
    MyDocumentsTypesEnum,
    StrategyTypes,
} from '../utilities/Constants';
import {
    IAlertListWithCountModel,
    IAlertSearchModel,
    IAlertSummaryModel,
    INoteCategoryTypeModel,
    INoteModel,
} from '../interfaces/INote';
import { IApiRequest, IApiResponse, ApiClient as TpiApiClient } from '../tpi.apiclient';
import {
    IAssertionDescriptorDisplayCategoryLookupModel,
    IDataScopeLookupModel,
    IDatabaseAppSettingModel,
    ILookupModel,
    IStaticItemLookupModel,
} from '../interfaces/ILookup';
import {
    IAssertionDescriptorExtendedModel,
    IAssertionDescriptorModel,
    IEntityAssertionModel,
} from '../interfaces/IAssertionDescriptor';
import {
    IAssignmentModel,
    ICaseDeadlineModel,
    ICaseModel,
    ICasePlanTemplate,
    ICaseSearchModel,
    ICaseStatusLookupModel,
    ICaseTitleModel,
    ICheckmateTaskModel,
    ICreateCheckmateTaskModel,
    IDepositionModel,
    IDocumentModel,
    IPackageSettlementBaseModel,
    IPackageSettlementModel,
    IUserTaskListModel,
} from '../interfaces/ICase';
import {
    IAssociatedOrganizationModel,
    IOrganizationModel,
    IOrganizationSettingModel,
} from '../interfaces/IOrganization';
import { IBoardComment, IBoardParametersModel } from '../interfaces/Report/IBoard';
import {
    ICaseExpertModel,
    ICaseExpertViewDetailModel,
    ICreateCaseExpertModel,
    IUpdateCaseExpertModel,
} from '../interfaces/ICaseExpert';
import {
    ICreateStrategyModel,
    IJurisdictionStrategyDetailModel,
    ILocalCounselStrategyDetailModel,
    IPlaintiffsFirmStrategyDetailModel,
    IProjectModel,
    IStrategySummaryModel,
    IUpdateStrategyModel,
} from '../interfaces/IStrategy';
import {
    IDepositionUISearchModel,
    INoteUISearchModel,
    IQueryResultModel,
    IQuerySettingsModel,
} from '../interfaces/IQuery';
import { IDocumentSearchModel, IViewDocumentModel } from '../interfaces/IDocument';
import {
    IEntityConversationCreateModel,
    IEntityConversationModel,
    IReportModel,
    IReportParametersBaseModel,
    IReportSaveModel,
    IReportTypeModel,
    IUpdateAlertUserModel,
    IUserAuditModel,
    IUserReportParametersModel,
} from '../interfaces/Report/IReport';
import { IEntityLease, IObtainEntityLeaseResponse } from '../interfaces/IEntityLease';
import {
    IExpertCreateModel,
    IExpertDetailModel,
    IExpertListModel,
    IExpertModel,
} from '../interfaces/IExpert';
import {
    IInvitationModel,
    IRegistrationModel,
    IUserModel,
    IUserZoneProfileSearchModel,
} from '../interfaces/IUser';
import { IMonthlyRecapModel, IWeeklyRecapModel } from '../interfaces/Report/ITimelineReport';
import { IResponse, IResponseModel } from '../interfaces/IResponse';
import { ITaskCategoryModel, IUserSettingModel, IUserZoneProfileModel } from '../interfaces/ITask';

import { ApiRoutes } from '../utilities/ApiRoutes';
import Auth from '../stores/authentication';
import { ICollabReportStatusModel } from '../interfaces/ICollabReport';
import { ICoordinates } from '../interfaces/ICoordinates';
import { IDisplaySectionModel } from '../interfaces/IDisplaySectionModel';
import { IFavoriteModel } from '../interfaces/IFavorite';
import { IKpiModel } from '../interfaces/IKpiView';
import { ILoginResponse } from '../interfaces/ILoginResponse';
import { ILoginUser } from '../interfaces/IAccount';
import { IRecoverAccount } from '../interfaces/IAccount';
import { IReportCaseDetailModel } from '../interfaces/Report/IReportCaseDetailModel';
import { ISnapshotViewModel } from '../interfaces/ISnapshotView';
import { IThreatReportParametersModel } from '../interfaces/Report/IThreatReportParameters';
import { ITimelineReportInputModel } from '../interfaces/Report/ITimelineReport';
import { ITriggerRuleSettingsModel } from '../interfaces/ITriggerRule';
import { config } from '../config/config';

class CheckmateApiClient extends TpiApiClient {
    constructor() {
        super(config.baseUrl);
    }

    /**
     * Prepares the request for transmission.
     * @param {ApiRequest} request The request object.
     * @returns {Promise<ApiRequest>} The prepared request object.
     */
    async prepareRequest<T>(request: IApiRequest<T>): Promise<IApiRequest<T>> {
        request = await super.prepareRequest(request);

        request.httpRequest.headers.set('User-Agent', 'CheckmateApiClient/1.0');
        request.httpRequest.headers.set('Credentials', 'same-origin');

        return request;
    }

    /**
     * Processes a response after completion.
     * @param {ApiRequest} request The request object.
     * @param {ApiResponse} response The response object.
     * @returns {Promise<ApiResponse>} The processed response object.
     */
    async processResponse<T>(
        request: IApiRequest<any>,
        response: IApiResponse<T>
    ): Promise<IApiResponse<T>> {
        response = await super.processResponse(request, response);
        return response;
    }

    // ===========================================================================
    // organization API Calls

    getDBAppSettings() {
        return this.get<IDatabaseAppSettingModel[]>(ApiRoutes.DBAppSettings);
    }

    getLookupData(lookupType: LookupDataEnums) {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + lookupType);
    }

    getDataScopes() {
        return this.get<IDataScopeLookupModel[]>(ApiRoutes.Lookup + LookupDataEnums.DataScopes);
    }

    getCaseStatusLookupData() {
        return this.get<ICaseStatusLookupModel[]>(
            ApiRoutes.Lookup + LookupDataEnums.CaseStatusTypes
        );
    }

    getCasePlanTemplates() {
        return this.get<ICasePlanTemplate[]>(ApiRoutes.Lookup + LookupDataEnums.CasePlanTemplates);
    }

    getTaskCategories() {
        return this.get<ITaskCategoryModel[]>(ApiRoutes.Lookup + LookupDataEnums.TaskCategories);
    }

    getReportTypes() {
        return this.get<IReportTypeModel[]>(ApiRoutes.Lookup + LookupDataEnums.ReportTypes);
    }

    getDocumentReportTypes() {
        return this.get<IReportTypeModel[]>(ApiRoutes.Lookup + LookupDataEnums.ReportTypes);
    }

    getNoteCategoryTypes(type?: LookupDataEnums) {
        if (type) {
            return this.get<INoteCategoryTypeModel[]>(ApiRoutes.Lookup + type);
        } else
            return this.get<INoteCategoryTypeModel[]>(
                ApiRoutes.Lookup + LookupDataEnums.NoteCategories
            );
    }

    getNoteCategoryTypesByEntityType(
        entityType?: EntityTypes
    ): Promise<IApiResponse<INoteCategoryTypeModel[]>> {
        let response = null;
        switch (entityType) {
            case EntityTypes.Expert:
            case EntityTypes.PackageSettlement:
            case EntityTypes.Deposition:
            case EntityTypes.Organization:
            case EntityTypes.Project:
            case EntityTypes.AssertionDescriptorValueStrategy:
                response = this.getNoteCategoryTypes(LookupDataEnums.StrategyNoteCategories);
                break;
            case EntityTypes.Case:
            case EntityTypes.CaseExpert:
                response = this.getNoteCategoryTypes(LookupDataEnums.CaseNoteCategories);
                break;
            default: {
                response = this.getNoteCategoryTypes();
            }
        }

        return response;
    }

    getOrganizationById(guid: string) {
        return this.get<IOrganizationModel>(ApiRoutes.OrganizationDetails.replace('{id}', guid));
    }

    getAssociatedOrganizationsById(guid: string) {
        return this.get<IOrganizationModel[]>(
            ApiRoutes.AssociatedOrganizations.replace('{id}', guid)
        );
    }

    getOrganizations(searchString?: string, organizationTypes?: number[]) {
        let route = ApiRoutes.organization;
        if (searchString && searchString !== '') {
            route += '?searchString=' + searchString;
        }
        if (organizationTypes && organizationTypes.length > 0) {
            route += '?types=' + organizationTypes.join(',');
        }
        return this.get<IOrganizationModel[]>(route);
    }

    createOrganization(organization: IOrganizationModel) {
        const body: IOrganizationModel = organization;
        return this.post<IOrganizationModel>(ApiRoutes.organization, { body });
    }

    updateOrganization(organization: IOrganizationModel) {
        const body: IOrganizationModel = organization;
        return this.put<IOrganizationModel>(ApiRoutes.organization, { body });
    }

    getOrganizationInZone(organizationTypeId?: number) {
        let route = ApiRoutes.OrganizationsInZone;
        if (organizationTypeId != null) {
            route += '?organizationTypeId=' + organizationTypeId;
        }

        return this.get<IAssociatedOrganizationModel[]>(route);
    }

    getOrganizationSettings(settingName?: string) {
        let url = ApiRoutes.OrganizationSettings;
        if (settingName) url += '?settingName=' + settingName;
        return this.get<IOrganizationSettingModel[]>(url);
    }

    // ===========================================================================
    // Phone API Calls

    getPhoneTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/phonetypes');
    }

    // ===========================================================================
    // Address API Calls

    getAddressTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/addresstypes');
    }

    // ===========================================================================
    // User API Calls
    createInvitation(userInvitation: IInvitationModel) {
        const body: IInvitationModel = userInvitation;
        return this.post<IInvitationModel>(ApiRoutes.UserInvitation, { body });
    }

    getUserInvitation(guid: string) {
        return this.get<IInvitationModel>(ApiRoutes.UserInvitationDetails.replace('{id}', guid));
    }

    getRoles() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/roles');
    }

    getAllUsers() {
        return this.get<IUserModel[]>(ApiRoutes.Lookup + '/users');
    }

    createUserAccount(userInformation: IRegistrationModel) {
        const body: IRegistrationModel = userInformation;
        return this.post<IRegistrationModel>(ApiRoutes.User, { body });
    }

    getUsers(active: boolean, searchString?: string) {
        let route = ApiRoutes.User + '?active=' + active.toString();
        if (searchString) route += '&searchString=' + searchString;

        return this.get<IUserModel[]>(route);
    }

    getCaseManagersInZone() {
        return this.getUsersInZone(DataScopesEnum.National.Value);
    }

    getUsersInZone(dataScope?: number) {
        let url = ApiRoutes.UserInZone;

        if (dataScope) {
            url += '?dataScope=' + dataScope;
        }

        return this.get<IUserModel[]>(url);
    }

    getUsersByRolesAndRights(roles: number[], rights: number[]) {
        let url = ApiRoutes.UserByRoleRight + '?';
        for (let i = 0; i < roles.length; i++) {
            url += 'roles=' + roles[i].toString() + '&';
        }

        for (let j = 0; j < rights.length; j++) {
            url += 'rights=' + rights[j].toString() + '&';
        }

        return this.get<IUserModel[]>(url);
    }

    getUserDetails(guid: string) {
        return this.get<IUserModel>(ApiRoutes.UserDetails.replace('{id}', guid));
    }

    getUserTasks(statusFilterList: string) {
        const url = ApiRoutes.UserTasks + '?statuses=' + statusFilterList;
        return this.put<IUserTaskListModel[]>(url);
    }

    getTaskBarInfo() {
        return this.get<IUserTaskListModel[]>(ApiRoutes.TaskBar);
    }

    getAlertBarInfo() {
        return this.get<IAlertSummaryModel[]>(ApiRoutes.UserAlertSummary);
    }

    updateUserDetails(userInformation: IUserModel) {
        const body: IUserModel = userInformation;
        return this.put<IUserModel>(ApiRoutes.User, { body });
    }

    //createUserDetails(userInformation: IUserModel) {
    //    var body: IUserModel = userInformation;
    //    return this.post<IUserModel>(ApiRoutes.UserDetails, { body });
    //}

    loginAcceptInvitation(login: ILoginUser, invitationGuid: string) {
        const body: ILoginUser = login;
        return this.post<ILoginUser>(ApiRoutes.Login + '?invitationGuid=' + invitationGuid, {
            body,
        });
    }

    loginVerifyTwoFactorCode(login: ILoginUser, twoFactorToken: string) {
        const body: ILoginUser = login;
        return this.post<ILoginResponse>(ApiRoutes.Login + '?twoFactorToken=' + twoFactorToken, {
            body,
        });
    }

    recoverAccount(recoverAccount: IRecoverAccount) {
        const body: IRecoverAccount = recoverAccount;
        return this.post<IRecoverAccount>(ApiRoutes.AccountRecover, { body });
    }

    resetPassword(userId: string, code: string, password: string) {
        return this.post<IResponse>(ApiRoutes.ResetPassword, {
            body: { id: userId, code: code, password: password },
        });
    }

    login(login: ILoginUser) {
        const body: ILoginUser = login;
        return this.post<ILoginResponse>(ApiRoutes.Login, { body });
    }

    acceptTermsOfService(userGuid: string, login: ILoginUser) {
        const body: ILoginUser = login;
        return this.post<ILoginResponse>(ApiRoutes.AcceptTermsOfService.replace('{id}', userGuid), {
            body,
        });
    }

    getLoggedInUserInfo() {
        return this.get<IUserModel>(ApiRoutes.LoggedInUserDetails);
    }

    logout() {
        return this.post<ILoginUser>(ApiRoutes.Logout);
    }

    getDefaultUserOrganization() {
        return Auth.getUserDefaultOrganization();
    }

    getDefaultUserOrganizationFromServer() {
        const orgInLocalStorage = this.getDefaultUserOrganization();
        if (orgInLocalStorage) {
            return this.getOrganizationById(orgInLocalStorage.guid!);
        }
    }

    setDefaultUserOrganization(
        organizationGuid: string,
        organizationName: string,
        locationKey = ''
    ) {
        const body = {
            organizationGuid,
            locationKey,
        };
        return this.post<IResponse>(ApiRoutes.SetDefaultUserOrganization, { body }).then(
            (response) => {
                if (!response.errorMessage) {
                    //write to local memory
                    Auth.setUserDefaultOrganization(organizationGuid, organizationName);
                } else {
                    console.error(response.errorMessage);
                }
                return response;
            }
        );
    }

    //Assertion Descriptor Api Calls

    getAssertionDescriptors(
        usageTypeId: number,
        searchString?: string,
        checkForEditAccess?: boolean
    ) {
        const body: any = {
            usageTypeId: usageTypeId,
            searchString: searchString,
            checkForEditAccess: checkForEditAccess,
        };

        return this.post<IAssertionDescriptorModel[]>(ApiRoutes.assertiondescriptors, {
            body,
        });
    }

    getAssertionDescriptorById(guid: string) {
        if (!guid) {
            const msg = 'Assertion Descriptor GUID not provided.';
            console.error(msg);
            throw new Error(msg);
        }
        return this.get<IAssertionDescriptorExtendedModel>(
            ApiRoutes.AssertionDescriptorDetails.replace('{id}', guid)
        );
    }

    createAssertionDescriptor(assertionDescriptor: IAssertionDescriptorModel) {
        const body: IAssertionDescriptorModel = assertionDescriptor;
        return this.post<IAssertionDescriptorModel>(ApiRoutes.assertiondescriptor, {
            body,
        });
    }

    updateAssertionDescriptor(assertionDescriptor: IAssertionDescriptorModel) {
        const body: IAssertionDescriptorModel = assertionDescriptor;
        return this.put<IAssertionDescriptorModel>(ApiRoutes.assertiondescriptor, {
            body,
        });
    }

    getAssertionDescriptorUsageTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/assertiondescriptorusagetypes');
    }

    getAssertionDescriptorLevels() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/threatassessmentlevels');
    }

    getAssertionDescriptorCategories() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/assertiondescriptorcategories');
    }

    getAssertionDescriptorValueSourceType() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/assertiondescriptorvaluesourcetypes');
    }

    getAssertionDescriptorValueDisplayType() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/assertiondescriptorvaluedisplaytypes');
    }

    getAssertionDescriptorDispositionType() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/assertiondescriptordispositiontypes');
    }

    getAssertionDescriptorDisplayCategories() {
        return this.get<IAssertionDescriptorDisplayCategoryLookupModel[]>(
            ApiRoutes.Lookup + '/assertiondescriptordisplaycategories'
        );
    }

    // case Api Calls

    getCaseFileStaticItems() {
        return this.get<IStaticItemLookupModel[]>(ApiRoutes.Lookup + '/CaseFileStaticItems');
    }

    getCaseFileDisplayCategories() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/AssertionDescriptorDisplayCategories');
    }

    getCaseFileModel() {
        return this.get<IDisplaySectionModel[]>(ApiRoutes.CaseFileModel);
    }

    getCases(caseFilter: ICaseSearchModel) {
        const body: ICaseSearchModel = caseFilter;
        return this.post<ICaseModel[]>(ApiRoutes.Cases, { body });
    }

    runPackageSettlementSearch(caseFilter: ICaseSearchModel) {
        const body: ICaseSearchModel = caseFilter;
        return this.post<IReportCaseDetailModel[]>(ApiRoutes.Cases, { body });
    }

    getCaseCount(caseFilter: ICaseSearchModel) {
        const body: ICaseSearchModel = caseFilter;
        return this.post<IResponseModel<number>>(ApiRoutes.Cases + '?count=true', {
            body,
        });
    }

    getCaseById(guid: string, queryString?: string) {
        let url = ApiRoutes.CaseDetails.replace('{id}', guid);
        if (queryString) url += queryString;
        return this.get<ICaseModel>(url);
    }

    getCaseTitle(caseGuid: string) {
        return this.get<ICaseTitleModel>(ApiRoutes.CaseTitle.replace('{id}', caseGuid));
    }

    updateCase(caseProfile: ICaseModel) {
        return this.put<ICaseModel>(ApiRoutes.Case, { body: caseProfile });
    }

    createCase(caseProfile: ICaseModel) {
        return this.post<ICaseModel>(ApiRoutes.Case, { body: caseProfile });
    }

    easyUpdateCase(caseProfile: ICaseModel) {
        return this.patch<ICaseModel>(ApiRoutes.Case, { body: caseProfile });
    }

    updateCasePriority(caseGuid: string, priority: string) {
        return this.put<ICaseTitleModel>(
            ApiRoutes.CasePriority.replace('{id}', caseGuid).replace('{priority}', priority)
        );
    }

    deleteCase(caseGuid: string) {
        return this.delete(ApiRoutes.CaseDetails.replace('{id}', caseGuid));
    }

    getMasterTasks(types?: string) {
        let url = ApiRoutes.MasterTasks;
        if (types) {
            url = url + '?types=' + types;
        }

        return this.get<ICheckmateTaskModel[]>(url);
    }

    createCasePlan(caseGuid: string, templateGuid: string, levelOfEffortId: number) {
        return this.post<ICasePlanTemplate[]>(
            ApiRoutes.CasePlan.replace('{id}', caseGuid)
                .replace('{templateid}', templateGuid)
                .replace('{loeid}', levelOfEffortId.toString())
        );
    }

    getCaseTaskById(caseGuid: string, casePlanGuid: string, taskGuid: string) {
        return this.get<ICheckmateTaskModel>(
            ApiRoutes.CaseTaskDetails.replace('{id}', caseGuid)
                .replace('{caseplanid}', casePlanGuid)
                .replace('{taskid}', taskGuid)
        );
    }

    createTask(caseGuid: string, casePlanGuid: string, newTask: ICreateCheckmateTaskModel) {
        const body: ICreateCheckmateTaskModel = newTask;
        return this.post<ICreateCheckmateTaskModel[]>(
            ApiRoutes.CaseTaskNew.replace('{id}', caseGuid).replace('{caseplanid}', casePlanGuid),
            { body }
        );
    }

    updateCaseDeadlines(caseGuid: string, coreDeadlines: ICaseDeadlineModel[]) {
        const body: ICaseDeadlineModel[] = coreDeadlines;
        return this.put<IUserModel>(ApiRoutes.CaseDeadlines.replace('{id}', caseGuid), { body });
    }

    updateCasePlanFirmAssignments(
        caseGuid: string,
        casePlanGuid: string,
        tasks: ICheckmateTaskModel[]
    ) {
        const body: ICheckmateTaskModel[] = tasks;
        const route = ApiRoutes.CasePlanFirmAssignments.replace('{id}', caseGuid).replace(
            '{caseplanid}',
            casePlanGuid
        );
        return this.put<ICheckmateTaskModel[]>(route, { body });
    }

    updateTasks(caseGuid: string, casePlanGuid: string, tasks: ICheckmateTaskModel[]) {
        const body: ICheckmateTaskModel[] = tasks;
        const route = ApiRoutes.CasePlanTask.replace('{id}', caseGuid).replace(
            '{caseplanid}',
            casePlanGuid
        );
        return this.put<ICheckmateTaskModel[]>(route, { body });
    }

    executeCasePlan(caseGuid: string, casePlanGuid: string) {
        const route = ApiRoutes.CasePlanExecute.replace('{id}', caseGuid).replace(
            '{caseplanid}',
            casePlanGuid
        );
        return this.put(route);
    }

    deleteTasks(caseGuid: string, casePlanGuid: string, taskGuids: string[]) {
        const body: string[] = taskGuids;
        const route = ApiRoutes.CasePlanTask.replace('{id}', caseGuid).replace(
            '{caseplanid}',
            casePlanGuid
        );
        return this.delete(route, { body });
    }

    updateCaseTaskAssignments(
        caseGuid: string,
        casePlanGuid: string,
        assignments: IAssignmentModel[]
    ) {
        const body: IAssignmentModel[] = assignments;
        const route = ApiRoutes.CasePlanTaskAssignments.replace('{id}', caseGuid).replace(
            '{caseplanid}',
            casePlanGuid
        );
        return this.put<IAssignmentModel[]>(route, { body });
    }

    getMyDocuments(searchModel: IDocumentSearchModel, pageNumber: number, pageSize: number) {
        const url = ApiRoutes.Documents.replace('{pageNumber}', pageNumber.toString()).replace(
            '{pageSize}',
            pageSize.toString()
        );
        return this.post<IViewDocumentModel[]>(url, { body: searchModel });
    }

    getMyDocumentsCount(searchModel: IDocumentSearchModel) {
        const body: IDocumentSearchModel = searchModel;
        return this.post<IResponseModel<number>>(ApiRoutes.MyDocumentsCount, { body });
    }

    getCollabReportChildren(reportGuid: string, entitytypeid: number) {
        const url = ApiRoutes.ChildDocuments.replace('{id}', reportGuid).replace(
            '{entitytypeid}',
            entitytypeid.toString()
        );

        return this.get<IViewDocumentModel[]>(url);
    }

    getCollaborationReportStatus(reportGuid: string) {
        return this.get<ICollabReportStatusModel[]>(
            ApiRoutes.CollaborationReportGetStatus.replace('{collaborationReportId}', reportGuid)
        );
    }

    getEntityDocumentById(
        entityGuid: string,
        entityTypeId: number,
        documentGuid: string,
        subTypeId?: number
    ) {
        let url = ApiRoutes.EntityDocument.replace('{id}', entityGuid)
            .replace('{entitytypeid}', entityTypeId.toString())
            .replace('{documentid}', documentGuid);
        if (subTypeId) url += '?subTypeId=' + subTypeId.toString();
        return this.get<IDocumentModel>(url);
    }

    // following is used for creating and updating documents
    saveEntityDocument(
        entityGuid: string,
        entityTypeId: number,
        document: IDocumentModel,
        noteTypeId?: number,
        strategyTypeId?: number
    ) {
        const body: IDocumentModel = document;
        let url = ApiRoutes.EntityDocumentSave.replace('{id}', entityGuid).replace(
            '{entitytypeid}',
            entityTypeId.toString()
        );
        const queryStrings: string[] = [];
        if (noteTypeId) queryStrings.push('noteTypeId=' + noteTypeId.toString());
        if (strategyTypeId) queryStrings.push('strategyTypeId=' + strategyTypeId.toString());
        if (queryStrings.length > 0) url += '?' + queryStrings.join('&');

        return this.post<IDocumentModel[]>(url, { body });
    }

    deleteEntityDocument(
        entityGuid: string,
        entityTypeId: number,
        documentGuid: string,
        noteTypeId?: number,
        strategyTypeId?: number
    ) {
        let url = ApiRoutes.EntityDocument.replace('{id}', entityGuid)
            .replace('{entitytypeid}', entityTypeId.toString())
            .replace('{documentid}', documentGuid);
        const queryStrings: string[] = [];
        if (noteTypeId) queryStrings.push('noteTypeId=' + noteTypeId.toString());
        if (strategyTypeId) queryStrings.push('strategyTypeId=' + strategyTypeId.toString());
        if (queryStrings.length > 0) url += '?' + queryStrings.join('&');
        return this.delete<IDocumentModel>(url);
    }

    deleteMyDocument(documentGuid: string) {
        let url = ApiRoutes.MyDocumentsDelete.replace('{id}', documentGuid);
        const queryStrings: string[] = [];
        if (queryStrings.length > 0) url += '?' + queryStrings.join('&');
        return this.delete<IDocumentModel>(url);
    }

    getDocumentTemplates() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/documenttemplates');
    }

    getDocumentTemplateById(guid: string) {
        return this.get<string>(ApiRoutes.DocumentTemplate.replace('{id}', guid));
    }

    // Report Api Calls

    getReportOptions() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/threatreportoptions');
    }

    // User zone profile Api Calls

    getUserZoneProfile() {
        return this.get<IUserZoneProfileModel>(ApiRoutes.UserZoneProfile);
    }

    getUserSetting(type?: number, defaultOrg?: boolean) {
        let url = ApiRoutes.UserSetting;
        if (type) {
            url = url + '?type=' + type;
            if (defaultOrg) url = url + '&defaultOrg=true';
        } else {
            if (defaultOrg) url = url + '?defaultOrg=true';
        }

        return this.get<IUserSettingModel[]>(url);
    }

    saveUserSetting(userSetting: IUserSettingModel) {
        const body: IUserSettingModel = userSetting;
        return this.post<IUserSettingModel>(ApiRoutes.UserSetting, { body });
    }

    getUserZoneProfiles(search: IUserZoneProfileSearchModel) {
        const body: IUserZoneProfileSearchModel = search;
        return this.post<IUserZoneProfileModel[]>(ApiRoutes.UserZoneProfiles, {
            body,
        });
    }

    createUserZoneProfile(userZoneProfile: IUserZoneProfileModel) {
        const body: IUserZoneProfileModel = userZoneProfile;
        return this.post<IUserZoneProfileModel>(ApiRoutes.UserZoneProfile, {
            body,
        });
    }

    updateUserZoneProfile(userZoneProfile: IUserZoneProfileModel) {
        const body: IUserZoneProfileModel = userZoneProfile;
        return this.put<IUserZoneProfileModel>(ApiRoutes.UserZoneProfile, { body });
    }

    getUserZoneProfileRoleTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/userzoneprofileroles');
    }

    getUserZoneProfileSkillTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/skills');
    }

    getUserZoneProfileSkillLevelTypes() {
        return this.get<ILookupModel[]>(ApiRoutes.Lookup + '/skilllevels');
    }

    getDocumentCategoriesForEntityType(entityTypeId: number) {
        const url = ApiRoutes.LookupEntityType.replace(
            '{entitytypeid}',
            entityTypeId.toString()
        ).replace('{lookuptype}', 'documentcategorytypes');
        return this.get<ILookupModel[]>(url);
    }

    getNotes(typeId: number, key?: string) {
        let url = ApiRoutes.Notes + '?typeId=' + typeId.toString();
        if (key) url += '&key=' + key;
        return this.get<INoteModel[]>(url);
    }

    saveNote(note: INoteModel) {
        const body: INoteModel = note;
        return note.guid
            ? this.put<INoteModel>(ApiRoutes.Note, { body })
            : this.post<INoteModel>(ApiRoutes.Note, { body });
    }

    deleteNote(noteGuid: string, key?: string) {
        let route = ApiRoutes.NoteDetail.replace('{id}', noteGuid);
        if (key) route += '?key=' + key;
        return this.delete(route);
    }

    generateReportRequest(reportData: IThreatReportParametersModel) {
        const body: IThreatReportParametersModel = reportData;
        return this.post<IThreatReportParametersModel>(ApiRoutes.ThreatReport, {
            body,
        });
    }

    getUserReports(read?: boolean) {
        let url = '';
        if (typeof read === 'undefined') {
            url = ApiRoutes.UserReports;
        } else if (read) {
            url = ApiRoutes.UserReports + '?read=true';
        } else {
            url = ApiRoutes.UserReports + '?read=false';
        }

        return this.get<IUserReportParametersModel[]>(url);
    }

    deleteReport(id: string) {
        return this.delete(ApiRoutes.ReportDetail.replace('{id}', id));
    }

    getBoardCaseDetails(
        type: string,
        parameters: IBoardParametersModel,
        id?: string,
        date?: string
    ) {
        const body: IBoardParametersModel = parameters;
        let url = ApiRoutes.BoardCaseDetails.replace('{type}', type);
        if (id) url += '&id=' + id;
        if (date) url += '&date=' + date;

        return this.post<IReportCaseDetailModel[]>(url, { body });
    }

    getBoardComments() {
        return this.get<IBoardComment[]>(ApiRoutes.BoardComments);
    }

    saveBoardComments(comments: IBoardComment[]) {
        const body: IBoardComment[] = comments;
        return this.post<IBoardComment[]>(ApiRoutes.BoardComments, { body });
    }

    deleteBoardComments() {
        return this.delete(ApiRoutes.BoardComments);
    }

    createTrigger(trigger: ITriggerRuleSettingsModel) {
        return this.post<ITriggerRuleSettingsModel>(ApiRoutes.TriggerCreate, { body: trigger });
    }

    searchTriggers(params: any = {}) {
        return this.post<ITriggerRuleSettingsModel[]>(ApiRoutes.TriggerSearch, { data: params });
    }

    getTrigger(guid: string) {
        const uri = ApiRoutes.TriggerGet.replace('{guid}', guid);
        return this.get<ITriggerRuleSettingsModel>(uri);
    }

    updateTrigger(trigger: ITriggerRuleSettingsModel) {
        if (!trigger.guid) return;
        return this.put<ITriggerRuleSettingsModel>(
            ApiRoutes.TriggerUpdate.replace('{guid}', trigger.guid),
            {
                body: trigger,
            }
        );
    }

    deleteTrigger(guid: string) {
        const uri = ApiRoutes.TriggerGet.replace('{guid}', guid);
        return this.delete(uri);
    }

    getCaseQueryResults<T extends IQueryResultModel | ICaseExpertModel[]>(
        querySettings: IQuerySettingsModel
    ) {
        return this.post<T>(ApiRoutes.Query, { body: querySettings });
    }

    getCaseQueryCount(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<IResponseModel<number>>(ApiRoutes.Query + '?count=true', {
            body,
        });
    }

    getCaseNotesQueryResults(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<INoteModel[]>(ApiRoutes.Query, { body });
    }

    getDepositionQueryResults(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<IDepositionModel[]>(ApiRoutes.Query, { body });
    }

    getCaseQueryResultsLegacy<T extends IQueryResultModel | ICaseExpertModel[]>(
        querySettings: IQuerySettingsModel
    ) {
        return this.post<T>(ApiRoutes.QueryLegacy, { body: querySettings });
    }

    getCaseQueryCountLegacy(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<IResponseModel<number>>(ApiRoutes.QueryLegacy + '?count=true', {
            body,
        });
    }

    getCaseNotesQueryResultsLegacy(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<INoteModel[]>(ApiRoutes.QueryLegacy, { body });
    }

    getDepositionQueryResultsLegacy(querySettings: IQuerySettingsModel) {
        const body: IQuerySettingsModel = querySettings;
        return this.post<IDepositionModel[]>(ApiRoutes.QueryLegacy, { body });
    }

    createDeposition(deposition: IDepositionModel) {
        const body: IDepositionModel = deposition;
        return this.post<IDepositionModel>(ApiRoutes.Deposition, { body });
    }

    updateDeposition(deposition: IDepositionModel) {
        const body: IDepositionModel = deposition;
        return this.put<IDepositionModel>(ApiRoutes.Deposition, { body });
    }

    deleteDeposition(guid: string) {
        return this.delete(ApiRoutes.Deposition + '/' + guid);
    }

    getWeeklyRecapReportData(input: ITimelineReportInputModel) {
        const body: ITimelineReportInputModel = input;
        return this.post<IWeeklyRecapModel>(ApiRoutes.TimelineRecap, { body });
    }

    getMonthlyRecapReportData(input: ITimelineReportInputModel) {
        const body: ITimelineReportInputModel = input;
        return this.post<IMonthlyRecapModel>(ApiRoutes.TimelineRecap, { body });
    }

    getKpiViewData(endDate: string, getLiveData: boolean) {
        return this.get<IKpiModel>(
            ApiRoutes.ReportDataByReportId.replace(
                '{id}',
                MyDocumentsTypesEnum.Trends.Value.toString()
            )
                .replace('{getLiveData}', getLiveData.toString())
                .replace('{endDate?}', endDate.toString())
        );
    }

    getKpiReportData(input: IReportParametersBaseModel) {
        const body: IReportParametersBaseModel = input;
        return this.post<IKpiModel>(`${ApiRoutes.ReportDataByReportParameters}`, {
            body,
        });
    }

    getPackageSettlements(params?: {
        startDate?: string;
        endDate?: string;
        statusIds?: number[];
        plaintiffsFirmIds?: string[];
    }) {
        const baseUrl = ApiRoutes.PackageSettlements;
        const searchParams = new URLSearchParams();

        Object.entries(params ?? {}).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val) => {
                    searchParams.append(key, val.toString());
                });
            } else if (value !== undefined) {
                searchParams.set(key, value);
            }
        });

        const queryString = searchParams?.toString();
        const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;

        return this.get<IPackageSettlementBaseModel[]>(url);
    }

    getPackageSettlementDetail(guid: string) {
        return this.get<IPackageSettlementModel>(
            ApiRoutes.PackageSettlementDetail.replace('{id}', guid)
        );
    }

    getPackageSettlementDetailWithReport(guid: string, includeHistoricData: boolean) {
        return this.get<IPackageSettlementModel>(
            ApiRoutes.PackageSettlementDetail.replace('{id}', guid) +
                `?report=true&includeHistoricData=${includeHistoricData}`
        );
    }

    createPackageSettlement(packageSettlement: IPackageSettlementModel) {
        const body: IPackageSettlementModel = packageSettlement;
        return this.post<IPackageSettlementModel>(ApiRoutes.PackageSettlement, {
            body,
        });
    }

    updatePackageSettlement(packageSettlement: IPackageSettlementModel) {
        const body: IPackageSettlementModel = packageSettlement;
        return this.put<IPackageSettlementModel>(ApiRoutes.PackageSettlement, {
            body,
        });
    }

    deletePackageSettlement(guid: string) {
        return this.delete<IResponse>(ApiRoutes.PackageSettlementDetail.replace('{id}', guid));
    }

    saveEntityAssertions(assertions: IEntityAssertionModel[]) {
        const body: IEntityAssertionModel[] = assertions;
        return this.put<IEntityAssertionModel[]>(ApiRoutes.EntityAssertions, {
            body,
        });
    }

    getStrategicPlanEntityAssertions() {
        return this.get<IEntityAssertionModel[]>(ApiRoutes.StrategicPlan);
    }

    getExecutionPlanEntityAssertions() {
        return this.get<IEntityAssertionModel[]>(ApiRoutes.ExecutionPlan);
    }

    getEntityStrategies(type: number) {
        return this.get<IStrategySummaryModel[]>(ApiRoutes.Strategy + '?type=' + type.toString());
    }

    addStrategy(data: ICreateStrategyModel) {
        const body: ICreateStrategyModel = data;
        return this.post<IResponse>(ApiRoutes.Strategy, { body });
    }

    updateStrategyDetails(id: string, data: IUpdateStrategyModel) {
        const body: IUpdateStrategyModel = data;
        return this.put<IResponse>(ApiRoutes.StrategyDetail.replace('{id}', id), {
            body,
        });
    }

    removeStrategy(guid: string, strategyEntityTypeId: number) {
        return this.delete<IResponse>(
            ApiRoutes.StrategyDetail.replace('{id}', guid) + '?type=' + strategyEntityTypeId
        );
    }

    getPlaintiffsFirmsStrategyDetails(id: string) {
        return this.get<IPlaintiffsFirmStrategyDetailModel>(
            ApiRoutes.StrategyDetail.replace('{id}', id) +
                '?strategyDetailType=' +
                StrategyTypes.PlaintiffsFirm.Value
        );
    }

    getJurisdictionStrategyDetails(id: string) {
        return this.get<IJurisdictionStrategyDetailModel>(
            ApiRoutes.StrategyDetail.replace('{id}', id) +
                '?strategyDetailType=' +
                StrategyTypes.Jurisdiction.Value
        );
    }

    getLocalCounselStrategyDetails(id: string) {
        return this.get<ILocalCounselStrategyDetailModel>(
            ApiRoutes.StrategyDetail.replace('{id}', id) +
                '?strategyDetailType=' +
                StrategyTypes.LocalCounsel.Value
        );
    }

    getProjects() {
        return this.get<IProjectModel[]>(ApiRoutes.Projects);
    }

    addProject(data: IProjectModel) {
        const body: IProjectModel = data;
        return this.post<IResponse>(ApiRoutes.Projects, { body });
    }

    updateProject(id: string, data: IProjectModel) {
        const body: IProjectModel = data;
        return this.put<IEntityAssertionModel[]>(ApiRoutes.ProjectDetail.replace('{id}', id), {
            body,
        });
    }

    removeProject(guid: string) {
        return this.delete<IResponse>(ApiRoutes.ProjectDetail.replace('{id}', guid));
    }

    getProjectDetails(id: string) {
        return this.get<IProjectModel>(ApiRoutes.ProjectDetail.replace('{id}', id));
    }

    getReportTypeDetail(id: number) {
        return this.get<IReportTypeModel>(
            ApiRoutes.ReportTypeDetail.replace('{id}', id.toString())
        );
    }

    searchNotes(searchModel: INoteUISearchModel) {
        const body: INoteUISearchModel = searchModel;
        return this.post<INoteModel[]>(ApiRoutes.Notes, { body });
    }

    searchAlerts(searchModel: IAlertSearchModel) {
        const body: IAlertSearchModel = searchModel;
        const url = ApiRoutes.Alerts;
        return this.post<IAlertListWithCountModel>(url, { body });
    }

    searchDepositions(searchModel: IDepositionUISearchModel) {
        const body: IDepositionUISearchModel = searchModel;
        return this.post<IDepositionModel[]>(ApiRoutes.Depositions, { body });
    }

    getSnapshotViewData(endDate: string, getLiveData: boolean) {
        return this.get<ISnapshotViewModel>(
            ApiRoutes.ReportDataByReportId.replace(
                '{id}',
                MyDocumentsTypesEnum.Snapshot.Value.toString()
            )
                .replace('{getLiveData}', getLiveData.toString())
                .replace('{endDate?}', endDate.toString())
        );
    }

    getNoteTargets(notetypeId: number, caseLocalCounselGuid?: string, strategyTypeId?: number) {
        let url = ApiRoutes.NoteTargets.replace('{notetypeId}', notetypeId.toString());

        const queryStringParams: string[] = [];
        if (caseLocalCounselGuid) queryStringParams.push('localCounsel=' + caseLocalCounselGuid);
        if (strategyTypeId) queryStringParams.push('strategyTypeId=' + strategyTypeId);
        if (queryStringParams.length > 0) url += '?' + queryStringParams.join('&');
        return this.get<IUserModel[]>(url);
    }

    markAlertAsRead(id: string, alertType?: AlertTypesEnum, alertSubType?: AlertSubTypesEnum) {
        let url = ApiRoutes.MarkAlertAsReadForUser.replace('{id}', id);
        const queryStringParams: string[] = [];

        if (alertType) queryStringParams.push('alertTypes=' + alertType);
        if (alertSubType) queryStringParams.push('alertSubTypes=' + alertSubType);
        if (queryStringParams.length > 0) url += '?' + queryStringParams.join('&');

        return this.put<IResponse>(url);
    }

    getNoteDetails(id: string) {
        const url = ApiRoutes.NoteDetail.replace('{id}', id);
        return this.get<INoteModel>(url);
    }

    deleteFavorite(id: string) {
        const url = ApiRoutes.FavoriteItem.replace('{id}', id);
        return this.delete<IResponse>(url);
    }

    getFavorites(typeId: number, def?: boolean) {
        let url = ApiRoutes.Favorites;
        const queryStringParams: string[] = [];
        if (typeId) queryStringParams.push('typeId=' + typeId);
        if (def) queryStringParams.push('def=true');
        if (queryStringParams.length > 0) url += '?' + queryStringParams.join('&');
        return this.get<IFavoriteModel[]>(url);
    }

    addFavorite(model: IFavoriteModel) {
        const body: IFavoriteModel = model;
        return this.post<IResponseModel<string>>(ApiRoutes.Favorites, { body });
    }

    getUserUnreadQueryExports() {
        return this.get<IQueryExportCountModel[]>(ApiRoutes.UnreadQueryExports);
    }

    getUserReportStatus() {
        return this.get<IResponseModel<boolean>>(ApiRoutes.ReportStatus);
    }

    getWeather(params: ICoordinates) {
        return this.get<IResponseModel<any>>(
            ApiRoutes.CurrentWeather + '?' + transformRequestOptions(params)
        );
    }

    // EXPERTS

    getExperts() {
        return this.get<IExpertListModel[]>(ApiRoutes.GetExperts);
    }

    getExpertById(expertId: string) {
        return this.get<IExpertDetailModel>(ApiRoutes.GetExpert.replace('{id}', expertId));
    }

    isExpertNameExists(expertLastName: string, expertId: string) {
        return this.get<boolean>(
            ApiRoutes.ExpertNameExists.replace('{lastName}', expertLastName) + '?id=' + expertId
        );
    }

    createExpert(model: IExpertCreateModel) {
        return this.post<IExpertModel>(ApiRoutes.CreateExpert, { body: model });
    }

    updateExpert(model: IExpertCreateModel) {
        return this.put<IResponseModel<IExpertModel>>(ApiRoutes.UpdateExpert, { body: model });
    }

    deleteExpert(expertGuid: string) {
        return this.delete<IResponse>(ApiRoutes.DeleteExpert.replace('{id}', expertGuid));
    }

    // CASE EXPERTS

    getCaseExpertById(caseExpertId: string) {
        return this.get<ICaseExpertViewDetailModel>(
            ApiRoutes.GetCaseExpert.replace('{id}', caseExpertId)
        );
    }

    createCaseExpert(caseExpert: ICreateCaseExpertModel) {
        return this.post<ICaseExpertViewDetailModel>(ApiRoutes.CreateCaseExpert, {
            body: caseExpert,
        });
    }

    updateCaseExpert(caseExpert: IUpdateCaseExpertModel) {
        return this.put<ICaseExpertViewDetailModel>(ApiRoutes.UpdateCaseExpert, {
            body: caseExpert,
        });
    }

    deleteCaseExpert(caseExpertGuid: string) {
        return this.delete<IResponse>(ApiRoutes.DeleteCaseExpert.replace('{id}', caseExpertGuid));
    }

    saveCollaborationReport(saveModel: IReportSaveModel) {
        const body: IReportSaveModel = saveModel;
        return this.post<IReportModel>(ApiRoutes.CollaborationReportSave, { body });
    }

    getCollaborationReport(reportGuid: string) {
        return this.get<IReportModel>(
            ApiRoutes.CollaborationReportGetComprehensive.replace(
                '{collaborationReportId}',
                reportGuid
            )
        );
    }

    getCollaborationReportHistory(reportGuid: string) {
        return this.get<IUserAuditModel[]>(
            ApiRoutes.CollaborationReportHistory.replace('{collaborationReportId}', reportGuid)
        );
    }

    deleteCollaborationReport(collaborationReportId: string) {
        return this.delete<IResponse>(
            ApiRoutes.CollaborationReportDelete.replace(
                '{collaborationReportId}',
                collaborationReportId
            )
        );
    }

    closeAlert(model: IUpdateAlertUserModel) {
        return this.put<unknown>(ApiRoutes.CloseAct, { body: model });
    }

    getEntityConversations(entityTypeId: number, entityGuid: string) {
        const url = ApiRoutes.EntityConversationGetAll.replace(
            '{entityTypeId}',
            entityTypeId.toString()
        ).replace('{entityGuid}', entityGuid.toString());

        return this.get<IEntityConversationModel[]>(url);
    }

    createEntityConversation(newConvo: IEntityConversationCreateModel) {
        return this.post<IEntityConversationModel>(ApiRoutes.EntityConversationCreate, {
            body: newConvo,
        });
    }

    obtainEntityLease(entityTypeId: number, entityGuid: string, durationInMs: number) {
        const url = ApiRoutes.EntityLeaseObtain.replace('{entityId}', entityGuid).replace(
            '{entityTypeId}',
            entityTypeId.toString()
        );
        return this.put<IObtainEntityLeaseResponse>(url, { body: { milliseconds: durationInMs } });
    }

    releaseEntityLease(entityLeaseGuid: string) {
        const url = ApiRoutes.EntityLeaseRelease.replace('{entityLeaseGuid}', entityLeaseGuid);

        return this.put<boolean>(url);
    }

    pingEntityLease(entityLeaseGuid: string) {
        const url = ApiRoutes.EntityLeasePing.replace('{entityLeaseGuid}', entityLeaseGuid);

        return this.put<IResponse>(url);
    }

    getIsEntityLeaseAvailable(entityTypeId: number, entityGuid: string) {
        const url = ApiRoutes.EntityLeaseIsAvailable.replace('{entityId}', entityGuid).replace(
            '{entityTypeId}',
            entityTypeId.toString()
        );
        return this.get<IEntityLease>(url);
    }

    getStaticDataDate(documentTypeId: number) {
        const url = ApiRoutes.StaticReportDataDate.replace(
            '{documentTypeId}',
            documentTypeId.toString()
        );
        return this.get<string>(url);
    }
}

export default CheckmateApiClient;

export const transformRequestOptions = (params: any) => {
    let options: any[] = [];
    for (const key in params) {
        if (typeof params[key] !== 'object' && params[key] !== undefined) {
            options.push(`${key}=${encodeURIComponent(params[key])}`);
        } else if (Array.isArray(params[key])) {
            const arrayOptions = params[key]
                ?.filter((arrayItem: any) => arrayItem !== null && arrayItem !== undefined)
                .map((el: any) => `${key}=${encodeURIComponent(el?.value || el)}`);
            options = options.concat(arrayOptions);
        } else if (params[key] !== undefined) {
            const value = params[key];
            if (value) {
                options.push(`${key}=${encodeURIComponent(value?.value || value)}`);
            }
        }
    }
    return options.join('&');
};
