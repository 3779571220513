import { Modal, ModalDialog, ModalDialogProps, Stack } from 'react-bootstrap';

import Draggable from 'react-draggable';
import { ReactNode } from 'react';

interface IDialogProps {
    body?: string;
    cancelText?: string;
    closeButtonClassName?: string;
    confirmButtonClassName?: string;
    confirmText?: string;
    dialogClassName?: string;
    disableActions?: boolean;
    draggable?: boolean;
    dragHandleClassName?: string;
    handleClose: () => void;
    handleConfirm?: () => void;
    isShowingModal: boolean;
    leftFooterComponent?: ReactNode;
    resizable?: boolean;
    showBackdrop?: boolean;
    size?: 'sm' | 'lg' | 'xl' | undefined;
    title?: string;
}

export function DraggableModalDialog(props: ModalDialogProps) {
    return (
        <Draggable handle=".modal-header">
            <ModalDialog {...props} />
        </Draggable>
    );
}

export function DraggableHandle(props: { children: JSX.Element; enabled?: boolean }) {
    if (!props.enabled) return <>{props.children}</>;

    return (
        <div
            className="drag-handle"
            title="Click to Drag"
            style={{
                cursor: 'grab',
            }}
        >
            {props.children}
        </div>
    );
}

export function CheckmateDialog(props: IDialogProps) {
    return (
        <Modal
            dialogAs={props.draggable ? DraggableModalDialog : undefined}
            centered
            show={props.isShowingModal}
            dialogClassName={props.dialogClassName}
            size={props.size}
            onHide={props.handleClose}
            backdrop={props.showBackdrop ?? false}
        >
            {props.title ? (
                <DraggableHandle enabled={props.draggable}>
                    <Modal.Header>
                        <Modal.Title>{props.title}</Modal.Title>
                        {props.draggable && <i className="fa-regular fa-grip-dots-vertical" />}
                    </Modal.Header>
                </DraggableHandle>
            ) : null}
            <Modal.Body>
                {props.body?.split('\n').map((text: string, i: number) => {
                    return (
                        <p key={i}>
                            {text}
                            <br />
                        </p>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Stack
                    direction="horizontal"
                    style={{
                        justifyContent: props.leftFooterComponent ? 'space-between' : 'flex-end',
                        width: '100%',
                    }}
                >
                    {props.leftFooterComponent && <>{props.leftFooterComponent}</>}
                    <div>
                        {props.handleConfirm && (
                            <button
                                className={props.confirmButtonClassName}
                                disabled={props.disableActions}
                                onClick={props.handleConfirm}
                            >
                                {props.confirmText ? props.confirmText : 'OK'}
                            </button>
                        )}
                        {props.handleClose && (
                            <button
                                className={props.closeButtonClassName}
                                disabled={props.disableActions}
                                onClick={props.handleClose}
                            >
                                {props.cancelText ? props.cancelText : 'Cancel'}
                            </button>
                        )}
                    </div>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}
