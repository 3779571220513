import CheckmateSelect from '../CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';

export interface IYesNoSelectorProps {
    disabled?: boolean;
    multi?: boolean;
    onChange: (optionalBool: boolean | undefined) => void;
    placeholder?: string;
    value: boolean | undefined;
    validationError?: string | undefined;
}

export function YesNoSelector(props: IYesNoSelectorProps) {
    const handleChange = (selectedOption: any) => {
        const optionalBool =
            selectedOption?.value === 'true'
                ? true
                : selectedOption?.value === 'false'
                ? false
                : undefined;

        props.onChange(optionalBool);
    };

    return (
        <div className="col-sm-12">
            <CheckmateSelect
                options={CheckmateSelectHelper.getBooleanOptions()}
                isDisabled={props.disabled}
                value={CheckmateSelectHelper.getBooleanValue(props.value)}
                onChange={handleChange}
                placeholder={props.placeholder ?? '-- Select --'}
            />
            <span className="text-danger">{props.validationError}</span>
        </div>
    );
}
