import {
    AlertSubTypesEnum,
    AlertTypesEnum,
    ApplicationHelpType,
    CaseInventoryByStateDisplayMode,
    DataScopesEnum,
    DatabaseAppSettingsEnum,
    EntityTypes,
    MyDocumentsTypesEnum,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    PlaintiffsFirmDisplayMode,
    ReportCaseDetailsLastColumn,
    ReportStatusTypesEnum,
    ReportUserTypesEnum,
    TimelineReportSections,
    TimelineReportStatusEnum,
    TimelineReportTypeEnum,
    UserRightsEnum,
    WeeklyRecapReportSectionList,
} from '../../../../utilities/Constants';
import { CommentsDialog, ISectionCommentEntry } from './CommentsDialog';
import {
    IKeyValuePairModel,
    ILookupModel,
    IMultiSelectOptions,
} from '../../../../interfaces/ILookup';
import { IOpenChatActModel, SignalRMethods } from '../../../../utilities/SignalR';
import {
    IReportBaseModel,
    IReportModel,
    IReportSaveModel,
    IReportUserModel,
    IUpdateAlertUserModel,
} from '../../../../interfaces/Report/IReport';
import {
    IReportCaseDetailModel,
    IReportNoteBaseModel,
} from '../../../../interfaces/Report/IReportCaseDetailModel';
import {
    IReportSectionDetailModel,
    ITimelineReportInputModel,
} from '../../../../interfaces/Report/ITimelineReport';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageActionsType, WeeklyRecapPageActions } from './WeeklyRecapPageActions';
import React, { useEffect, useRef, useState } from 'react';
import usePerformanceNavigation, {
    EntryTypeEnum,
} from '../../../../hooks/usePerformanceNavigation';

import Authorization from '../../../../stores/Authorization';
import { CaseEasyUpdate } from '../../../case/CaseEasyUpdate';
import CheckmateApiClient from '../../../../services/apiClient';
import { CheckmateDialog } from '../../../../components/shared/dialog';
import CheckmateSelectHelper from '../../../../utilities/CheckmateSelectHelper';
import Common from '../../../../stores/Common';
import { DisplayMessages } from '../../../../utilities/DisplayMessages';
import { DocumentTitle } from '../../../../components/shared/DocumentTitle';
import { Help } from '../../../../components/shared/Help';
import { HistoryModal } from './HistoryModal';
import { IEntityLease } from '../../../../interfaces/IEntityLease';
import { INoteModel } from '../../../../interfaces/INote';
import { IUserModel } from '../../../../interfaces/IUser';
import { IValidation } from '../../../../interfaces/IError';
import { IWeeklyRecapModel } from '../../../../interfaces/Report/ITimelineReport';
import { IndustryNewsSection } from './sections/IndustryNewsSection';
import { Loader } from '../../../../components/shared/Loader';
import { LoadingButton } from '../../../../shared/buttons/LoadingButton';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { NoteEditor } from '../../../../components/notes/NoteEditor';
import NoteHelper from '../../../../utilities/NoteHelper';
import { ReportChatModal } from '../../../../components/weekly-recap/ReportChatModal';
import { ReportSettingsModal } from '../../../../components/weekly-recap/ReportSettingsModal';
import { SaveReportModal } from '../../../../components/weekly-recap/SaveReportModal';
import { SectionComment } from './sections/SectionComment';
import { SectionHeader } from './sections/SectionHeader';
import { Stack } from 'react-bootstrap';
import { TextWithLineBreaks } from '../../../../components/shared/TextWithLineBreaks';
import UIHelper from '../../../../utilities/UIHelper';
import { UnsavedChangesBlocker } from '../../../../shared/UnsavedChangesBlocker';
import { UpcomingDepositionsSection } from './sections/UpcomingDepositionsSection';
import ValidateUtils from '../../../../shared/validations';
import _ from 'lodash';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { useCloseAlertMutation } from '../../../../components/weekly-recap/useCloseAlertMutation';
import { useGetDbAppSettings } from '../../../../shared/react-query-hooks/useGetDbAppSettings';
import { useGetIsEntityLeaseAvailable } from '../../../../shared/react-query-hooks/userGetIsEntityLeaseAvailable';
import { useGetNoteCategoryTypes } from '../../../../shared/react-query-hooks/useGetNoteCategoryTypes';
import { useLoading } from '../../../../contexts/LoadingContext';
import { useObtainEntityLeaseMutation } from '../../../../shared/react-query-hooks/mutations/useObtainEntityLeaseMutation';
import { usePingExistingLeaseMutation } from '../../../../components/weekly-recap/usePingExistingLeaseMutation';
import { useReleaseEntityLeaseMutation } from '../../../../shared/react-query-hooks/mutations/useReleaseEntityLeaseMutation';
import { useSignalRContext } from '../../../../contexts/SignalRContext';
import { useZoneUsers } from '../../../../shared/react-query-hooks/useZoneUsers';

const _apiClient = new CheckmateApiClient();

const LeaseDuration = 60 * 60 * 8 * 1000;
const PingLeasefrequencyMs = 30 * 1000;

enum DisplayMode {
    Create,
    Readonly,
    Edit,
    Preview,
}

export interface IWeeklyRecapState {
    inputModel: ITimelineReportInputModel;
    reportData?: IWeeklyRecapModel;
    selectAllSections: TimelineReportSections[];
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];
    noteEditAttemptedGuidList: string[];
    openNoteEditor?: boolean;
    currentNote: INoteModel;
    showCommentsModal?: boolean;
    currentCommentSection: number;
    plaintiffsFirmDisplayMode: IMultiSelectOptions;
    reportCaseDetailsLastColumn: IMultiSelectOptions;
    caseInventoryByStateDisplayMode: IMultiSelectOptions;
    showClearSettingssConfirmModal?: boolean;
    sectionToBeRemoved?: TimelineReportSections;
    collaborationReport: IReportModel;
    originalReportState?: {
        collaborationReport: IReportModel;
        inputModel: ITimelineReportInputModel;
    };
}

enum UnsavedChangesReason {
    Cancel,
    Refresh,
}

export const WeeklyRecap = (props: { user: IUserModel }) => {
    const { guid: reportGuid } = useParams();
    const loading = useLoading();
    const navigate = useNavigate();
    const { alertHub } = useSignalRContext();
    const closeChatActMutation = useCloseAlertMutation();
    const navigationType = usePerformanceNavigation();
    const pingMutation = usePingExistingLeaseMutation();

    const {
        data: reportUsers,
        isLoading: reportUsersLoading,
        isError: reportUsersIsError,
    } = useZoneUsers(DataScopesEnum.National.Value);

    const {
        data: noteCategoryTypes,
        isLoading: noteCategoryTypesLoading,
        isError: noteCategoryTypesIsError,
    } = useGetNoteCategoryTypes();

    const obtainEntityLeaseMutation = useObtainEntityLeaseMutation();
    const releaseEntityLeaseMutation = useReleaseEntityLeaseMutation();
    const getIsEntityLeaseAvailable = useGetIsEntityLeaseAvailable(
        EntityTypes.Report,
        reportGuid,
        !!navigationType && !!reportGuid
    );
    const appSettings = useGetDbAppSettings();

    const getDefaultReportInputModel = () => {
        const inputModel: ITimelineReportInputModel = {
            type: TimelineReportTypeEnum.Weekly,
            entityGuid: reportGuid,
            reportSectionDetails: [],
        };

        WeeklyRecapReportSectionList.map((section: ILookupModel) => {
            const sectionDetail: IReportSectionDetailModel = {
                section: section.id,
                sectionName: section.name ?? '',
            };
            inputModel.reportSectionDetails!.push(sectionDetail);
        });

        inputModel.additionalSettings = {
            plaintiffsFirmDisplayMode: PlaintiffsFirmDisplayMode.Hierarchy,
            reportCaseDetailsLastColumn: ReportCaseDetailsLastColumn.ProductPremises,
            reportCaseDetailsLastColumnDisplayName: 'Product/Premises',
        };

        return inputModel;
    };

    const [validation, setValidation] = useState<IValidation>({});
    const [isPending, setIsPending] = useState<boolean>(false);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [showChatModal, setShowChatModal] = useState<boolean>(false);
    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
    const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
    const [forceHideEditButton, setForceHideEditButton] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<DisplayMode>(
        !reportGuid ? DisplayMode.Create : DisplayMode.Readonly
    );
    const [showUnsavedChangesWarningDialog, setShowUnsavedChangesWarningDialog] =
        useState<UnsavedChangesReason | null>(null);
    const [existingLease, setExistingLease] = useState<IEntityLease | undefined>();
    const existingLeaseRef = useRef<IEntityLease | undefined>(); // used to store the existingLease so it's available when unmounting
    const [showExistingLeaseDialog, setShowExistingLeaseDialog] = useState<boolean>(false);
    const [showFailedToObtainLeaseDialog, setShowFailedToObtainLeaseDialog] =
        useState<IEntityLease>();
    const [showEditingInAnotherWindowDialog, setShowEditingInAnotherWindowDialog] =
        useState<boolean>(false);
    const [showForceReleaseSuccessModal, setShowForceReleaseSuccessModal] =
        useState<boolean>(false);
    const [showForceReleaseConfirmation, setShowForceReleaseConfirmation] =
        useState<boolean>(false);

    const [state, setState] = useState<IWeeklyRecapState>({
        selectAllSections: [],
        inputModel: getDefaultReportInputModel(),
        easyUpdateMode: false,
        easyUpdateAttemptedCaseList: [],
        noteEditAttemptedGuidList: [],
        currentNote: {
            status: { id: NoteStatusTypes.Open },
            purpose: { id: NotePurposeTypesEnum.Info },
        },
        currentCommentSection: 0,
        plaintiffsFirmDisplayMode: {
            label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
            value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
            id: PlaintiffsFirmDisplayMode.Hierarchy,
        },
        reportCaseDetailsLastColumn: {
            label: 'Product/Premises',
            value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
            id: ReportCaseDetailsLastColumn.ProductPremises,
        },
        caseInventoryByStateDisplayMode: {
            label: 'Top 5 States',
            value: CaseInventoryByStateDisplayMode.Top5.toString(),
            id: CaseInventoryByStateDisplayMode.Top5,
        },
        collaborationReport: {
            baseDetails: { type: { id: MyDocumentsTypesEnum.WeeklyRecap.Value } },
        },
    });

    const reportDifferences =
        !_.isEmpty(state.originalReportState?.collaborationReport) &&
        !_.isEmpty(state.collaborationReport)
            ? UIHelper.difference(
                  _.cloneDeep(state.originalReportState?.collaborationReport),
                  _.cloneDeep(state.collaborationReport)
              )
            : {};

    const inputModelDifferences = UIHelper.difference(
        _.cloneDeep(state.originalReportState?.inputModel),
        _.cloneDeep(state.inputModel)
    );

    const hasUnsavedChanges = !_.isEmpty(reportDifferences) || !_.isEmpty(inputModelDifferences);

    const loadInitialData = async () => {
        const validation: IValidation = {};
        setShowUnsavedChangesWarningDialog(null);
        setDisplayMode(DisplayMode.Readonly);

        setValidation({});
        setIsPending(true);

        if (noteCategoryTypesIsError || reportUsersIsError) {
            validation.model = [DisplayMessages.UnexpectedError];
        }

        let collaborationReport = cloneDeep(state.collaborationReport);
        let inputModelCopy = cloneDeep(state.inputModel);
        let reportDataCopy = cloneDeep(state.reportData);

        if (reportGuid) {
            const serverCollabReport = await getCollaborationReport(reportGuid);
            if (serverCollabReport?.baseDetails.guid) {
                collaborationReport = serverCollabReport;

                if (serverCollabReport.reportData?.reportParameterJson) {
                    inputModelCopy = JSON.parse(serverCollabReport.reportData?.reportParameterJson);
                    inputModelCopy.entityGuid = reportGuid;
                    //todo:aj - verify after feature is implemented.
                    inputModelCopy.saveReport = true;
                    if (serverCollabReport.reportData?.data) {
                        reportDataCopy = JSON.parse(serverCollabReport.reportData?.data);
                    }
                    setShowSettingsModal(false);
                }
            }
        }

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            collaborationReport,
            inputModel: inputModelCopy,
            reportData: reportDataCopy,
            originalReportState: {
                collaborationReport,
                inputModel: inputModelCopy,
            },
        }));

        setIsPending(false);
    };

    const getCollaborationReport = async (reportGuid: string) => {
        const response = await _apiClient.getCollaborationReport(reportGuid);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }

        if (response.httpResponse.status === 403) {
            navigate(LocalRoutes.AccessDenied);
            return;
        }

        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        }

        return response.payload;
    };

    const getReportData = async () => {
        const validation: IValidation = {};

        if (!state.inputModel.startDate || !state.inputModel.endDate) {
            validation.settings = ['Enter Report Parameters'];
            setValidation(validation);
            return;
        }

        if (Common.dateDiffInDays(state.inputModel.endDate, state.inputModel.startDate) < 0) {
            validation.settings = ['From Date must be before To Date'];
            setValidation(validation);
            return;
        }

        if ((state.inputModel.reportSectionDetails?.length ?? 0) === 0) {
            validation.settings = ['Select at least 1 section'];
            setValidation(validation);
            return;
        }

        if (
            state.inputModel.reportSectionDetails?.some((element) =>
                [
                    TimelineReportSections.RiskIssues,
                    TimelineReportSections.SignificantActivity,
                    TimelineReportSections.UpcomingTrialDates,
                    TimelineReportSections.TenderedCases,
                ].includes(element.section)
            ) &&
            (!state.inputModel.additionalSettings ||
                !state.inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName)
        ) {
            validation.settings = ['Case Details - Last Column Header Name is required'];
            setValidation(validation);
            return;
        }

        setValidation(validation);
        setIsPending(true);

        const response = await _apiClient.getWeeklyRecapReportData(state.inputModel);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        }
        if (response.payload) {
            setShowSettingsModal(false);
            setIsPending(false);
            setState((prevState: IWeeklyRecapState) => ({
                ...prevState,
                reportData: response.payload,
            }));
        }
    };

    const getCheckedValue = (
        section: TimelineReportSections,
        entityType: EntityTypes,
        guid: string
    ) => {
        const inputModelCopy = cloneDeep(state.inputModel);
        if (inputModelCopy.reportSectionDetails) {
            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                switch (entityType) {
                    case EntityTypes.Case:
                        if (sectionMatch.caseGuids)
                            return sectionMatch.caseGuids.some((x) => x === guid);
                        break;
                    case EntityTypes.Note:
                        if (sectionMatch.noteGuids)
                            return sectionMatch.noteGuids.some((x) => x === guid);
                        break;
                    default:
                }
            }
        }
        return false;
    };

    const isSectionRowsSelectAllChecked = (section: TimelineReportSections) => {
        return state.selectAllSections.some((x) => x === section);
    };

    const getParentCaseGuidByNoteGuid = (
        noteGuid: string,
        section: TimelineReportSections
    ): string => {
        if (state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < state.reportData.initialCaseEval.length; i++) {
                        if (
                            state.reportData.initialCaseEval[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return state.reportData.initialCaseEval[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < state.reportData.tenderedCases.length; i++) {
                        if (
                            state.reportData.tenderedCases[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return state.reportData.tenderedCases[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < state.reportData.riskIssues.length; i++) {
                        if (
                            state.reportData.riskIssues[i].notes.filter((x) => x.guid === noteGuid)
                                .length > 0
                        )
                            return state.reportData.riskIssues[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < state.reportData.significantActivities.length; i++) {
                        if (
                            state.reportData.significantActivities[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return state.reportData.significantActivities[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < state.reportData.clientRequests.length; i++) {
                        if (
                            state.reportData.clientRequests[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return state.reportData.significantActivities[i].caseGuid;
                    }
                    break;
                default:
                    return '';
            }
        }

        return '';
    };

    const getNoteGuidsByCaseAndSection = (
        caseGuid: string,
        section: TimelineReportSections
    ): string[] => {
        const returnValue: string[] = [];

        if (state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    {
                        const initialCaseEvalMatch = state.reportData.initialCaseEval.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (initialCaseEvalMatch && initialCaseEvalMatch.notes)
                            initialCaseEvalMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    {
                        const tenderedcaseMatch = state.reportData.tenderedCases.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (tenderedcaseMatch && tenderedcaseMatch.notes)
                            tenderedcaseMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    {
                        const riskIssuesMatch = state.reportData.riskIssues.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (riskIssuesMatch && riskIssuesMatch.notes)
                            riskIssuesMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    {
                        const sigActMatch = state.reportData.significantActivities.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (sigActMatch && sigActMatch.notes)
                            sigActMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    {
                        const reqForAuthMatch = state.reportData.clientRequests.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (reqForAuthMatch && reqForAuthMatch.notes)
                            reqForAuthMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                default:
            }
        }
        return returnValue;
    };

    const getAllGuidsBySectionForExport = (section: TimelineReportSections) => {
        const result: IReportSectionDetailModel = {
            section: section,
            sectionName: section.toString(),
            caseGuids: [],
            noteGuids: [],
        };
        if (state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < state.reportData.initialCaseEval.length; i++) {
                        if (result.caseGuids) {
                            result.caseGuids.push(state.reportData.initialCaseEval[i].caseGuid);
                            if (state.reportData.initialCaseEval[i].notes) {
                                for (
                                    let j = 0;
                                    j < state.reportData.initialCaseEval[i].notes.length;
                                    j++
                                ) {
                                    if (result.noteGuids)
                                        result.noteGuids.push(
                                            state.reportData.initialCaseEval[i].notes[j].guid
                                        );
                                }
                            }
                        }
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < state.reportData.tenderedCases.length; i++) {
                        result.caseGuids?.push(state.reportData.tenderedCases[i].caseGuid);
                        if (state.reportData.tenderedCases[i].notes) {
                            for (
                                let j = 0;
                                j < state.reportData.tenderedCases[i].notes.length;
                                j++
                            ) {
                                result.noteGuids?.push(
                                    state.reportData.tenderedCases[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < state.reportData.riskIssues.length; i++) {
                        result.caseGuids?.push(state.reportData.riskIssues[i].caseGuid);
                        if (state.reportData.riskIssues[i].notes) {
                            for (let j = 0; j < state.reportData.riskIssues[i].notes.length; j++) {
                                result.noteGuids?.push(
                                    state.reportData.riskIssues[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < state.reportData.significantActivities.length; i++) {
                        result.caseGuids?.push(state.reportData.significantActivities[i].caseGuid);
                        if (state.reportData.significantActivities[i].notes) {
                            for (
                                let j = 0;
                                j < state.reportData.significantActivities[i].notes.length;
                                j++
                            ) {
                                result.noteGuids?.push(
                                    state.reportData.significantActivities[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < state.reportData.clientRequests.length; i++) {
                        result.caseGuids?.push(state.reportData.clientRequests[i].caseGuid);
                        if (state.reportData.clientRequests[i].notes) {
                            for (
                                let j = 0;
                                j < state.reportData.clientRequests[i].notes.length;
                                j++
                            ) {
                                result.noteGuids?.push(
                                    state.reportData.clientRequests[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.ZoneNotes:
                    for (let i = 0; i < state.reportData.zoneNotes.length; i++) {
                        result.noteGuids?.push(state.reportData.zoneNotes[i].noteGuid);
                    }
                    break;
                default:
            }
        }
        return result;
    };

    const saveReportAs = () => {
        setShowSaveModal(true);
    };

    const executeSaveReport = async (
        report: IReportBaseModel,
        collaborators: IReportUserModel[],
        readers: IReportUserModel[],
        redirectOnSave?: boolean
    ) => {
        setValidation({});
        setIsPending(true);

        return saveReport(report, collaborators, readers, redirectOnSave);
    };

    const saveReport = async (
        report: IReportBaseModel,
        collaborators: IReportUserModel[],
        readers: IReportUserModel[],
        redirectOnSave?: boolean
    ) => {
        const request: IReportSaveModel = {
            baseDetails: report,
            entityLeaseGuid: existingLease?.guid,
            users: collaborators,
            readers,
            reportParameterJson: JSON.stringify(state.inputModel),
        };

        const response = await _apiClient.saveCollaborationReport(request);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        } else {
            setValidation({});
            setIsPending(false);
            const newReportState = response.payload!;
            setState((prev) => ({
                ...prev,
                collaborationReport: newReportState,
                originalReportState: {
                    collaborationReport: newReportState,
                    inputModel: prev.inputModel,
                },
            }));
            setDisplayMode(DisplayMode.Readonly);

            if (redirectOnSave) {
                navigate(
                    LocalRoutes.WeeklyRecap.replace(
                        ':guid',
                        response.payload?.baseDetails.guid ?? ''
                    )
                );
            }

            return response.payload;
        }
    };

    const getLeaseDuration = (): number => {
        const leaseDurationSetting = appSettings.data?.find(
            (setting) => setting.name === DatabaseAppSettingsEnum.CollabReportLeaseDuration
        )?.value;

        const durationAsNumber = parseInt(leaseDurationSetting ?? '');

        if (isNaN(durationAsNumber)) {
            return LeaseDuration;
        }

        return durationAsNumber;
    };

    const getLeasePingFrequency = (): number => {
        const pingFrequencySetting = appSettings.data?.find(
            (setting) => setting.name === DatabaseAppSettingsEnum.EntityLeasePingIntervalInSeconds
        )?.value;

        const frequencyAsNumber = parseInt(pingFrequencySetting ?? '');

        if (isNaN(frequencyAsNumber)) {
            return PingLeasefrequencyMs;
        }

        return frequencyAsNumber * 1000; // convert to MS
    };

    const buildReportSection = (item: IReportCaseDetailModel, section: TimelineReportSections) => {
        const rows: any[] = [];

        let noteColSpan = 5;
        switch (section) {
            case TimelineReportSections.InitialCaseEval:
            case TimelineReportSections.TenderedCases:
                noteColSpan = 6;
                break;
            default:
        }

        const noteRows = buildNotesRows(item, section, noteColSpan);
        const hasNotes = noteRows.some((row) => !!row);
        const borderClass = classNames({ 'border-0 ': hasNotes });

        rows.push(
            <tr key={'section_' + section.toString()}>
                {!isPreviewMode && (
                    <td width={25} className={borderClass}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.caseGuid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(e, section, EntityTypes.Case);
                            }}
                            checked={getCheckedValue(section, EntityTypes.Case, item.caseGuid)}
                            disabled={isReportReadonly}
                        />
                    </td>
                )}
                <td className={borderClass + 'col-sm-2'}>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                    {item.priorServed && <>*</>}
                </td>
                {section === TimelineReportSections.InitialCaseEval && (
                    <td className={borderClass + 'col-sm-1'}>
                        {Common.dateFormat(item.serviceDate)}
                    </td>
                )}
                {section === TimelineReportSections.TenderedCases && (
                    <td className={borderClass + 'col-sm-1'}>
                        {item.status!.displayName ? item.status!.displayName : item.status!.name!}
                    </td>
                )}
                <td
                    className={
                        borderClass +
                        (section === TimelineReportSections.InitialCaseEval ||
                        section === TimelineReportSections.TenderedCases
                            ? 'col-sm-2'
                            : 'col-sm-3')
                    }
                >
                    {item.jurisdiction}
                </td>
                <td className={borderClass + 'col-sm-3'}>{item.plaintiffsFirmDisplayName}</td>
                <td
                    className={
                        borderClass +
                        (section === TimelineReportSections.InitialCaseEval
                            ? 'col-sm-1'
                            : 'col-sm-2')
                    }
                >
                    {item.diagnosis}
                </td>
                <td className={borderClass}>{item.additionalCaseIdentifier}</td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, props.user) && (
                    <td width={45} className={borderClass + 'center'}>
                        {!isReportReadonly && !isPreviewMode && (
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    handleEasyUpdate(item.caseGuid);
                                }}
                            >
                                <i
                                    className={`fal fa-edit ${
                                        state.easyUpdateAttemptedCaseList.filter(
                                            (x) => x === item.caseGuid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        );

        if (item.notes) {
            noteRows.forEach((row: any) => {
                rows.push(row);
            });
        }

        return rows;
    };

    const buildNotesRows = (
        caseItem: IReportCaseDetailModel,
        section: TimelineReportSections,
        colSpan: number
    ) => {
        const rows = caseItem.notes.map((note, index) => {
            const currentNote: INoteModel = {
                guid: note.guid,
                type: { id: NoteTypes.CaseNote },
                status: { id: 0 },
                purpose: { id: 0 },
                caseGuid: caseItem.caseGuid,
                caseName: caseItem.caseName,
                qScore: caseItem.qScore,
                qProjection: caseItem.qProjection,
            };

            const showEditColumn =
                Authorization.userHasRight(UserRightsEnum.EditCaseNote, props.user) &&
                (Authorization.getUserDefaultOrganizationDataScope(props.user) !==
                    DataScopesEnum.LocalBasic.Value ||
                    note.allowLocalEdit);

            const isChecked = getCheckedValue(section, EntityTypes.Note, note.guid);

            if (!isChecked && isPreviewMode) {
                return null;
            }

            return (
                <tr key={index}>
                    {!isPreviewMode && (
                        <td className={caseItem.notes.length - 1 === index ? '' : 'border-0'}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name={note.guid}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleCheckedChange(e, section, EntityTypes.Note);
                                }}
                                checked={isChecked}
                                disabled={isReportReadonly}
                            />
                        </td>
                    )}
                    <td
                        className={caseItem.notes.length - 1 === index ? '' : 'border-0'}
                        colSpan={colSpan}
                    >
                        {note.content && <TextWithLineBreaks text={note.content} />}
                    </td>
                    {showEditColumn && (
                        <td
                            width={45}
                            className={
                                (caseItem.notes.length - 1 === index ? '' : 'border-0 ') + 'center'
                            }
                        >
                            {!isReportReadonly && (
                                <button
                                    className="btn btn-no-bg"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        handleNoteEdit(e, currentNote);
                                    }}
                                >
                                    <i
                                        className={`fal fa-pen ${
                                            state.noteEditAttemptedGuidList.filter(
                                                (x) => x === currentNote.guid
                                            ).length > 0
                                                ? 'color-gray'
                                                : 'color-black'
                                        } `}
                                    />
                                </button>
                            )}
                        </td>
                    )}
                </tr>
            );
        });

        return rows;
    };

    const getNoteCategoryTypesByNoteType = () => {
        const noteTypeId = state.currentNote.type ? state.currentNote.type.id : 0;
        return noteCategoryTypes?.filter((x) => x.noteType.id === noteTypeId) ?? [];
    };

    const handleCommentsButtonClick = (section: number) => {
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            showCommentsModal: true,
            currentCommentSection: section,
        }));
    };

    const handleSelectAllCheckedChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const selectAllSectionsCopy = cloneDeep(state.selectAllSections);
        const inputModelCopy = cloneDeep(state.inputModel);

        if (!inputModelCopy.reportSectionDetails) inputModelCopy.reportSectionDetails = [];

        let sectionMatch = inputModelCopy.reportSectionDetails.find((x) => x.section === section);
        if (!sectionMatch) {
            sectionMatch = { section: section, sectionName: section.toString() };
            inputModelCopy.reportSectionDetails.push(sectionMatch);
        }

        if (event.target.checked) {
            if (!selectAllSectionsCopy.some((x) => x === section)) {
                selectAllSectionsCopy.push(section);
            }
            const allGuids = getAllGuidsBySectionForExport(section);
            sectionMatch.caseGuids = allGuids.caseGuids;
            sectionMatch.noteGuids = allGuids.noteGuids;
        } else {
            if (selectAllSectionsCopy.findIndex((x) => x === section) > -1)
                selectAllSectionsCopy.splice(
                    selectAllSectionsCopy.findIndex((x) => x === section),
                    1
                );
            sectionMatch.caseGuids = [];
            sectionMatch.noteGuids = [];
        }

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            selectAllSections: selectAllSectionsCopy,
            inputModel: inputModelCopy,
        }));
    };

    const handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => {
        const inputModelCopy = cloneDeep(state.inputModel);
        const selectAllSectionsCopy = cloneDeep(state.selectAllSections);

        if (event.target.checked) {
            if (!inputModelCopy.reportSectionDetails) inputModelCopy.reportSectionDetails = [];

            if (inputModelCopy.reportSectionDetails.findIndex((x) => x.section === section) === -1)
                inputModelCopy.reportSectionDetails.push({
                    section: section,
                    sectionName: section.toString(),
                });

            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (!sectionMatch) return;
            if (!sectionMatch.caseGuids) sectionMatch.caseGuids = [];
            if (!sectionMatch.noteGuids) sectionMatch.noteGuids = [];

            switch (entityType) {
                case EntityTypes.Case:
                    {
                        if (
                            sectionMatch.caseGuids?.findIndex((x) => x === event.target.name) === -1
                        )
                            sectionMatch.caseGuids?.push(event.target.name);

                        const noteGuidsToAddToSelectedList = getNoteGuidsByCaseAndSection(
                            event.target.name,
                            section
                        );
                        if (noteGuidsToAddToSelectedList.length > 0) {
                            for (let i = 0; i < noteGuidsToAddToSelectedList.length; i++) {
                                if (
                                    sectionMatch.noteGuids?.findIndex(
                                        (x) => x === noteGuidsToAddToSelectedList[i]
                                    ) === -1
                                )
                                    sectionMatch.noteGuids?.push(noteGuidsToAddToSelectedList[i]);
                            }
                        }
                    }
                    break;
                case EntityTypes.Note:
                    if (sectionMatch.noteGuids?.findIndex((x) => x === event.target.name) === -1) {
                        sectionMatch.noteGuids?.push(event.target.name);

                        // Find the parent;  if it is not checked, check it ( for all sections except other client requests)
                        const parentCaseGuid = getParentCaseGuidByNoteGuid(
                            event.target.name,
                            section
                        );
                        if (
                            parentCaseGuid &&
                            sectionMatch.caseGuids?.findIndex((x) => x === parentCaseGuid) === -1
                        )
                            sectionMatch.caseGuids?.push(parentCaseGuid);
                    }
                    break;
                default:
            }
        } else {
            if (selectAllSectionsCopy.findIndex((x) => x === section) > -1)
                selectAllSectionsCopy.splice(
                    selectAllSectionsCopy.findIndex((x) => x === section),
                    1
                );

            if (inputModelCopy.reportSectionDetails && inputModelCopy.reportSectionDetails) {
                const sectionMatch = inputModelCopy.reportSectionDetails.find(
                    (x) => x.section === section
                );
                if (sectionMatch) {
                    switch (entityType) {
                        case EntityTypes.Case:
                            if (
                                sectionMatch.caseGuids &&
                                sectionMatch.caseGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            ) {
                                sectionMatch.caseGuids.splice(
                                    sectionMatch.caseGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                                if (sectionMatch.noteGuids) {
                                    const noteGuidsToRemoveFromSelectedList =
                                        getNoteGuidsByCaseAndSection(event.target.name, section);
                                    if (noteGuidsToRemoveFromSelectedList.length > 0) {
                                        for (
                                            let i = 0;
                                            i < noteGuidsToRemoveFromSelectedList.length;
                                            i++
                                        ) {
                                            if (
                                                sectionMatch.noteGuids?.some(
                                                    (x) =>
                                                        x === noteGuidsToRemoveFromSelectedList[i]
                                                )
                                            )
                                                sectionMatch.noteGuids?.splice(
                                                    sectionMatch.noteGuids?.findIndex(
                                                        (x) =>
                                                            x ===
                                                            noteGuidsToRemoveFromSelectedList[i]
                                                    ),
                                                    1
                                                );
                                        }
                                    }
                                }
                            }
                            break;
                        case EntityTypes.Note:
                            if (sectionMatch.noteGuids?.some((x) => x === event.target.name))
                                sectionMatch.noteGuids.splice(
                                    sectionMatch.noteGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                            break;
                        default:
                    }
                }
            }
        }

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            inputModel: inputModelCopy,
            selectAllSections: selectAllSectionsCopy,
        }));
    };

    const handleCloseChatActs = () => {
        const collaborationReportCopy = cloneDeep(state.collaborationReport);
        collaborationReportCopy.baseDetails.loggedInUserHasOpenChatAct = false;
        setState((prev) => ({ ...prev, collaborationReport: collaborationReportCopy }));
    };

    const handleNoteEdit = (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => {
        if (!noteItem.guid) return;

        const noteEditAttemptedGuidList = state.noteEditAttemptedGuidList;
        if (noteEditAttemptedGuidList.filter((x) => x === noteItem.guid).length === 0)
            noteEditAttemptedGuidList.push(noteItem.guid);
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            openNoteEditor: true,
            currentNote: noteItem,
        }));
    };

    const handleSaveNoteComplete = () => {
        const localValidation = { ...validation };
        localValidation.categories = [];
        localValidation.content = [];
        setValidation(localValidation);

        getReportData();

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
        }));
    };

    const handleCancelAddEditNote = () => {
        const localValidation = { ...validation };
        localValidation.categories = [];
        localValidation.content = [];
        setValidation(localValidation);
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
        }));
    };

    const easyUpdateDone = async () => {
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
        }));

        getReportData();
    };

    const getCommentText = (reportSection: number) => {
        if (state.inputModel?.reportSectionDetails) {
            const sectionMatch = state.inputModel.reportSectionDetails.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    const removeSection = (section: TimelineReportSections) => {
        const inputModelCopy = cloneDeep(state.inputModel);
        const selectAllSectionsCopy = cloneDeep(state.selectAllSections);

        if (!inputModelCopy.reportSectionDetails) return;

        inputModelCopy.reportSectionDetails!.splice(
            inputModelCopy.reportSectionDetails.findIndex((x) => x.section === section),
            1
        );

        if (selectAllSectionsCopy.findIndex((x) => x === section) >= 0)
            selectAllSectionsCopy.splice(
                selectAllSectionsCopy.findIndex((x) => x === section),
                1
            );

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            inputModel: inputModelCopy,
            selectAllSections: selectAllSectionsCopy,
            sectionToBeRemoved: undefined,
        }));
    };

    const forceCancelLease = (leaseGuid: string, onSuccess?: () => void, onError?: () => void) => {
        if (!reportGuid) return;

        releaseEntityLeaseMutation
            .mutateAsync(leaseGuid)
            .then(() => {
                existingLeaseRef.current = undefined;
                setExistingLease(undefined);
                setForceHideEditButton(false);
                setShowEditingInAnotherWindowDialog(false);

                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error: Error) => {
                console.error(error);

                if (onError) {
                    onError();
                }
                setValidation({ entityRelease: [error.message ?? 'Failed to release entity'] });
            });
    };

    const cancelLease = (readonly: boolean) => {
        if (readonly || !existingLeaseRef.current?.guid) return;

        forceCancelLease(existingLeaseRef.current?.guid);
    };

    const buildRequestForAuthRows = (item: IReportCaseDetailModel) => {
        const rows: any[] = [];
        rows.push(
            <tr key={item.caseGuid}>
                {!isPreviewMode && (
                    <td width={15}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.caseGuid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(
                                    e,
                                    TimelineReportSections.RequestForAuth,
                                    EntityTypes.Case
                                );
                            }}
                            checked={getCheckedValue(
                                TimelineReportSections.RequestForAuth,
                                EntityTypes.Case,
                                item.caseGuid
                            )}
                            disabled={isReportReadonly}
                        />
                    </td>
                )}
                <td>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                </td>
                <td className="col-sm-1">
                    {item.authorityRequested
                        ? Common.formatCurrency(item.authorityRequested.toString())
                        : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.authorityRequestedDate)}</td>
                <td className="col-sm-2">{item.jurisdiction}</td>
                <td className="col-sm-2">{item.plaintiffsFirmDisplayName}</td>
                <td>{item.diagnosis}</td>
                <td className="col-sm-1">{Common.dateFormat(item.trialDate)}</td>
                <td className="col-sm-1">
                    {item.demand ? Common.formatCurrency(item.demand.toString()) : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.clientExposureStartDate)}</td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, props.user) && (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        {' '}
                        {!isReportReadonly && (
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    handleEasyUpdate(item.caseGuid);
                                }}
                            >
                                <i
                                    className={`fal fa-edit ${
                                        state.easyUpdateAttemptedCaseList.filter(
                                            (x) => x === item.caseGuid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        );

        if (item.notes)
            buildNotesRows(item, TimelineReportSections.RequestForAuth, 8).map((row: any) => {
                rows.push(row);
            });
        return rows;
    };

    const handleSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const inputModelCopy = cloneDeep(state.inputModel);
        const selectAllSectionsCopy = cloneDeep(state.selectAllSections);

        if (!inputModelCopy.reportSectionDetails) {
            inputModelCopy.reportSectionDetails = [];
        }

        if (event.target.checked) {
            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                sectionMatch.excludeInReport = false;
            } else {
                inputModelCopy.reportSectionDetails!.push({
                    section,
                    sectionName: section.toString(),
                });
            }

            setState((prevState: IWeeklyRecapState) => ({
                ...prevState,
                inputModel: inputModelCopy,
                selectAllSections: selectAllSectionsCopy,
            }));
        } else {
            const sectionToRemove = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionToRemove && sectionToRemove.comment)
                setState((prevState: IWeeklyRecapState) => ({
                    ...prevState,
                    sectionToBeRemoved: section,
                }));
            else removeSection(section);
        }
    };

    const handleConfirmRemoveSection = () => {
        if (state.sectionToBeRemoved) removeSection(state.sectionToBeRemoved);
    };

    const handleEasyUpdate = (caseGuid: string, noteGuid?: string) => {
        const easyUpdateAttemptedCaseList = cloneDeep(state.easyUpdateAttemptedCaseList);
        const easyUpdateAttemptedNoteGuidList = cloneDeep(state.noteEditAttemptedGuidList);
        if (easyUpdateAttemptedCaseList.filter((x) => x === caseGuid).length === 0)
            easyUpdateAttemptedCaseList.push(caseGuid);
        if (noteGuid) easyUpdateAttemptedNoteGuidList.push(noteGuid);
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
            noteEditAttemptedGuidList: easyUpdateAttemptedNoteGuidList,
        }));
    };

    const handleClearSettings = () => {
        setValidation({});
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            reportData: undefined,
            inputModel: getDefaultReportInputModel(),
            plaintiffsFirmDisplayMode: {
                label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
                value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
                id: PlaintiffsFirmDisplayMode.Hierarchy,
            },
            reportCaseDetailsLastColumn: {
                label: 'Product/Premises',
                value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
                id: ReportCaseDetailsLastColumn.ProductPremises,
            },
            caseInventoryByStateDisplayMode: {
                label: 'Top 5 States',
                value: CaseInventoryByStateDisplayMode.Top5.toString(),
                id: CaseInventoryByStateDisplayMode.Top5,
            },
            showCommentsModal: false,
            showClearSettingssConfirmModal: false,
        }));
    };

    const handleSettingsModalSubmit = () => {
        getReportData();
    };

    const handleSaveReportModalClose = (readonly: boolean) => {
        cancelLease(readonly);
        setShowSaveModal(false);
    };

    const handleCommentsDialogSaveClick = (fromDialog: ISectionCommentEntry[]) => {
        const reportSectionDetailsCopy = cloneDeep(state.inputModel.reportSectionDetails ?? []);

        reportSectionDetailsCopy?.forEach((details) => {
            const found = fromDialog.find(
                (searchDetail) => searchDetail.section === details.section
            );

            if (found) {
                details.comment = found.comment;
            }
        });

        const toAdd = fromDialog.filter((details) => {
            return !reportSectionDetailsCopy.map((fd) => fd.section).includes(details.section);
        });

        toAdd.forEach((item) => {
            const { section, comment } = item;
            reportSectionDetailsCopy.push({
                section,
                comment,
                excludeInReport: false,
            } as IReportSectionDetailModel);
        });

        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            showCommentsModal: false,
            inputModel: {
                ...prevState.inputModel,
                reportSectionDetails: reportSectionDetailsCopy,
            },
        }));
    };

    const handleSaveClick = async () => {
        executeSaveReport(
            state.collaborationReport.baseDetails,
            state.collaborationReport.collaborators ?? [],
            [],
            false
        )
            .then((payload) => {
                if (payload) {
                    setState((prev) => ({
                        ...prev,
                        collaborationReport: payload,
                        originalReportState: {
                            collaborationReport: payload,
                            inputModel: prev.inputModel,
                        },
                    }));
                    setDisplayMode(DisplayMode.Readonly);
                }
            })
            .catch((error) => {
                console.error(error);
                setValidation((prev) => ({ ...prev, model: [error] }));
            });
    };

    const handleContinueAsReadonlyClick = () => {
        setDisplayMode(DisplayMode.Readonly);
        setForceHideEditButton(true);
        setShowEditingInAnotherWindowDialog(false);
    };

    const handleContinueInEditModeClick = () => {
        handleEditClick(() => {
            setShowEditingInAnotherWindowDialog(false);
        });
    };

    const handleCancelClick = () => {
        if (hasUnsavedChanges) {
            setShowUnsavedChangesWarningDialog(UnsavedChangesReason.Cancel);
        } else {
            setDisplayMode(DisplayMode.Readonly);
        }

        if (existingLeaseRef.current?.guid) {
            cancelLease(false);
        }
    };

    const handlePreviewClick = () => {
        setDisplayMode(() => {
            if (isPreviewMode) {
                return DisplayMode.Readonly;
            } else {
                return DisplayMode.Preview;
            }
        });
    };

    const handleHistoryClick = () => setShowHistoryModal(true);

    const handleChatClick = () => setShowChatModal(true);

    const handleCommentsClick = () =>
        setState((prevState: IWeeklyRecapState) => ({
            ...prevState,
            currentCommentSection: 0,
            showCommentsModal: true,
        }));

    const handleCloseReviewActClick = async () => {
        if (!state.collaborationReport.baseDetails.alertGuid) {
            setValidation((prev) => ({ ...prev, model: ['Failed to close Review Act'] }));
        }

        const model = {
            alertGuid: state.collaborationReport.baseDetails.alertGuid,
            alertType: AlertTypesEnum.Act,
            alertSubType: AlertSubTypesEnum.Review,
            parentEntityGuid: reportGuid,
            parentEntityType: EntityTypes.Report,
        } as IUpdateAlertUserModel;

        loading.showLoading();

        await closeChatActMutation
            .mutateAsync(model)
            .then(() => {
                const collaborationReportCopy = cloneDeep(state.collaborationReport);
                collaborationReportCopy.baseDetails.loggedInUserHasOpenReviewAct = false;
                setState((prev) => ({ ...prev, collaborationReport: collaborationReportCopy }));
            })
            .catch(() => {
                setValidation((prev) => ({ ...prev, act: ['Failed to close Review Act'] }));
            })
            .finally(() => {
                loading.hideLoading();
            });
    };

    const handleCollabClick = () => setShowSaveModal(true);

    const handleEditClick = async (onSuccess?: () => void) => {
        if (!reportGuid) return;

        const durationInMs = getLeaseDuration();

        obtainEntityLeaseMutation
            .mutateAsync({
                entityTypeId: EntityTypes.Report,
                entityGuid: reportGuid!,
                durationInMs,
            })
            .then((result) => {
                if (result?.success) {
                    setDisplayMode(DisplayMode.Edit);
                    setExistingLease(result.currentLease);

                    if (typeof onSuccess === 'function') {
                        onSuccess();
                    }
                } else {
                    setShowFailedToObtainLeaseDialog(result?.currentLease);
                }
            })
            .catch(() => {
                // todo: handle error
            });
    };

    const handleSettingsClick = () => setShowSettingsModal(true);

    const handleExportError = () => {
        // todo: any error handling
    };

    const previewFilter = (guid: string, section: number, entityType: EntityTypes) => {
        if (isPreviewMode) {
            return getCheckedValue(section, entityType, guid);
        }

        return true;
    };

    const bindSignalrEvents = () => {
        const handleSendNewOpenChatActReceived = (model: IOpenChatActModel) => {
            // console.log(`******* ${SignalRMethods.SendOpenChatActStatus} *******`, model);

            if (model.entityGuid === reportGuid) {
                const collaborationReportCopy = cloneDeep(state.collaborationReport);
                collaborationReportCopy.baseDetails.loggedInUserHasOpenChatAct =
                    model.loggedInUserHasOpenChatAct;
                setState((prev) => ({ ...prev, collaborationReport: collaborationReportCopy }));
            }
        };

        if (alertHub !== undefined) {
            alertHub?.on(SignalRMethods.SendOpenChatActStatus, handleSendNewOpenChatActReceived);
            console.log(
                `SignalR - Alert Hub => Listening for ${SignalRMethods.SendOpenChatActStatus}`
            );
        }
    };

    const unbindSignalrEvents = () => {
        alertHub?.off(SignalRMethods.SendOpenChatActStatus);
        console.log(
            `SignalR - Alert Hub => Stopped listening for ${SignalRMethods.SendOpenChatActStatus}`
        );
    };

    useEffect(() => {
        if (reportGuid) {
            loadInitialData();
        } else {
            setShowSettingsModal(true);
            setState((prevState) => ({
                ...prevState,
                collaborationReport: {
                    baseDetails: { type: { id: MyDocumentsTypesEnum.WeeklyRecap.Value } },
                },
                reportData: undefined,
            }));
        }
    }, [reportGuid]);

    useEffect(() => {
        bindSignalrEvents();
        return unbindSignalrEvents;
    }, [alertHub]);

    useEffect(() => {
        if (!!reportGuid && !!navigationType) {
            getIsEntityLeaseAvailable.refetch().then((response) => {
                const lease = response.data;
                if (response.isError) {
                    setShowExistingLeaseDialog(true);
                    setExistingLease(undefined);
                } else if (lease) {
                    setExistingLease(lease);
                    const lesseeIsCurrentUser = lease?.lesseeUser?.guid !== props.user.guid;

                    if (lesseeIsCurrentUser) {
                        setShowExistingLeaseDialog(true);
                    } else {
                        if (
                            navigationType === EntryTypeEnum.navigate ||
                            navigationType === EntryTypeEnum.reload
                        ) {
                            setShowEditingInAnotherWindowDialog(true);
                        }
                    }
                    setForceHideEditButton(true);
                } else {
                    setShowExistingLeaseDialog(false);
                    setForceHideEditButton(false);
                }
            });
        }
    }, [reportGuid, navigationType]);

    useEffect(() => {
        existingLeaseRef.current = existingLease;
    }, [JSON.stringify(existingLease)]);

    useEffect(() => {
        return () => {
            if (existingLeaseRef.current) {
                const belongsToCurrentUser =
                    existingLeaseRef.current?.lesseeUser?.guid === props.user.guid;

                if (belongsToCurrentUser && reportGuid) {
                    releaseEntityLeaseMutation
                        .mutateAsync(existingLeaseRef.current?.guid)
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        };
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const pingMs = getLeasePingFrequency();

        (function loop() {
            if (existingLeaseRef.current?.guid && reportGuid && displayMode === DisplayMode.Edit) {
                timeoutId = setTimeout(() => {
                    pingMutation.mutateAsync(existingLeaseRef.current!.guid);

                    loop();
                }, pingMs);
            }
        })();

        return () => clearTimeout(timeoutId);
    }, [existingLeaseRef.current?.guid, displayMode]);

    const isDraftStatus =
        state.collaborationReport.baseDetails.status?.id === ReportStatusTypesEnum.Draft;
    const isPublished =
        state.collaborationReport.baseDetails.status?.id === ReportStatusTypesEnum.Published;
    const isFinalDraftStatus =
        state.collaborationReport?.baseDetails.status?.id === ReportStatusTypesEnum.FinalDraft;

    const isPreviewMode = displayMode === DisplayMode.Preview || isPublished;
    const isReadonlyMode = displayMode === DisplayMode.Readonly || isPreviewMode;

    const isReportReadonly = (reportGuid && !isDraftStatus) || isReadonlyMode || isPreviewMode;

    const displaySection = (sectionNumber: number) =>
        (isPreviewMode &&
            state.inputModel.reportSectionDetails?.some((x) => x.section === sectionNumber)) ||
        !isPreviewMode;

    const lesseeUser = existingLease?.lesseeUser;
    const lesseeProfile = lesseeUser?.profile;

    const lastModifiedByCurrentUser =
        state.collaborationReport.baseDetails.modifiedBy?.guid === props.user.guid;

    const saveModalIsReadonly =
        isReadonlyMode || (!!reportGuid && !isDraftStatus && !lastModifiedByCurrentUser);

    const userIsReader = !!state.collaborationReport?.readers?.find(
        (reader) => reader.userGuid === props.user.guid
    );

    const leaseDialogBody = `This report is currently being edited by ${lesseeProfile?.firstName} ${lesseeProfile?.lastName}.\nThe report will be opened in read-only mode.`;

    const readers =
        reportUsers
            ?.filter((reportUser) =>
                state.collaborationReport?.readers
                    ?.map((reader) => reader.userGuid)
                    .includes(reportUser.guid)
            )
            .filter((reportUser) => reportUser.guid !== props.user.guid) ?? [];

    const collaborators =
        reportUsers
            ?.filter((reportUser) =>
                state.collaborationReport?.collaborators
                    ?.map((collaborator) => collaborator.userGuid)
                    .includes(reportUser.guid)
            )
            .filter((reportUser) => reportUser.guid !== props.user.guid) ?? [];

    const chatActionTargets =
        state.collaborationReport.baseDetails.status?.id === ReportStatusTypesEnum.Published
            ? collaborators.concat(readers)
            : collaborators;

    const enableCollab =
        appSettings.data?.find(
            (setting) => setting.name === DatabaseAppSettingsEnum.DisableCollabReportFeature
        )?.value !== 'true' && !appSettings.isLoading;

    const getPageActionsDisplayType = () => {
        if (displayMode === DisplayMode.Create && state.reportData) {
            return PageActionsType.WeeklyRecapCreateActions;
        }

        if (displayMode === DisplayMode.Edit && state.collaborationReport) {
            return PageActionsType.WeeklyRecapNonReadOnlyActions;
        }

        if (!state.reportData) {
            return PageActionsType.SettingsOnly;
        }

        return PageActionsType.WeeklyRecapReadOnlyActions;
    };

    if (userIsReader && !isPublished) {
        navigate(LocalRoutes.AccessDenied);
    }

    const handleForceReleaseClick = () => {
        if (!existingLease) return;

        setValidation({ entityRelease: [] });
        setShowForceReleaseConfirmation(true);
    };

    const handleForceReleaseConfirm = () => {
        if (!existingLease) return;

        const handleForceReleaseSuccess = () => {
            setShowForceReleaseConfirmation(false);
            setShowForceReleaseSuccessModal(true);
        };

        const handleForceReleaseError = () => {
            setShowForceReleaseConfirmation(false);
        };

        forceCancelLease(existingLease.guid, handleForceReleaseSuccess, handleForceReleaseError);
    };

    if (
        isPending ||
        reportUsersLoading ||
        noteCategoryTypesLoading ||
        getIsEntityLeaseAvailable.isLoading ||
        appSettings.isLoading
    ) {
        return <Loader />;
    }

    if (state.openNoteEditor)
        return (
            <NoteEditor
                authorizedToEdit={
                    Authorization.userHasRight(UserRightsEnum.EditCaseNote, props.user) &&
                    (Authorization.getUserDefaultOrganizationDataScope(props.user) !==
                        DataScopesEnum.LocalBasic.Value ||
                        state.currentNote.allowLocalEdit)
                }
                caseLocalCounselGuid={state.currentNote.localCounselOrganizationGuid}
                caseTitle={NoteHelper.getCaseTitle(state.currentNote)}
                currentNote={state.currentNote}
                defaultAlertTarget={
                    Authorization.isLocalScope(props.user)
                        ? state.currentNote.caseManager
                        : undefined
                }
                defaultWatchTargets={
                    Authorization.isLocalScope(props.user) && state.currentNote.alternateCaseManager
                        ? [state.currentNote.alternateCaseManager]
                        : undefined
                }
                handleCancel={handleCancelAddEditNote}
                handleSaveComplete={handleSaveNoteComplete}
                helpText={[]}
                noteCategoryTypes={getNoteCategoryTypesByNoteType()}
                user={props.user}
            />
        );

    return (
        <>
            <UnsavedChangesBlocker when={hasUnsavedChanges} />
            {Authorization.userHasRight(UserRightsEnum.EasyUpdate, props.user) && (
                <CaseEasyUpdate
                    user={props.user}
                    caseGuid={state.easyUpdateCaseGuid}
                    onComplete={easyUpdateDone}
                    onCancel={() => {
                        setState((prevState: IWeeklyRecapState) => ({
                            ...prevState,
                            easyUpdateMode: false,
                            easyUpdateCaseGuid: undefined,
                        }));
                    }}
                    hidden={!state.easyUpdateMode}
                />
            )}
            {!state.easyUpdateMode && (
                <>
                    <DocumentTitle title="Weekly Recap" />
                    <Stack
                        direction="vertical"
                        style={{
                            height: 'calc(100%)',
                            marginRight: '-12px',
                            overflow: 'hidden',
                        }}
                    >
                        <div className="row pb-3">
                            <Stack
                                direction="horizontal"
                                style={{
                                    alignItems: 'flex-start',
                                    flexWrap: 'wrap',
                                    paddingRight: '35px',
                                }}
                            >
                                <PageHeader
                                    actionsComponent={
                                        <WeeklyRecapPageActions
                                            enableCollab={enableCollab}
                                            forceHideEditButton={forceHideEditButton}
                                            inputModel={state.inputModel}
                                            hasChatActs={
                                                state.collaborationReport?.baseDetails
                                                    .loggedInUserHasOpenChatAct
                                            }
                                            hasCollabAct={
                                                state.collaborationReport?.baseDetails
                                                    .loggedInUserHasOpenCollabAct
                                            }
                                            hasReviewAct={
                                                state.collaborationReport?.baseDetails
                                                    .loggedInUserHasOpenReviewAct
                                            }
                                            hasUnsavedChanges={hasUnsavedChanges}
                                            isClosingReviewAct={closeChatActMutation.isLoading}
                                            isPreviewMode={isPreviewMode}
                                            onCancelClick={handleCancelClick}
                                            onChatClick={handleChatClick}
                                            onCloseReviewActClick={handleCloseReviewActClick}
                                            onCollabClick={handleCollabClick}
                                            onCommentsClick={handleCommentsClick}
                                            onEditClick={handleEditClick}
                                            onExportError={handleExportError}
                                            onHistoryClick={handleHistoryClick}
                                            onPreviewClick={handlePreviewClick}
                                            onRefreshClick={getReportData}
                                            onSaveAsClick={saveReportAs}
                                            onSaveClick={handleSaveClick}
                                            onSettingsClick={handleSettingsClick}
                                            pageActionsDisplayType={getPageActionsDisplayType()}
                                            reportStatus={
                                                state.collaborationReport?.baseDetails.status
                                            }
                                            userIsReader={userIsReader}
                                        />
                                    }
                                    inputModel={state.inputModel}
                                    isCollabReport={!!reportGuid}
                                    isInADraftStatus={isDraftStatus || isFinalDraftStatus}
                                    report={state.collaborationReport.baseDetails}
                                />
                            </Stack>
                        </div>

                        {validation.model && (
                            <div className="mb-1">
                                <span className="text-danger">{validation.model}</span>
                            </div>
                        )}

                        {state.reportData && (
                            <div style={{ overflow: 'auto', paddingRight: '12px' }}>
                                {displaySection(TimelineReportSections.CaseInventory) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInventory
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.CaseInventory}
                                            title="Weekly Change in Case Volume"
                                        />

                                        <table className="table thick-outer-border">
                                            <thead>
                                                <tr>
                                                    {state.reportData.inventory.map(
                                                        (item: IKeyValuePairModel) => {
                                                            return (
                                                                <th
                                                                    className="align-top text-center"
                                                                    key={item.key}
                                                                >
                                                                    {item
                                                                        .key!.split('<br/>')
                                                                        .map((x, key) => {
                                                                            return (
                                                                                <span key={key}>
                                                                                    {x}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                        })}
                                                                </th>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="align-top text-center">
                                                <tr>
                                                    {state.reportData.inventory.map(
                                                        (item: IKeyValuePairModel) => {
                                                            return (
                                                                <td key={item.key}>{item.value}</td>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.CaseInventory
                                            )}
                                            rowCount={state.reportData.inventory.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.CaseInfoNew) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section === TimelineReportSections.CaseInfoNew
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.CaseInfoNew}
                                            title="New Cases Recap"
                                        />

                                        {state.reportData.caseInfo.some(
                                            (x) => x.status.id === TimelineReportStatusEnum.New
                                        ) && (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */ className="border-0" />
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th>
                                                            {state.inputModel.additionalSettings
                                                                ? state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            props.user
                                                        ) && <th></th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.reportData.caseInfo
                                                        .filter(
                                                            (x) =>
                                                                x.status.id ===
                                                                TimelineReportStatusEnum.New
                                                        )
                                                        .map((item: IReportCaseDetailModel) => {
                                                            return (
                                                                <tr key={item.caseGuid}>
                                                                    <td
                                                                        width={25}
                                                                        className="border-0"
                                                                    />
                                                                    <td className="col-sm-2">
                                                                        <Link
                                                                            target="_blank"
                                                                            to={LocalRoutes.CaseFile.replace(
                                                                                ':guid',
                                                                                item.caseGuid
                                                                            )}
                                                                        >
                                                                            {item.caseName}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.jurisdiction}
                                                                    </td>
                                                                    <td className="col-sm-3">
                                                                        {
                                                                            item.plaintiffsFirmDisplayName
                                                                        }
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.diagnosis}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.additionalCaseIdentifier
                                                                        }
                                                                    </td>
                                                                    {Authorization.isAuthorizedToRoute(
                                                                        LocalRoutes.CaseEasyUpdate,
                                                                        props.user
                                                                    ) && (
                                                                        <td
                                                                            width={45}
                                                                            className={
                                                                                (item.notes.length >
                                                                                0
                                                                                    ? 'border-0 '
                                                                                    : '') + 'center'
                                                                            }
                                                                        >
                                                                            {!isReportReadonly && (
                                                                                <button
                                                                                    className="btn-no-bg"
                                                                                    onClick={() => {
                                                                                        handleEasyUpdate(
                                                                                            item.caseGuid
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className={`fal fa-edit ${
                                                                                            state.easyUpdateAttemptedCaseList.filter(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x ===
                                                                                                    item.caseGuid
                                                                                            )
                                                                                                .length >
                                                                                            0
                                                                                                ? 'color-gray'
                                                                                                : 'color-black'
                                                                                        } `}
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.CaseInfoNew
                                            )}
                                            rowCount={
                                                state.reportData.caseInfo.filter(
                                                    (x) =>
                                                        x.status.id === TimelineReportStatusEnum.New
                                                ).length
                                            }
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.CaseInfoDismissed) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInfoDismissed
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.CaseInfoDismissed}
                                            title="Dismissed Cases Recap"
                                        />

                                        {state.reportData.caseInfo.some(
                                            (x) =>
                                                x.status.id === TimelineReportStatusEnum.Dismissed
                                        ) && (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */ className="border-0" />
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th>
                                                            {state.inputModel.additionalSettings
                                                                ?.reportCaseDetailsLastColumnDisplayName ??
                                                                'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            props.user
                                                        ) && <th></th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.reportData.caseInfo
                                                        .filter(
                                                            (x) =>
                                                                x.status.id ===
                                                                TimelineReportStatusEnum.Dismissed
                                                        )
                                                        .map((item: IReportCaseDetailModel) => {
                                                            return (
                                                                <tr key={item.caseGuid}>
                                                                    <td
                                                                        width={25}
                                                                        className="border-0"
                                                                    />
                                                                    <td className="col-sm-2">
                                                                        <Link
                                                                            target="_blank"
                                                                            to={LocalRoutes.CaseFile.replace(
                                                                                ':guid',
                                                                                item.caseGuid
                                                                            )}
                                                                        >
                                                                            {item.caseName}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.jurisdiction}
                                                                    </td>
                                                                    <td className="col-sm-3">
                                                                        {
                                                                            item.plaintiffsFirmDisplayName
                                                                        }
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.diagnosis}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.additionalCaseIdentifier
                                                                        }
                                                                    </td>
                                                                    {Authorization.isAuthorizedToRoute(
                                                                        LocalRoutes.CaseEasyUpdate,
                                                                        props.user
                                                                    ) && (
                                                                        <td
                                                                            width={45}
                                                                            className={
                                                                                (item.notes.length >
                                                                                0
                                                                                    ? 'border-0 '
                                                                                    : '') + 'center'
                                                                            }
                                                                        >
                                                                            {!isReportReadonly && (
                                                                                <button
                                                                                    className="btn-no-bg"
                                                                                    onClick={() => {
                                                                                        handleEasyUpdate(
                                                                                            item.caseGuid
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className={`fal fa-edit ${
                                                                                            state.easyUpdateAttemptedCaseList.filter(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x ===
                                                                                                    item.caseGuid
                                                                                            )
                                                                                                .length >
                                                                                            0
                                                                                                ? 'color-gray'
                                                                                                : 'color-black'
                                                                                        } `}
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.CaseInfoDismissed
                                            )}
                                            rowCount={
                                                state.reportData.caseInfo.filter(
                                                    (x) =>
                                                        x.status.id ===
                                                        TimelineReportStatusEnum.Dismissed
                                                ).length
                                            }
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.CaseInfoSettled) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInfoSettled
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.CaseInfoSettled}
                                            title="Settled Cases Recap"
                                        />

                                        {state.reportData.caseInfo.some(
                                            (x) => x.status.id === TimelineReportStatusEnum.Settled
                                        ) && (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */ className="border-0" />
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th className="col-sm-1">Amount</th>
                                                        <th>Date</th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            props.user
                                                        ) && <th></th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.reportData.caseInfo
                                                        .filter(
                                                            (x) =>
                                                                x.status.id ===
                                                                TimelineReportStatusEnum.Settled
                                                        )
                                                        .map((item: IReportCaseDetailModel) => {
                                                            return (
                                                                <tr key={item.caseGuid}>
                                                                    <td
                                                                        width={25}
                                                                        className="border-0"
                                                                    />
                                                                    <td className="col-sm-2">
                                                                        <Link
                                                                            target="_blank"
                                                                            to={LocalRoutes.CaseFile.replace(
                                                                                ':guid',
                                                                                item.caseGuid
                                                                            )}
                                                                        >
                                                                            {item.caseName}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.jurisdiction}
                                                                    </td>
                                                                    <td className="col-sm-3">
                                                                        {
                                                                            item.plaintiffsFirmDisplayName
                                                                        }
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {item.diagnosis}
                                                                    </td>
                                                                    <td className="col-sm-1">
                                                                        {item.settlementAmount
                                                                            ? Common.formatCurrency(
                                                                                  item.settlementAmount.toString()
                                                                              )
                                                                            : ''}
                                                                    </td>
                                                                    <td>
                                                                        {Common.dateFormat(
                                                                            item.resolutionDate
                                                                        )}
                                                                    </td>
                                                                    {Authorization.isAuthorizedToRoute(
                                                                        LocalRoutes.CaseEasyUpdate,
                                                                        props.user
                                                                    ) && (
                                                                        <td
                                                                            width={45}
                                                                            className={
                                                                                (item.notes.length >
                                                                                0
                                                                                    ? 'border-0 '
                                                                                    : '') + 'center'
                                                                            }
                                                                        >
                                                                            {!isReportReadonly && (
                                                                                <button
                                                                                    className="btn-no-bg"
                                                                                    onClick={() => {
                                                                                        handleEasyUpdate(
                                                                                            item.caseGuid
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className={`fal fa-edit ${
                                                                                            state.easyUpdateAttemptedCaseList.filter(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x ===
                                                                                                    item.caseGuid
                                                                                            )
                                                                                                .length >
                                                                                            0
                                                                                                ? 'color-gray'
                                                                                                : 'color-black'
                                                                                        } `}
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.CaseInfoSettled
                                            )}
                                            rowCount={
                                                state.reportData.caseInfo.filter(
                                                    (x) =>
                                                        x.status.id ===
                                                        TimelineReportStatusEnum.Settled
                                                ).length
                                            }
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.InitialCaseEval) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.InitialCaseEval
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.InitialCaseEval}
                                            title="Initial Case Evaluations"
                                        />

                                        {state.reportData.initialCaseEval.length > 0 && (
                                            <div className="margin-left-20">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {!isPreviewMode && (
                                                                <th /* width={25} */>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="selectAll"
                                                                        checked={isSectionRowsSelectAllChecked(
                                                                            TimelineReportSections.InitialCaseEval
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            handleSelectAllCheckedChanged(
                                                                                e,
                                                                                TimelineReportSections.InitialCaseEval
                                                                            );
                                                                        }}
                                                                        disabled={isReportReadonly}
                                                                    />
                                                                </th>
                                                            )}
                                                            <th className="col-sm-2">Case Name</th>
                                                            <th className="col-sm-1">
                                                                Date Served
                                                            </th>
                                                            <th className="col-sm-3">
                                                                Jurisdiction
                                                            </th>
                                                            <th className="col-sm-3">
                                                                Plaintiffs Firm
                                                            </th>
                                                            <th className="col-sm-1">Disease</th>
                                                            <th>
                                                                {state.inputModel.additionalSettings
                                                                    ?.reportCaseDetailsLastColumnDisplayName ??
                                                                    'Last Column Header Name'}
                                                            </th>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.CaseEasyUpdate,
                                                                props.user
                                                            ) && <th></th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.reportData.initialCaseEval
                                                            .filter((item) =>
                                                                previewFilter(
                                                                    item.caseGuid,
                                                                    TimelineReportSections.InitialCaseEval,
                                                                    EntityTypes.Case
                                                                )
                                                            )
                                                            .map((item: IReportCaseDetailModel) => {
                                                                return buildReportSection(
                                                                    item,
                                                                    TimelineReportSections.InitialCaseEval
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                                {state.reportData.initialCaseEval.some(
                                                    (x) => x.priorServed
                                                ) && (
                                                    <span className="mb-2">
                                                        *Case received from client during this
                                                        reporting period but date served was prior
                                                        to this reporting period
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.InitialCaseEval
                                            )}
                                            rowCount={state.reportData.initialCaseEval.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.TenderedCases) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.TenderedCases
                                            )}
                                            helpComponent={
                                                <Help
                                                    type={ApplicationHelpType.Info}
                                                    additionalStyleClassNames="font-size-sm"
                                                    title="Tendered Cases"
                                                    helpText="Cases that are Tendered-Pending as of the last date of the reporting period will be displayed along with the most recent case note, Category = Case.  \n\nCases with a Tendered-Accepted date during the reporting period will be displayed along with the most recent case note, Category = Case."
                                                />
                                            }
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.TenderedCases}
                                            title="Tendered Cases"
                                        />

                                        {state.reportData.tenderedCases.length > 0 && (
                                            <div className="margin-left-20">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {!isPreviewMode && (
                                                                <th style={{ width: 25 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="selectAll"
                                                                        checked={isSectionRowsSelectAllChecked(
                                                                            TimelineReportSections.TenderedCases
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            handleSelectAllCheckedChanged(
                                                                                e,
                                                                                TimelineReportSections.TenderedCases
                                                                            );
                                                                        }}
                                                                        disabled={isReportReadonly}
                                                                    />
                                                                </th>
                                                            )}
                                                            <th className="col-sm-2">Case Name</th>
                                                            <th>Status</th>
                                                            <th className="col-sm-2">
                                                                Jurisdiction
                                                            </th>
                                                            <th className="col-sm-3">
                                                                Plaintiffs Firm
                                                            </th>
                                                            <th className="col-sm-2">Disease</th>
                                                            <th className="col-sm-2">
                                                                {state.inputModel.additionalSettings
                                                                    ?.reportCaseDetailsLastColumnDisplayName ??
                                                                    'Last Column Header Name'}
                                                            </th>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.CaseEasyUpdate,
                                                                props.user
                                                            ) && <th></th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.reportData.tenderedCases
                                                            .filter((item) =>
                                                                previewFilter(
                                                                    item.caseGuid,
                                                                    TimelineReportSections.TenderedCases,
                                                                    EntityTypes.Case
                                                                )
                                                            )
                                                            .map((item: IReportCaseDetailModel) => {
                                                                return buildReportSection(
                                                                    item,
                                                                    TimelineReportSections.TenderedCases
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.TenderedCases
                                            )}
                                            rowCount={state.reportData.tenderedCases.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.RequestForAuth) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.RequestForAuth
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.RequestForAuth}
                                            title="Outstanding Requests for Authority"
                                        />

                                        {state.reportData.clientRequests.length > 0 && (
                                            <div className="margin-left-20">
                                                <table className="table">
                                                    <thead>
                                                        <tr
                                                            style={{
                                                                verticalAlign: 'middle',
                                                            }}
                                                        >
                                                            {!isPreviewMode && (
                                                                <th>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="selectAll"
                                                                        checked={isSectionRowsSelectAllChecked(
                                                                            TimelineReportSections.RequestForAuth
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            handleSelectAllCheckedChanged(
                                                                                e,
                                                                                TimelineReportSections.RequestForAuth
                                                                            );
                                                                        }}
                                                                        disabled={isReportReadonly}
                                                                    />
                                                                </th>
                                                            )}
                                                            <th>Case</th>
                                                            <th className="col-sm-1">
                                                                Requested Authority
                                                            </th>
                                                            <th className="col-sm-1">
                                                                Date Requested
                                                            </th>
                                                            <th className="col-sm-2">
                                                                Jurisdiction
                                                            </th>
                                                            <th className="col-sm-2">
                                                                Plaintiffs Firm
                                                            </th>
                                                            <th className="col-sm-1">Disease</th>
                                                            <th className="col-sm-1">Trial Date</th>
                                                            <th className="col-sm-1">Demand</th>
                                                            <th className="col-sm-1">
                                                                Client Exposure Start Date
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.reportData.clientRequests
                                                            .filter((item) =>
                                                                previewFilter(
                                                                    item.caseGuid,
                                                                    TimelineReportSections.RequestForAuth,
                                                                    EntityTypes.Case
                                                                )
                                                            )
                                                            .map((item: IReportCaseDetailModel) => {
                                                                return buildRequestForAuthRows(
                                                                    item
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.RequestForAuth
                                            )}
                                            rowCount={state.reportData.clientRequests.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.RiskIssues) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section === TimelineReportSections.RiskIssues
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.RiskIssues}
                                            title="Risk Issues"
                                        />

                                        {state.reportData.riskIssues.length > 0 && (
                                            <div className="margin-left-20">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {!isPreviewMode && (
                                                                <th /* width={25} */>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="selectAll"
                                                                        checked={isSectionRowsSelectAllChecked(
                                                                            TimelineReportSections.RiskIssues
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            handleSelectAllCheckedChanged(
                                                                                e,
                                                                                TimelineReportSections.RiskIssues
                                                                            );
                                                                        }}
                                                                        disabled={isReportReadonly}
                                                                    />
                                                                </th>
                                                            )}
                                                            <th className="col-sm-2">Case Name</th>
                                                            <th className="col-sm-2">
                                                                Jurisdiction
                                                            </th>
                                                            <th className="col-sm-3">
                                                                Plaintiffs Firm
                                                            </th>
                                                            <th className="col-sm-2">Disease</th>
                                                            <th>
                                                                {state.inputModel.additionalSettings
                                                                    ?.reportCaseDetailsLastColumnDisplayName ??
                                                                    'Last Column Header Name'}
                                                            </th>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.CaseEasyUpdate,
                                                                props.user
                                                            ) && <th></th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.reportData.riskIssues
                                                            .filter((item) =>
                                                                previewFilter(
                                                                    item.caseGuid,
                                                                    TimelineReportSections.RiskIssues,
                                                                    EntityTypes.Case
                                                                )
                                                            )
                                                            .map((item: IReportCaseDetailModel) => {
                                                                return buildReportSection(
                                                                    item,
                                                                    TimelineReportSections.RiskIssues
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        <SectionComment
                                            text={getCommentText(TimelineReportSections.RiskIssues)}
                                            rowCount={state.reportData.riskIssues.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.SignificantActivity) && (
                                    <>
                                        <SectionHeader
                                            checked={state.inputModel.reportSectionDetails?.some(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.SignificantActivity
                                            )}
                                            isPreviewMode={isPreviewMode}
                                            onCommentsButtonClick={handleCommentsButtonClick}
                                            onSectionCheckedChange={handleSectionCheckedChange}
                                            readonly={isReportReadonly}
                                            section={TimelineReportSections.SignificantActivity}
                                            title="Significant Activity"
                                        />

                                        {state.reportData.significantActivities.length > 0 && (
                                            <div className="margin-left-20">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            {!isPreviewMode && (
                                                                <th /* width={25} */>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        name="selectAll"
                                                                        checked={isSectionRowsSelectAllChecked(
                                                                            TimelineReportSections.SignificantActivity
                                                                        )}
                                                                        onChange={(
                                                                            e: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            handleSelectAllCheckedChanged(
                                                                                e,
                                                                                TimelineReportSections.SignificantActivity
                                                                            );
                                                                        }}
                                                                        disabled={isReportReadonly}
                                                                    />
                                                                </th>
                                                            )}
                                                            <th className="col-sm-2">Case Name</th>
                                                            <th className="col-sm-2">
                                                                Jurisdiction
                                                            </th>
                                                            <th className="col-sm-2">
                                                                Plaintiffs Firm
                                                            </th>
                                                            <th className="col-sm-2">Disease</th>
                                                            <th>
                                                                {state.inputModel.additionalSettings
                                                                    ?.reportCaseDetailsLastColumnDisplayName ??
                                                                    'Last Column Header Name'}
                                                            </th>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.CaseEasyUpdate,
                                                                props.user
                                                            ) && <th></th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.reportData.significantActivities
                                                            .filter((item) =>
                                                                previewFilter(
                                                                    item.caseGuid,
                                                                    TimelineReportSections.SignificantActivity,
                                                                    EntityTypes.Case
                                                                )
                                                            )
                                                            .map((item: IReportCaseDetailModel) => {
                                                                return buildReportSection(
                                                                    item,
                                                                    TimelineReportSections.SignificantActivity
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        <SectionComment
                                            text={getCommentText(
                                                TimelineReportSections.SignificantActivity
                                            )}
                                            rowCount={state.reportData.significantActivities.length}
                                        />
                                    </>
                                )}

                                {displaySection(TimelineReportSections.Depositions) && (
                                    <UpcomingDepositionsSection
                                        data={state.reportData.depositions}
                                        checked={state.inputModel.reportSectionDetails?.some(
                                            (x) => x.section === TimelineReportSections.Depositions
                                        )}
                                        isPreviewMode={isPreviewMode}
                                        getCheckedValue={getCheckedValue}
                                        onCommentsButtonClick={handleCommentsButtonClick}
                                        onCheckedChange={handleCheckedChange}
                                        onEasyUpdate={handleEasyUpdate}
                                        onSectionCheckedChange={handleSectionCheckedChange}
                                        onSelectAllCheckedChanged={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleSelectAllCheckedChanged(
                                                e,
                                                TimelineReportSections.Depositions
                                            );
                                        }}
                                        previouslyViewedGuids={state.easyUpdateAttemptedCaseList}
                                        readonly={isReportReadonly}
                                        sectionComment={getCommentText(
                                            TimelineReportSections.Depositions
                                        )}
                                        user={props.user}
                                    />
                                )}

                                {displaySection(TimelineReportSections.ZoneNotes) && (
                                    <IndustryNewsSection
                                        data={state.reportData.zoneNotes.filter((item) =>
                                            previewFilter(
                                                item.noteGuid,
                                                TimelineReportSections.ZoneNotes,
                                                EntityTypes.Note
                                            )
                                        )}
                                        checked={state.inputModel.reportSectionDetails?.some(
                                            (x) => x.section === TimelineReportSections.ZoneNotes
                                        )}
                                        getCheckedValue={getCheckedValue}
                                        isPreviewMode={isPreviewMode}
                                        onCommentsButtonClick={handleCommentsButtonClick}
                                        onCheckedChange={handleCheckedChange}
                                        onNoteEdit={handleNoteEdit}
                                        onSectionCheckedChange={handleSectionCheckedChange}
                                        onSelectAllCheckedChanged={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            handleSelectAllCheckedChanged(
                                                e,
                                                TimelineReportSections.ZoneNotes
                                            );
                                        }}
                                        previouslyViewedGuids={state.noteEditAttemptedGuidList}
                                        readonly={isReportReadonly}
                                        sectionComment={getCommentText(
                                            TimelineReportSections.ZoneNotes
                                        )}
                                        selectAllChecked={isSectionRowsSelectAllChecked(
                                            TimelineReportSections.ZoneNotes
                                        )}
                                        user={props.user}
                                    />
                                )}
                            </div>
                        )}
                    </Stack>

                    {showSaveModal && (
                        <SaveReportModal
                            alertGuid={state.collaborationReport.baseDetails.alertGuid ?? ''}
                            availableUsers={CheckmateSelectHelper.getUserOptions(reportUsers ?? [])}
                            closeButtonText={
                                displayMode === DisplayMode.Create ? 'Cancel' : 'Close'
                            }
                            disableReaders={userIsReader}
                            executeSaveReport={executeSaveReport}
                            leaseDuration={getLeaseDuration()}
                            onClose={handleSaveReportModalClose}
                            onLeaseObtained={(lease) => {
                                setExistingLease(lease);
                                setForceHideEditButton(true);
                            }}
                            onFailedToObtainLease={(currentLease: IEntityLease) =>
                                setShowFailedToObtainLeaseDialog(currentLease)
                            }
                            open={showSaveModal}
                            readonly={saveModalIsReadonly}
                            report={state.collaborationReport.baseDetails}
                            reportCollaborators={
                                state.collaborationReport.collaborators ??
                                (reportGuid
                                    ? []
                                    : [
                                          {
                                              reportGuid,
                                              reportUserTypeId: ReportUserTypesEnum.Collaborator,
                                              userGuid: props.user.guid,
                                          } as IReportUserModel,
                                      ])
                            }
                            reportReaders={state.collaborationReport.readers ?? []}
                            showEditButton={!existingLease && !isPublished}
                            title={reportGuid ? 'Collab' : 'Save Report'}
                        />
                    )}

                    <ReportSettingsModal
                        disabled={!!reportGuid && !isDraftStatus}
                        inputModel={state.inputModel}
                        open={showSettingsModal}
                        onHide={() => setShowSettingsModal(false)}
                        onSectionCheckedChange={handleSectionCheckedChange}
                        onSubmit={handleSettingsModalSubmit}
                        reportGuid={reportGuid}
                        reportState={state}
                        setReportState={setState}
                        setValidation={setValidation}
                        validation={validation}
                        isReadOnlyReport={isReportReadonly}
                    />

                    <CheckmateDialog
                        isShowingModal={state.showClearSettingssConfirmModal || false}
                        body="Are you sure you want to clear all settings and set to default?"
                        handleClose={() => {
                            setState((prevState: IWeeklyRecapState) => ({
                                ...prevState,
                                showClearSettingssConfirmModal: false,
                            }));
                        }}
                        handleConfirm={handleClearSettings}
                        confirmText="Yes"
                        cancelText="No"
                        confirmButtonClassName="btn btn-black float-end"
                        dialogClassName="confirm-document-delete-dialog"
                        closeButtonClassName="btn btn-default float-end"
                    />

                    <CheckmateDialog
                        isShowingModal={!!state.sectionToBeRemoved}
                        body="The section you are trying to remove contains comments. Removing the section will delete the comment for the section. Are you sure you want to remove this section? "
                        handleClose={() => {
                            setState((prevState: IWeeklyRecapState) => ({
                                ...prevState,
                                sectionToBeRemoved: undefined,
                            }));
                        }}
                        handleConfirm={handleConfirmRemoveSection}
                        confirmText="Yes"
                        cancelText="No"
                        confirmButtonClassName="btn btn-black float-end"
                        dialogClassName="confirm-document-delete-dialog"
                        closeButtonClassName="btn btn-default float-end"
                    />

                    {showChatModal && (
                        <ReportChatModal
                            alertGuid={state.collaborationReport.baseDetails.alertGuid ?? ''}
                            actionTargets={chatActionTargets}
                            showCloseChatActButton={
                                state.collaborationReport.baseDetails.loggedInUserHasOpenChatAct
                            }
                            onCloseChatActs={handleCloseChatActs}
                            onClose={() => setShowChatModal(false)}
                            open={showChatModal}
                            reportGuid={reportGuid ?? ''}
                            user={props.user}
                        />
                    )}

                    {showHistoryModal && (
                        <HistoryModal
                            reportGuid={state.collaborationReport!.baseDetails!.guid! ?? ''}
                            open={showHistoryModal}
                            onClose={() => setShowHistoryModal(false)}
                        />
                    )}

                    {state.showCommentsModal && (
                        <CommentsDialog
                            defaultSection={state.currentCommentSection}
                            open={state.showCommentsModal ?? false}
                            onSaveClick={handleCommentsDialogSaveClick}
                            sectionCommentMap={
                                state.inputModel.reportSectionDetails
                                    ?.filter((detail) => !detail.excludeInReport)
                                    .map((detail) => ({
                                        section: detail.section,
                                        comment: detail.comment,
                                    })) ?? []
                            }
                            disabled={!!reportGuid && !isDraftStatus}
                        />
                    )}
                </>
            )}
            <CheckmateDialog
                isShowingModal={showUnsavedChangesWarningDialog !== null}
                title={'Unsaved Changes Detected'}
                body={'You have unsaved changes. Are you sure you want to proceed?'}
                handleClose={() => {
                    setShowUnsavedChangesWarningDialog(null);
                }}
                handleConfirm={() => {
                    if (showUnsavedChangesWarningDialog === UnsavedChangesReason.Cancel) {
                        loadInitialData();
                    }
                    setShowUnsavedChangesWarningDialog(null);
                }}
                cancelText="Cancel"
                confirmText="Continue"
                closeButtonClassName="btn btn-default float-end"
                confirmButtonClassName="btn btn-black float-end"
                size="sm"
            />
            <CheckmateDialog
                isShowingModal={showExistingLeaseDialog}
                title={'Report is Read-only'}
                body={leaseDialogBody}
                handleClose={() => {
                    setShowExistingLeaseDialog(false);
                }}
                cancelText="Ok"
                closeButtonClassName="btn btn-default float-end"
                size="sm"
                showBackdrop
            />
            <CheckmateDialog
                isShowingModal={!!showFailedToObtainLeaseDialog}
                title={'Report is Read-only'}
                body={`The report is read-only.\nIt is currently being edited by ${showFailedToObtainLeaseDialog?.lesseeUser?.profile?.firstName} ${showFailedToObtainLeaseDialog?.lesseeUser?.profile?.lastName}.`}
                handleClose={() => {
                    setShowFailedToObtainLeaseDialog(undefined);
                    setForceHideEditButton(true);
                }}
                cancelText="Ok"
                closeButtonClassName="btn btn-default float-end"
                size="sm"
                showBackdrop
            />
            <CheckmateDialog
                isShowingModal={showForceReleaseSuccessModal}
                title={'Force Release Successful'}
                body={`The report can now be edited by other users.`}
                handleClose={() => {
                    setShowForceReleaseSuccessModal(false);
                }}
                cancelText="Ok"
                closeButtonClassName="btn btn-default"
                size="sm"
                showBackdrop
            />
            <CheckmateDialog
                isShowingModal={showForceReleaseConfirmation}
                title={'Force Release Confirmation'}
                body={`Are you sure you wish to release this report to other users?\nThis cannot be undone.`}
                handleClose={() => {
                    setShowForceReleaseConfirmation(false);
                }}
                handleConfirm={handleForceReleaseConfirm}
                cancelText="Cancel"
                closeButtonClassName="btn btn-default"
                confirmText="Release"
                confirmButtonClassName="btn btn-orange"
                size="sm"
            />
            <CheckmateDialog
                body={
                    "You were previously editing this report in another session and didn't save.\nDo you want to open this report in read-only mode or begin editing this report here?\nAll unsaved changes made in your previous session will be permanently lost if you choose to edit this report here. \nIf you do not have any other open sessions, click Edit to edit the report here."
                }
                cancelText="Open as Read-only"
                closeButtonClassName="btn btn-default"
                confirmButtonClassName="btn btn-default"
                confirmText="Edit"
                disableActions={releaseEntityLeaseMutation.isLoading}
                handleClose={handleContinueAsReadonlyClick}
                handleConfirm={handleContinueInEditModeClick}
                isShowingModal={showEditingInAnotherWindowDialog}
                leftFooterComponent={
                    <Stack direction="horizontal" gap={1}>
                        {!!existingLease && (
                            <LoadingButton
                                loading={releaseEntityLeaseMutation.isLoading}
                                loadingText="Releasing..."
                                onClick={handleForceReleaseClick}
                                text="Force Release"
                                variant="default"
                            />
                        )}
                        <div className="text-danger">{validation.entityRelease}</div>
                    </Stack>
                }
                showBackdrop
                size="lg"
                title={'Report is Read-only'}
            />
        </>
    );
};

interface IPageHeaderProps {
    actionsComponent: JSX.Element;
    inputModel: ITimelineReportInputModel;
    isCollabReport: boolean;
    isInADraftStatus: boolean;
    report: IReportBaseModel | undefined;
}

function PageHeader(props: IPageHeaderProps) {
    const getReportMeta = () => {
        /*
        Report Title
            For Live View from the Report Tool (Report hasn't been saved yet) - Report Name | Date Range 
            Draft/Final Draft - report name | status | date range | Last Edited User | DateTime
            Published - Report Name | Date Range | Date Published (DateTime)
        */
        const dateString =
            props.inputModel && props.inputModel.startDate && props.inputModel.endDate
                ? Common.dateFormat(props.inputModel.startDate.toString()) +
                  ' to ' +
                  Common.dateFormat(props.inputModel.endDate.toString())
                : '';

        let parts: (string | undefined)[];

        if (!props.isCollabReport) {
            return <>{dateString && <span className="font-size-xs">{dateString}</span>}</>;
        } else if (props.isInADraftStatus) {
            parts = [
                props.report?.status?.displayName,
                dateString,
                `Last Updated by ${props.report?.modifiedBy?.profile?.firstName} ${props.report?.modifiedBy?.profile?.lastName}`,
                Common.dateTimeFormatToLocal(props.report?.modifiedDate),
            ];

            return <span className="font-size-xs">{parts.filter(Boolean).join(' | ')}</span>;
        } else {
            parts = [dateString, Common.dateTimeFormatToLocal(props.report?.modifiedDate)];
        }

        return <span className="font-size-xs">{parts.filter(Boolean).join(' | ')}</span>;
    };

    return (
        <div style={{ width: '100%' }}>
            <Stack direction="horizontal" style={{ justifyContent: 'space-between' }}>
                <h1 style={{ whiteSpace: 'nowrap' }}>Weekly Recap</h1>
                {props.actionsComponent}
            </Stack>
            <div>{getReportMeta()}</div>
        </div>
    );
}
