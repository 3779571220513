import { criteriaRequiresValue, triggerFieldRequiresValue } from '../validation';

import { AssertionDescriptorValueControls } from './AssertionDescriptorValueControls';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { StaticItemValueControls } from './StaticItemValueControls';

export enum ValueControlsType {
    Criteria,
    TriggerField,
}

export interface IValueControlsProps {
    item: IQueryFilterCriteria;
    onDateRangeChange: (item: IQueryFilterCriteria, field: string, value: string) => void;
    onRelativeDateChange: (item: IQueryFilterCriteria, value: number) => void;
    onNumericRangeChange: (
        item: IQueryFilterCriteria,
        field: string,
        value: number | undefined
    ) => void;
    onSelectorChange: (item: IQueryFilterCriteria, options: any) => void;
    onTextChange: (item: IQueryFilterCriteria, value: string) => void;
    onYesNoChange: (item: IQueryFilterCriteria, option: any) => void;
    type: ValueControlsType;
}

const itemRequiresValue = (item: IQueryFilterCriteria, type: ValueControlsType) => {
    if (type === ValueControlsType.Criteria) {
        return criteriaRequiresValue(item);
    } else if (type === ValueControlsType.TriggerField) {
        return triggerFieldRequiresValue(item);
    }
    return false;
};

export function ValueControls(props: IValueControlsProps) {
    const { item, type } = props;
    const disabled = !itemRequiresValue(item, type);

    if (item.isAssertionDescriptor) {
        return (
            <AssertionDescriptorValueControls {...props} disabled={disabled || !!item.isFixed} />
        );
    } else {
        return <StaticItemValueControls {...props} disabled={disabled || !!item.isFixed} />;
    }
}
