import { FilterCriteria } from '../../../../pages/query2/FilterCriteria';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { IUserModel } from '../../../../interfaces/IUser';
import { IValidation } from '../../../../interfaces/IError';
import { SelectedFilterCriteria } from '../../../../pages/query2/SelectedFilterCriteria';
import { cloneDeep } from 'lodash';

interface ITriggerFilterCriteriaSectionProps {
    availableFilterCriterias: IQueryFilterCriteria[];
    onSelectedQueryFilterCriteriaChange: (selectedCriteria: IQueryFilterCriteria[]) => void;
    onConditionOptionChange: (item: IQueryFilterCriteria, itemIndex: number) => void;
    queryType: number;
    selectedQueryFilterCriteria: IQueryFilterCriteria[];
    suppressDateRangeCondition?: boolean;
    user: IUserModel;
    validation: IValidation;
    buildValueControls: (item: IQueryFilterCriteria) => JSX.Element;
}

export function TriggerFilterCriteriaSection(props: ITriggerFilterCriteriaSectionProps) {
    const handleConditionOptionChange = (filters: IQueryFilterCriteria[], filterIdx: number) => {
        props.onConditionOptionChange(filters[filterIdx], filterIdx);
    };

    const handleDeleteSelectedFilterCriteria = (toDelete: IQueryFilterCriteria) => {
        const selectedQueryFilterCriteriaCopy = cloneDeep(props.selectedQueryFilterCriteria);

        const existingIndex = selectedQueryFilterCriteriaCopy.findIndex((criteria) => {
            if (criteria.isAssertionDescriptor) {
                return criteria.assertionDescriptorGuid === toDelete.assertionDescriptorGuid;
            }
            return criteria.staticItemId === toDelete.staticItemId;
        });

        selectedQueryFilterCriteriaCopy.splice(existingIndex, 1);

        props.onSelectedQueryFilterCriteriaChange(selectedQueryFilterCriteriaCopy);
    };

    return (
        <>
            <FilterCriteria
                onQueryCriteriaSelected={props.onSelectedQueryFilterCriteriaChange}
                queryFilterCriteriaAll={props.availableFilterCriterias}
                queryFilterCriteriaSelected={props.selectedQueryFilterCriteria}
                validation={props.validation}
                useQueryToolText={false}
            />
            <SelectedFilterCriteria
                acknowledgmentStatusTypes={[]}
                approvalStatusTypes={[]}
                noteCategoryTypes={[]}
                notePurposeTypes={[]}
                noteSearchCriteria={{}}
                onChangeAlt={() => {
                    console.log('onChangeAlt');
                }}
                onCheckmateSingleSelectChange={() => {
                    console.log('onCheckmateSingleSelectChange');
                }}
                onConditionOptionChange={handleConditionOptionChange}
                onDeleteField={handleDeleteSelectedFilterCriteria}
                onFilterValuesSelectedAlt={() => {
                    console.log('onFilterValuesSelectedAlt');
                }}
                queryFilterCriteriaSelected={props.selectedQueryFilterCriteria}
                queryType={props.queryType}
                suppressDateRangeCondition={props.suppressDateRangeCondition}
                user={props.user}
                buildValuesColumn={props.buildValueControls}
            />
        </>
    );
}
