import ApiClient from '../../services/apiClient';
import { useMutation } from 'react-query';

const _apiClient = new ApiClient();

export const usePingExistingLeaseMutation = (onError?: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (existingLeaseGuid: string) => {
            const response = await _apiClient.pingEntityLease(
                existingLeaseGuid,
            );

            if (!response?.httpResponse.ok) {
                if (response.errorMessage && response.httpResponse.status == 400) {
                    if (response.errors?.length ?? 0 > 0) {
                        throw new Error(
                            `Failed to ping existing lease: ${response.errors[0].message}`
                        );
                    }
                }
                throw new Error('Failed to ping existing lease');
            }
            return response.payload;
        },
        onError(error) {
            if (error instanceof Error && typeof onError === 'function') {
                onError(error.message);
            }
        },
    });
